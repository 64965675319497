import React, { useState } from "react";
import Header from "../sidebar/Header";
import LeftSideBar from "../sidebar/LeftSideBar";
import { Dialog, Transition } from "@headlessui/react";
import {  MdOutlineDoneOutline } from "react-icons/md";
import { Fragment } from "react";
import { BiEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { GiCancel } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";

import Select from "react-select";
import {
  apiGetEthenecity,
  DeleteEthencityType,
  DeleteperformerCategory,
  DeleteProductionType,
  GetperformerCategory,
  GetperformerCategoryID,
  getProductionType,
  postPerformerCategory,
  UpdateEthencityType,
  UpdateProductionType,
} from "../apiconfig/authService";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { Disclosure } from "@headlessui/react";
import { BiChevronUp } from "react-icons/bi";

const AttributeListEmployer = () => {
  const options = [
    { value: "Production Type", label: "Production Type" },
    { value: "Ethnicity", label: "Ethnicity" },
  ];

  const [editdata, setEditData] = useState();

  //Post PerformerCategory
  const handleUpdateProductionType = async () => {
    if(editdata.name===""){
      toast("Invalid category name.");
      return;
    }

    let data = { id: editdata._id, name: editdata.name, description: "d" };
    try {
      const response = await UpdateProductionType(data);
      if (response.status == true) {
        toast(response.message);
      } else {
        toast(response.message);
      }
    } catch (error) {}
    closeModal();
    GetProduction();
  };
  //Post PerformerCategory

  //Get Production Type api
  const [data, setData] = useState([]);
  const GetProduction = async () => {
    try {
      const response = await getProductionType();
      setData(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    GetProduction();
  }, []);
  //Get Production Type api

  //Delete performerCategory By Id
  const handleDeleteProduction = async (id) => {
    try {
      const response = await DeleteProductionType({
        id: id,
      });
      if (response.status == true) {
        toast(response.message);
      } else {
        toast(response.message);
      }
      closeModalProd()
      GetProduction();
    } catch (error) {}
  };
  //Delete performerCategory By Id

  //   Modal
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  // Modal

  //   Modal
  let [isEthen, setIsEthen] = useState(false);

  function closeethen() {
    setIsEthen(false);
  }

  function openethen() {
    setIsEthen(true);
  }
  // Modal

  const [editEthen, setEditethen] = useState(); //For Geting Ethencity Data To Edit
  //Get EtheneCity Type
  const [ethenecity, setEthenecity] = useState([]);
  const GetEthenecity = async () => {
    try {
      const response = await apiGetEthenecity();
      setEthenecity(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    GetEthenecity();
  }, []);
  //Get EtheneCity Type

  //Update Ethencity
  const handleUpdateEthencitytype = async () => {
    if(editEthen.name===""){
      toast("Invalid category name.");
      return;
    }
    let data = { id: editEthen._id, name: editEthen.name };
    try {
      const response = await UpdateEthencityType(data);
      if (response.status == true) {
        toast(response.message);
      } else {
        toast(response.message);
      }
    } catch (error) {}
    GetEthenecity();
    closeethen();
  };
  //Update Ethencity

  //Delete Ethencity By Id
  const handleDeleteEthencity = async (id) => {
    try {
      const response = await DeleteEthencityType({
        id: id,
      });
      GetEthenecity();
      if (response.status == true) {
        toast(response.message);
      } else {
        toast(response.message);
      }
      closeModalEth()
    } catch (error) {}
  };
  //Delete Ethencity By Id


  //modal for delete prod type

  const [prodId , setProdId] = useState("")
  const [modalProd, setModalProd] = useState(false);
  const [closeProd, setCloseProd] = useState(false);
  const openModalProd = () => {
    setModalProd(true);
    setCloseProd(false);
  };

  const closeModalProd = () => {
    setCloseProd(true);
    setModalProd(false);
  };

  //delete modal for ethnicity
  const [ethId , setEthId] = useState("")
  const [modalEth, setModalEth] = useState(false);
  const [closeEth, setCloseEth] = useState(false);
  const openModalEth = () => {
    setModalEth(true);
    setCloseEth(false);
  };

  const closeModalEth = () => {
    setCloseEth(true);
    setModalEth(false);
  };

  return (
    <div>
      <Header />
      <div className="col12SwmDashBord bg-white mb-20">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>

        <div className="col8SemNvr mt-[70px] ml-[5%]">
          {/* Bredcrums */}
          <div className="grid grid-cols-12">
            <div className="col-span-10 ">
              <div className="HdingSwnvr my-3 px-5">
                <h5> Attribute List </h5>
              </div>
            </div>
          </div>
          <hr className="w-full bg-gray-500 h-[2px] mb-8" />
          {/* Bredcrums End */}

          <div className="space-y-4">
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between rounded-lg bg-black/80  px-4 py-2 text-left text-sm font-medium text-white hover:bg-primary focus:outline-none  ">
                    <span className="">Production Type</span>
                    <BiChevronUp
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-white`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                    {data.length > 0 ? (
                      <div className="">
                        <div>
                          <div className="">
                            <div class="  shadow-md sm:rounded-lg">
                              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
                                <thead class="text-xs text-gray-700 uppercase bg-orange-100	 dark:bg-gray-700 dark:text-gray-400">
                                  <tr>
                                    <th scope="col" class="py-4 px-4">
                                      S No.
                                    </th>
                                    <th scope="col" class="py-4 px-4">
                                      Filter Name
                                    </th>

                                    <th scope="col" class="py-4 px-4">
                                      Action
                                    </th>
                                  </tr>
                                </thead>

                                <tbody className="">
                                  {data &&
                                    data.map((item, index) => (
                                      <tr
                                        class="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                                        key={index}
                                      >
                                        <th
                                          scope="row"
                                          class="py-3 px-4 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white"
                                        >
                                          {index + 1}
                                        </th>
                                        <th
                                          scope="row"
                                          class="py-3 px-4 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white"
                                        >
                                          {item.name}
                                        </th>

                                        <td class="py-3 text-xs">
                                          <div className="flex gap-5">
                                            <BiEdit
                                              // onClick={openModal}
                                              onClick={() => {
                                                openModal();
                                                setEditData(item);
                                              }}
                                              className="bg-blue-600 cursor-pointer text-white p-1"
                                              size={32}
                                            />
                                            <MdDelete
                                              onClick={(e) =>{
                                                setProdId(item._id);
                                                openModalProd()
                                              }}
                                              className="bg-red-600 cursor-pointer text-white p-2"
                                              size={32}
                                            />
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between rounded-lg bg-black/80  px-4 py-2 text-left text-sm font-medium text-white hover:bg-primary focus:outline-none  ">
                    <span className="">Ethnicity</span>
                    <BiChevronUp
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-white`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                    {data.length > 0 ? (
                      <div className="">
                        <div>
                          <div className="">
                            <div class="  shadow-md sm:rounded-lg">
                              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
                                <thead class="text-xs text-gray-700 uppercase bg-orange-100	 dark:bg-gray-700 dark:text-gray-400">
                                  <tr>
                                    <th scope="col" class="py-4 px-4">
                                      S No.
                                    </th>
                                    <th scope="col" class="py-4 px-4">
                                      Filter Name
                                    </th>

                                    <th scope="col" class="py-4 px-4">
                                      Action
                                    </th>
                                  </tr>
                                </thead>

                                <tbody className="">
                                  {ethenecity &&
                                    ethenecity.map((item, index) => (
                                      <tr
                                        class="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                                        key={index}
                                      >
                                        <th
                                          scope="row"
                                          class="py-3 px-4 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white"
                                        >
                                          {index + 1}
                                        </th>
                                        <th
                                          scope="row"
                                          class="py-3 px-4 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white"
                                        >
                                          {item.name}
                                        </th>

                                        <td class="py-3 text-xs">
                                          <div className="flex gap-5">
                                            <BiEdit
                                              onClick={() => {
                                                openethen();
                                                setEditethen(item);
                                              }}
                                              className="bg-blue-600 cursor-pointer text-white p-1"
                                              size={32}
                                            />
                                            <MdDelete
                                              onClick={(e) => {
                                                openModalEth()
                                                setEthId(item._id)
                                              }
                                              }
                                              className="bg-red-600 cursor-pointer text-white p-2"
                                              size={32}
                                            />
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        </div>

        {/* delete prod modal */}
        <div
          className={`w-screen h-screen fixed top-0 left-0 bg-black bg-opacity-20 ${modalProd ? "block duration-500" : "hidden duration-500"
            } ${closeProd ? "hidden" : ""}`}
        >
          <div
            className={`text-sm absolute top-[50%] left-[50%]  w-[38%] translate-x-[-50%] h-auto  translate-y-[-50%] bg-[#fff] rounded-md shadow-lg pb-6 ${modalProd ? "duration-500" : "duration-500"
              }`}
          >
            <div className="bg-primary w-full flex items-center justify-between px-6 py-4 rounded-tl-md rounded-tr-md text-white">
              <div className="text-sm font-bold">Delete</div>
              <AiOutlineClose
                size={20}
                onClick={closeModalProd}
                className="cursor-pointer font-bold"
              />
            </div>

            <div className="text-center py-8">
              <h2 className="font-semibold text-lg">
                Are you sure you want to delete this item?
              </h2>
            </div>

            <div className="grid grid-cols-12 gap-5 px-6 py-8">
              <div className="col-span-6">
                <div className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white">
                  <MdOutlineDoneOutline className="text-2xl" />
                  <div 
                  onClick={(e) => handleDeleteProduction(prodId)}
                  className="uppercase">
                    <span>Confirm</span>
                  </div>
                </div>
              </div>

              <div className="col-span-6">
                <div
                  onClick={closeModalProd}
                  className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white"
                >
                  <GiCancel className="text-2xl" />
                  <div class="uppercase">
                    <span>Cancel</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*delete prod modal */}


        {/* delete eth modal */}
        <div
          className={`w-screen h-screen fixed top-0 left-0 bg-black bg-opacity-20 ${modalEth ? "block duration-500" : "hidden duration-500"
            } ${closeEth ? "hidden" : ""}`}
        >
          <div
            className={`text-sm absolute top-[50%] left-[50%]  w-[38%] translate-x-[-50%] h-auto  translate-y-[-50%] bg-[#fff] rounded-md shadow-lg pb-6 ${modalEth ? "duration-500" : "duration-500"
              }`}
          >
            <div className="bg-primary w-full flex items-center justify-between px-6 py-4 rounded-tl-md rounded-tr-md text-white">
              <div className="text-sm font-bold">Delete</div>
              <AiOutlineClose
                size={20}
                onClick={closeModalEth}
                className="cursor-pointer font-bold"
              />
            </div>

            <div className="text-center py-8">
              <h2 className="font-semibold text-lg">
                Are you sure you want to delete this item?
              </h2>
            </div>

            <div className="grid grid-cols-12 gap-5 px-6 py-8">
              <div className="col-span-6">
                <div className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white">
                  <MdOutlineDoneOutline className="text-2xl" />
                  <div 
                  onClick={(e) => handleDeleteEthencity(ethId)}
                  className="uppercase">
                    <span>Confirm</span>
                  </div>
                </div>
              </div>

              <div className="col-span-6">
                <div
                  onClick={closeModalEth}
                  className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white"
                >
                  <GiCancel className="text-2xl" />
                  <div class="uppercase">
                    <span>Cancel</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*delete eth modal */}

        {/* Modal ADD section */}
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-[80%] transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                    <div className=" mt-[10px] ml-[3%] mr-[3%] ">
                      <div className="grid grid-cols-11 ">
                        <div className="col-span-11 py-4 flex justify-between">
                          <div className="text-black flex items-center text-sm tracking-wider font-bold">
                            Performer Category{" "}
                          </div>
                          <div><AiOutlineClose
                            size={20}
                            className="cursor-pointer font-bold"
                            onClick={closeModal}
                          /></div>
                        </div>
                      </div>

                      <div className="mb-10">
                        <div className=" mb-5">
                          <div className="border-[1.5px] border-gray-200 py-4 rounded-md">
                            <div className="flex justify-between bg-grey pl-6 py-1 text-sm text-[#4A4A4A] font-semibold">
                              Add Category
                            </div>

                            <div className="flex py-5 px-4 space-x-6 items-center">
                              <div className="w-full">
                                <label className="text-sm font-semibold text-[#898989]">
                                  Enter Category
                                </label>
                                <br />
                                <input
                                  value={editdata?.name}
                                  onChange={(e) =>
                                    setEditData({
                                      ...editdata,
                                      name: e.target.value,
                                    })
                                  }
                                  type="text"
                                  placeholder="performer category"
                                  className="border-[1px] outline-none placeholder:text-xs  py-2.5 my-2 px-2 rounded-md  text-xs border-gray-300 w-full"
                                />
                              </div>
                            </div>
                          </div>

                          <button className="flex items-center justify-center pt-4 space-x-4 duration-300">
                            <p
                              onClick={handleUpdateProductionType}
                              className=" px-6 py-1 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                            >
                              Save
                            </p>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>

        {/* Edit Ethenecity */}
        {/* Modal ADD section */}
        <Transition appear show={isEthen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeethen}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-[80%] transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                    <div className=" mt-[10px] ml-[3%] mr-[3%] ">
                      <div className="grid grid-cols-11 ">
                        <div className="col-span-11 py-4 flex justify-between">
                          <div className="text-black flex items-center text-sm tracking-wider font-bold">
                            Performer Category{" "}
                          </div>
                          <div><AiOutlineClose
                            size={20}
                            className="cursor-pointer font-bold"
                            onClick={closeethen}
                          /></div>
                        </div>
                      </div>

                      <div className="mb-10">
                        <div className=" mb-5">
                          <div className="border-[1.5px] border-gray-200 py-4 rounded-md">
                            <div className="flex justify-between bg-grey pl-6 py-1 text-sm text-[#4A4A4A] font-semibold">
                              Add Category
                            </div>

                            <div className="flex py-5 px-4 space-x-6 items-center">
                              <div className="w-full">
                                <label className="text-sm font-semibold text-[#898989]">
                                  Enter Category
                                </label>
                                <br />
                                <input
                                  value={editEthen?.name}
                                  onChange={(e) =>
                                    setEditethen({
                                      ...editEthen,
                                      name: e.target.value,
                                    })
                                  }
                                  type="text"
                                  placeholder="performer category"
                                  className="border-[1px] outline-none placeholder:text-xs  py-2.5 my-2 px-2 rounded-md  text-xs border-gray-300 w-full"
                                />
                              </div>
                            </div>
                          </div>

                          <button className="flex items-center justify-center pt-4 space-x-4 duration-300">
                            <p
                              onClick={handleUpdateEthencitytype}
                              className=" px-6 py-1 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                            >
                              Save
                            </p>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>

      {/* Modal section */}
    </div>
  );
};

export default AttributeListEmployer;
