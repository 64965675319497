import React, { useState, useEffect, Fragment } from "react";
import LeftSideBar from "../sidebar/LeftSideBar";
import {
  AiOutlineClose,
  AiOutlineEdit,
  AiOutlinePlus,
  AiFillDelete,
} from "react-icons/ai";
import { HiEye } from "react-icons/hi";
import { MdDelete, MdOutlineDoneOutline } from "react-icons/md";
import { FaHandHoldingUsd } from "react-icons/fa";
import { NavLink, Link } from "react-router-dom";

import Header from "../sidebar/Header";
import { GiCancel } from "react-icons/gi";
import Select from "react-select";
import { getDirectoryData, getDirectoryTypes } from "../apiconfig/authService";
export default function LatestUpdates() {
  const options = [
    { value: "Status", label: "Casting Director" },
    { value: "Pending", label: "Film Production House" },
    { value: "Rejected", label: "Ad Agency" },
  ];
  const employerDetails = [
    {
      id: 1,
      category: "Singer",
      name: "Sanjay Jangid",
      phone: "0987654576",
      email: "admin@gmail.com",
    },
    {
      id: 2,
      category: "Singer",
      name: "Sanjay Jangid",
      phone: "0987654576",
      email: "admin@gmail.com",
    },
    {
      id: 3,
      category: "Singer",
      name: "Sanjay Jangid",
      phone: "0987654576",
      email: "admin@gmail.com",
    },
    {
      id: 4,
      category: "Singer",
      name: "Sanjay Jangid",
      phone: "0987654576",
      email: "admin@gmail.com",
    },
    {
      id: 5,
      category: "Singer",
      name: "Sanjay Jangid",
      phone: "0987654576",
      email: "admin@gmail.com",
    },
    {
      id: 6,
      category: "Singer",
      name: "Sanjay Jangid",
      phone: "0987654576",
      email: "admin@gmail.com",
    },
    {
      id: 7,
      category: "Singer",
      name: "Sanjay Jangid",
      phone: "0987654576",
      email: "admin@gmail.com",
    },
    {
      id: 8,
      category: "Singer",
      name: "Sanjay Jangid",
      phone: "0987654576",
      email: "admin@gmail.com",
    },
  ];

  //   Modal
  const [modal, setModal] = useState(false);
  const [close, setClose] = useState(false);
  const openModal = () => {
    setModal(true);
    setClose(false);
  };

  const closeModal = () => {
    setClose(true);
    setModal(false);
  };
  // Modal

  const [dropDown, setDropDown] = useState([]);

  const [directoryData, setDirectoryData] = useState([]);

  const getDropDownOptions = async () => {
    try {
      const response = await getDirectoryTypes();
      console.log(response.data);
      setDropDown(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getDirectoryListData = async () => {
    try {
      const response = await getDirectoryData();
      // console.log(response.data);
      setDirectoryData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDropDownOptions();
    getDirectoryListData();
  }, []);

  console.log(directoryData);

  let arr = [];
  for (let i = 0; i < dropDown.length; i++) {
    arr[i] = dropDown[i].name;
  }
  console.log(arr);

  var list = [];
  arr.forEach(function (element) {
    list.push({ label: element, value: element });
  });

  return (
    <>
      <Header />
      <div className="col12SwmDashBord bg-white ">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>
        <div className="col8SemNvr mt-[70px] ml-[5%]">
          {/* Bredcrums */}
          <div className="grid grid-cols-12">
            <div className="col-span-10 ">
              <div className="HdingSwnvr my-3 px-5">
                <h5> Latest Updates </h5>
              </div>
            </div>
          </div>
          <hr className="w-full bg-gray-500 h-[2px]" />
          {/* Bredcrums End */}

          <div className="flex col12WdthSwhnMn mt-2">
            <div className="flex justify-between items-end mb-5">
              <div className="">
                <div class="mt-3 flex w-52">
                  <label className="mt-2"> Category</label>
                  <select className="border mx-3 p-3">
                    <option>Employer</option>
                    <option>Performer</option>
                  </select>
                </div>
              </div>
              <div class="flex">
                <div className="hover:shadow mx-3">
                  <NavLink to="/cms/create-faq">
                    <div className="flex cursor-pointer hover:shadow items-center border-[1px] px-6 space-x-1 py-2 rounded-md shadow-md">
                      <AiOutlinePlus />
                      <span>Create FAQ</span>
                    </div>
                  </NavLink>
                </div>
                {/* <div className="hover:shadow mx-3">
                  <NavLink to="/cms/create-faq">
                    <div className="flex cursor-pointer hover:shadow items-center border-[1px] px-6 space-x-1 py-2 rounded-md shadow-md">
                      
                      <span>FAQ Category</span>
                    </div>
                  </NavLink>
                </div> */}
              </div>
            </div>

            {/* FAQ start  */}

            <div class="container  py-10">
              <div class="w-full">
                <h2 class="w-full text-3xl text-center font-semibold py-6 lg:px-0 px-5 underline decoration-2 underline-offset-8 decoration-[#E1078A]">
                  FREQUENTLY ASKED QUESTIONS
                </h2>
              </div>
              <div class="space-y-4 text-center font-text pt-5 pb-10 lg:px-10 px-5">
                <div>
                  <button class="w-full shadow-lg text-left group focus:outline-none space-y-2 p-1 ">
                    <div class="flex w-full justify-between rounded-sm text-black bg-#fff  border-1 border-white px-4 py-2  focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75">
                      + Why choose Milestone 101?{" "}
                      <div class="">
                        <div className="flex gap-5">
                          <NavLink
                            className="bg-[#0a65cc] text-white px-3 py-2"
                            to="/cms/create-faq"
                          >
                            <AiOutlineEdit />
                          </NavLink>
                          <NavLink className="bg-red-600 text-white px-3 py-2">
                            <AiFillDelete />
                          </NavLink>
                        </div>
                      </div>
                    </div>
                    <div class="hidden p-4  group-focus:block  text-black border-2 border-white">
                      <p class="text-sm text-black font-normal leading-relaxed tracking-wide">
                        Milestone 101 is a sorted platform for all media and
                        entertainment admirers. Whether they are a part of the
                        industry or wish to be one, We got something for
                        everyone. We have talented artists on our boat from
                        several job opportunities for the artists. It is simply
                        easy and accessible with Milestone 101.
                      </p>
                    </div>
                  </button>
                </div>
                <div>
                  <button class="w-full shadow-lg text-left group focus:outline-none space-y-2 p-1 ">
                    <div class="flex w-full justify-between rounded-sm text-black bg-#fff  border-1 border-white px-4 py-2  focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75">
                      + Why choose Milestone 101?{" "}
                      <div class="">
                        <div className="flex gap-5">
                          <NavLink
                            className="bg-[#0a65cc] text-white px-3 py-2"
                            to="/cms/create-faq"
                          >
                            <AiOutlineEdit />
                          </NavLink>
                          <NavLink className="bg-red-600 text-white px-3 py-2">
                            <AiFillDelete />
                          </NavLink>
                        </div>
                      </div>
                    </div>
                    <div class="hidden p-4  group-focus:block  text-black border-2 border-white">
                      <p class="text-sm text-black font-normal leading-relaxed tracking-wide">
                        Milestone 101 is a sorted platform for all media and
                        entertainment admirers. Whether they are a part of the
                        industry or wish to be one, We got something for
                        everyone. We have talented artists on our boat from
                        several job opportunities for the artists. It is simply
                        easy and accessible with Milestone 101.
                      </p>
                    </div>
                  </button>
                </div>
                <div>
                  <button class="w-full shadow-lg text-left group focus:outline-none space-y-2 p-1 ">
                    <div class="flex w-full justify-between rounded-sm text-black bg-#fff  border-1 border-white px-4 py-2  focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75">
                      + Why choose Milestone 101?{" "}
                      <div class="">
                        <div className="flex gap-5">
                          <NavLink
                            className="bg-[#0a65cc] text-white px-3 py-2"
                            to="/cms/create-faq"
                          >
                            <AiOutlineEdit />
                          </NavLink>
                          <NavLink className="bg-red-600 text-white px-3 py-2">
                            <AiFillDelete />
                          </NavLink>
                        </div>
                      </div>
                    </div>
                    <div class="hidden p-4  group-focus:block  text-black border-2 border-white">
                      <p class="text-sm text-black font-normal leading-relaxed tracking-wide">
                        Milestone 101 is a sorted platform for all media and
                        entertainment admirers. Whether they are a part of the
                        industry or wish to be one, We got something for
                        everyone. We have talented artists on our boat from
                        several job opportunities for the artists. It is simply
                        easy and accessible with Milestone 101.
                      </p>
                    </div>
                  </button>
                </div>
                <div>
                  <button class="w-full shadow-lg text-left group focus:outline-none space-y-2 p-1 ">
                    <div class="flex w-full justify-between rounded-sm text-black bg-#fff  border-1 border-white px-4 py-2  focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75">
                      + Why choose Milestone 101?{" "}
                      <div class="">
                        <div className="flex gap-5">
                          <NavLink
                            className="bg-[#0a65cc] text-white px-3 py-2"
                            to="/cms/create-faq"
                          >
                            <AiOutlineEdit />
                          </NavLink>
                          <NavLink className="bg-red-600 text-white px-3 py-2">
                            <AiFillDelete />
                          </NavLink>
                        </div>
                      </div>
                    </div>
                    <div class="hidden p-4  group-focus:block  text-black border-2 border-white">
                      <p class="text-sm text-black font-normal leading-relaxed tracking-wide">
                        Milestone 101 is a sorted platform for all media and
                        entertainment admirers. Whether they are a part of the
                        industry or wish to be one, We got something for
                        everyone. We have talented artists on our boat from
                        several job opportunities for the artists. It is simply
                        easy and accessible with Milestone 101.
                      </p>
                    </div>
                  </button>
                </div>
                <div>
                  <button class="w-full shadow-lg text-left group focus:outline-none space-y-2 p-1 ">
                    <div class="flex w-full justify-between rounded-sm text-black bg-#fff  border-1 border-white px-4 py-2  focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75">
                      + Why choose Milestone 101?{" "}
                      <div class="">
                        <div className="flex gap-5">
                          <NavLink
                            className="bg-[#0a65cc] text-white px-3 py-2"
                            to="/cms/create-faq"
                          >
                            <AiOutlineEdit />
                          </NavLink>
                          <NavLink className="bg-red-600 text-white px-3 py-2">
                            <AiFillDelete />
                          </NavLink>
                        </div>
                      </div>
                    </div>
                    <div class="hidden p-4  group-focus:block  text-black border-2 border-white">
                      <p class="text-sm text-black font-normal leading-relaxed tracking-wide">
                        Milestone 101 is a sorted platform for all media and
                        entertainment admirers. Whether they are a part of the
                        industry or wish to be one, We got something for
                        everyone. We have talented artists on our boat from
                        several job opportunities for the artists. It is simply
                        easy and accessible with Milestone 101.
                      </p>
                    </div>
                  </button>
                </div>
              </div>
            </div>

            {/*FAQ end */}

            {/* <div className="SwvrMnvr flex">
              <ul>
                <li className="prev">
                  {" "}
                  <NavLink to={"#"}>
                    {" "}
                    <img alt="Swhn" src="/assets/images/leftarrow.png" />{" "}
                  </NavLink>{" "}
                </li>
                <li>
                  {" "}
                  <NavLink to={"1"}> 1 </NavLink>{" "}
                </li>
                <li>
                  {" "}
                  <NavLink to={"#"}> 2 </NavLink>{" "}
                </li>
                <li>
                  {" "}
                  <NavLink to={"#"}> 3 </NavLink>{" "}
                </li>
                <li>
                  {" "}
                  <NavLink to={"#"}> .. </NavLink>{" "}
                </li>
                <li>
                  {" "}
                  <NavLink to={"#"}> 7 </NavLink>{" "}
                </li>
                <li>
                  {" "}
                  <NavLink to={"#"}> 8 </NavLink>{" "}
                </li>
                <li>
                  {" "}
                  <NavLink to={"#"}> 9 </NavLink>{" "}
                </li>
                <li>
                  {" "}
                  <NavLink to={"#"}> 10 </NavLink>{" "}
                </li>
                <li className="next">
                  {" "}
                  <NavLink to={"#"}>
                    {" "}
                    <img alt="Swhn" src="/assets/images/rightarrow.png" />{" "}
                  </NavLink>{" "}
                </li>
              </ul>

              <div className="flex ml-2">
                <select
                  id="countries"
                  class="bg-gray-50 border text-xs border-gray-300 text-gray-900  rounded-[2px] focus:ring-blue-500 focus:border-blue-500 block w-full p-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option selected value="10">
                    10
                  </option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="100">200</option>
                  <option value="200">200</option>
                  <option value="300">300</option>
                  <option value="400">400</option>
                  <option value="500">500</option>
                </select>
              </div>
            </div> */}
          </div>
        </div>
        {/* Modal section */}
        <div
          className={`w-screen h-screen fixed top-0 left-0 bg-black bg-opacity-20 ${
            modal ? "block duration-500" : "hidden duration-500"
          } ${close ? "hidden" : ""}`}
        >
          <div
            className={`text-sm absolute top-[50%] left-[50%]  w-[38%] translate-x-[-50%] h-auto  translate-y-[-50%] bg-[#fff] rounded-md shadow-lg pb-6 ${
              modal ? "duration-500" : "duration-500"
            }`}
          >
            <div className="bg-primary w-full flex items-center justify-between px-6 py-4 rounded-tl-md rounded-tr-md text-white">
              <div className="text-sm font-bold">Delete</div>
              <AiOutlineClose
                size={20}
                onClick={closeModal}
                className="cursor-pointer font-bold"
              />
            </div>

            <div className="text-center py-8">
              <h2 className="font-semibold text-lg">
                Are you sure you want to delete this item?
              </h2>
            </div>

            <div className="grid grid-cols-12 gap-5 px-6 py-8">
              <div className="col-span-6">
                <div className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white">
                  <MdOutlineDoneOutline className="text-2xl" />
                  <div class="uppercase">
                    <span>Confirm</span>
                  </div>
                </div>
              </div>

              <div className="col-span-6">
                <div
                  onClick={closeModal}
                  className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white"
                >
                  <GiCancel className="text-2xl" />
                  <div class="uppercase">
                    <span>Cancel</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal section */}
      </div>
    </>
  );
}
