import React, { useState, useEffect } from "react";
import LeftSideBar from "../sidebar/LeftSideBar";
import { MdDelete, MdOutlineDoneOutline } from "react-icons/md";
import { HiEye } from "react-icons/hi";
import { NavLink } from "react-router-dom";
import Header from "../sidebar/Header";
import { IoIosPeople } from "react-icons/io";
import { BsLaptopFill } from "react-icons/bs";
import {
  getAllPerformerAdmin,
  getAllEmployeeAdmin,
  getAllJobAdmin,
  getAllPerformers,
  getAllTopPerformerListingHome,
} from "../apiconfig/authService";
export default function IndexMain() {
  const [performerList, setPerformerData] = useState([]);
  const [employeeList, setEmployeeData] = useState([]);
  const [jobdata, setAllJobData] = useState([]);
  const [performerListData, setPerformerDataList] = useState([]);

  // const getAllPerformerAdminData = async () => {
  //   const response = await getAllPerformerAdmin();
  //   setPerformerData(response.data);
  // };
  const getAllPerformerAdminData = async () => {
    try {
      const response = await getAllPerformers();
      console.log(response.data);
      setPerformerData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllEmployeeData = async () => {
    const response = await getAllEmployeeAdmin();
    setEmployeeData(response.data);
  };

  const getAllJobData = async () => {
    const response = await getAllJobAdmin();
    setAllJobData(response.data);
  };

  const getAllPerfromerListing = async () => {
    try {
      const response = await getAllTopPerformerListingHome();
      console.log("Blog Data", response.data);
      setPerformerDataList(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  console.log("Job List", jobdata);

  useEffect(() => {
    getAllPerformerAdminData();
    getAllEmployeeData();
    getAllPerfromerListing();
    getAllJobData();
  }, []);

  return (
    <>
      <Header />
      <div className="col12SwmDashBord">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>
        <div className="col8SemNvr mt-[70px] ml-[5%]">
          <div className="flex w-full p-2">
            <div className="grid grid-cols-3 mx-9 gap-12 mt-[30px]">
              <div className="card w-96 shadow-lg p-2 flex ">
                <div
                  className="icon text-white text-7xl p-2"
                  style={{ backgroundColor: "#ffc107", borderRadius: "5px" }}
                >
                  <IoIosPeople />
                </div>
                <div className="pt-1  mx-4">
                  <h3 className="font-bold">Total Performer</h3>
                  <p>{performerList?.length}</p>
                </div>
              </div>
              <div className="card w-96 shadow-lg p-2 flex ">
                <div
                  className="icon text-white text-7xl p-2"
                  style={{ backgroundColor: "#28a745", borderRadius: "5px" }}
                >
                  <IoIosPeople />
                </div>
                <div className="pt-1  mx-4">
                  <h3 className="font-bold">Total Employer</h3>
                  <p>{employeeList?.length}</p>
                </div>
              </div>
              <div className="card w-96 shadow-lg p-2 flex ">
                <div
                  className="icon text-white text-7xl p-2"
                  style={{ backgroundColor: "#17a2b8", borderRadius: "5px" }}
                >
                  <BsLaptopFill />
                </div>
                <div className="pt-1  mx-4">
                  <h3 className="font-bold">Career Jobs</h3>
                  <p>{jobdata?.length}</p>
                </div>
              </div>
              {/* <div className="card w-96 shadow-lg p-2 flex ">
                <div
                  className="icon text-white text-7xl p-2"
                  style={{
                    backgroundColor: "#28a745",
                    borderRadius: "5px",
                  }}
                >
                  <BsLaptopFill />
                </div>
                <div className="pt-1  mx-4">
                  <h3 className="font-bold">Expired Job</h3>
                  <p>34</p>
                </div>
              </div>
              <div className="card w-96 shadow-lg p-2 flex ">
                <div
                  className="icon text-white text-7xl p-2"
                  style={{ backgroundColor: "#dc3545", borderRadius: "5px" }}
                >
                  <BsLaptopFill />
                </div>
                <div className="pt-1  mx-4">
                  <h3 className="font-bold">Pending Job</h3>
                  <p>34</p>
                </div>
              </div>
              <div className="card w-96 shadow-lg p-2 flex ">
                <div
                  className="icon text-white text-7xl p-2"
                  style={{ backgroundColor: "#28a745", borderRadius: "5px" }}
                >
                  <IoIosPeople />
                </div>
                <div className="pt-1  mx-4">
                  <h3 className="font-bold">All Jobs</h3>
                  <p>34</p>
                </div>
              </div> */}
            </div>
          </div>

          <div className="flex mx-5 justify-between  mt-[50px]">
            <div className="text-xl font-bold"> Career Job</div>
            <div className="flex">
              <div className="hover:shadow mx-3">
                <NavLink to="/cms/add-job">
                  <div className="flex cursor-pointer hover:shadow items-center border-[1px] px-6 space-x-1 py-2 rounded-md shadow-md">
                    <span>View all jobs</span>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="flex col12WdthSwhnMn mt-2">
            <div class=" relative  shadow-md sm:rounded-lg  mt-5">
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
                <thead class="text-xs text-gray-700 uppercase bg-orange-100	 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" class="py-4 px-4">
                      S No.
                    </th>
                    <th scope="col" class="py-4 px-4">
                      Job Title
                    </th>
                    <th scope="col" class="py-4 px-4">
                      Total Experience
                    </th>

                    <th scope="col" class="py-4 px-4">
                      Salary
                    </th>

                    <th scope="col" class="py-4 px-4">
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody className="TableService">
                  {jobdata &&
                    jobdata.slice(0, 5).map((jobDataItem, index) => (
                      <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                        <th
                          scope="row"
                          class="py-3 px-4 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {index + 1}
                        </th>
                        <th
                          scope="row"
                          class="py-3 px-4 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {jobDataItem.title}
                        </th>
                        <td class="py-3 text-xs">
                          {jobDataItem.totalExperience}
                        </td>
                        <td class="py-3 text-xs">{jobDataItem.salary}</td>

                        <td class="py-3 text-xs">
                          <div className="flex gap-5">
                            <NavLink to={`/cms/edit-job/${jobDataItem._id}`}>
                              <HiEye
                                className="bg-[#0a65cc] text-white p-2"
                                size={32}
                              />
                            </NavLink>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="flex mx-5 justify-between  mt-[50px]">
            <div className="text-xl font-bold">Top Performer</div>
            <div className="flex">
              <div className="hover:shadow mx-3">
                <NavLink to="/manage/performer">
                  <div className="flex cursor-pointer hover:shadow items-center border-[1px] px-6 space-x-1 py-2 rounded-md shadow-md">
                    <span>View all Performer</span>
                  </div>
                </NavLink>
              </div>
              <div className="hover:shadow mx-3">
                <NavLink to="/manage/employer">
                  <div className="flex cursor-pointer hover:shadow items-center border-[1px] px-6 space-x-1 py-2 rounded-md shadow-md">
                    <span>View all Employer</span>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="flex col12WdthSwhnMn mt-2">
            <div class=" relative  shadow-md sm:rounded-lg  mt-5">
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
                <thead class="text-xs text-gray-700 uppercase bg-orange-100	 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" class="py-4 px-4">
                      S No.
                    </th>
                    <th scope="col" class="py-4 px-4">
                      Image
                    </th>
                    <th scope="col" class="py-4 px-4">
                      Name
                    </th>

                    <th scope="col" class="py-4 px-4">
                      Role
                    </th>

                    <th scope="col" class="py-4 px-4">
                      Created date
                    </th>

                    <th scope="col" class="py-4 px-4">
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody className="TableService">
                  {performerListData
                    .slice(0, 4)
                    .map((itemPermerData, index) => (
                      <tr
                        class="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                        key={index}
                      >
                        <th
                          scope="row"
                          class="py-3 px-4 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {index + 1}
                        </th>
                        <th
                          scope="row"
                          class="py-3 px-4 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          <img
                            src={itemPermerData.ImageUrl}
                            alt="Logo"
                            className="w-[120px]"
                          />
                        </th>
                        <td class="py-3 text-xs">{itemPermerData.name}</td>
                        <td class="py-3 text-xs">
                          {itemPermerData.designation}
                        </td>
                        <td class="py-3 text-xs">
                          {itemPermerData.createdAt.substr(0, 10)}
                        </td>

                        <td class="py-3 text-xs">
                          <div className="flex gap-5">
                            <NavLink
                              to={`/cms/edit-performer/${itemPermerData._id}`}
                            >
                              <HiEye
                                className="bg-[#0a65cc] text-white p-2 cursor-pointer"
                                size={32}
                              />
                            </NavLink>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
