import React, { useState, Fragment, useEffect } from "react";
import LeftSideBar from "../sidebar/LeftSideBar";
import { HiEye } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import { NavLink, Link, useParams } from "react-router-dom";
import { MdDelete, MdOutlineDoneOutline } from "react-icons/md";
import { GiCancel } from "react-icons/gi";
import Select from "react-select";
import Header from "../sidebar/Header";
import { getJobApplicants } from "../apiconfig/authService";
export default function NoOfapplicants() {
  const options = [
    { value: "Ignore", label: "Ignore" },
    { value: "Pending", label: "Pending" },
    { value: "In Person Audition", label: "In Person Audition" },
    { value: "Request", label: "Request Selftape" },
  ];
  const performerDetails = [
    {
      id: 1,
      applicantname: "Production Title",
      applieddate: "Test Production",
      status: "Ignore",
    },
    {
      id: 2,
      applicantname: "Production Title",
      applieddate: "Test Production",
      status: "Ignore",
    },
    {
      id: 3,
      applicantname: "Production Title",
      applieddate: "Test Production",
      status: "Pending",
    },
    {
      id: 4,
      applicantname: "Production Title",
      applieddate: "Test Production",
      status: "Ignore",
    },
    {
      id: 5,
      applicantname: "Production Title",
      applieddate: "Test Production",
      status: "In Person Audition",
    },
    {
      id: 6,
      applicantname: "Production Title",
      applieddate: "Test Production",
      status: "Request Selftape",
    },
  ];
  const id = useParams();
  const jobRoleId = id.id;

  const [state, setState] = useState([]);

  const getApplicants = async () => {
    try {
      const response = await getJobApplicants({
        params: { jobRoleId: jobRoleId },
      });
      console.log(response.data);
      setState(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getApplicants();
  }, []);

  return (
    <>
      <Header />
      <div className="col12SwmDashBord bg-white ">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>
        <div className="col8SemNvr mt-[70px] ml-[5%]">
          {/* Bredcrums */}
          <div className="grid grid-cols-12">
            <div className="col-span-10 ">
              <div className="HdingSwnvr my-3 px-5">
                <h5>No. Of Applicants</h5>
              </div>
            </div>
          </div>
          <hr className="w-full bg-gray-500 h-[2px]" />
          {/* Bredcrums End */}

          <div className="flex col12WdthSwhnMn mt-2">
            <div className="flex space-x-8 items-center mb-5">
              <div className="w-[20%] ">
                <label>Filter By Status</label>
                <div class="mt-3 w-full">
                  <Select options={options} className="" />
                </div>
              </div>
            </div>

            {/* Table  */}

            <div class=" relative  shadow-md sm:rounded-lg  mt-5">
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
                <thead class="text-xs text-gray-700 uppercase bg-orange-100	 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" class="py-4 px-4">
                      S No.
                    </th>
                    <th scope="col" class="py-4 px-4">
                      Applicants Name
                    </th>
                    <th scope="col" class="py-4 px-4">
                      Applied Date
                    </th>

                    <th scope="col" class="py-4 px-6 ml-6 text-center">
                      Status
                    </th>

                    <th scope="col" class="py-4 px-4 text-center">
                      View Resume
                    </th>
                  </tr>
                </thead>

                <tbody className="TableService">
                  {state.map((item, index) => (
                    <tr
                      class="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                      key={index}
                    >
                      <th
                        scope="row"
                        class="py-3 px-4 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {index + 1}
                      </th>
                      <th
                        scope="row"
                        class="py-3 px-4 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {item?.performerId?.name}
                      </th>
                      <td class="py-3 text-xs px-4">
                        {new Date(
                          item?.performerId?.updatedAt
                        ).toLocaleDateString("en-GB")}
                      </td>

                      <td className="py-3  text-xs px-4">
                        <div
                          className={` text-black mx-2 text-center py-2 rounded-md ${
                            item?.jobApplicationStatus === "Shortlist"
                              ? "bg-green-500"
                              : item?.jobApplicationStatus === "Applied"
                              ? "bg-blue-500"
                              : item?.jobApplicationStatus === "Rejected"
                              ? "bg-red-500"
                              : item?.jobApplicationStatus ===
                                "Request Selftape"
                              ? "bg-blue-gray-200"
                              : null
                          }`}
                        >
                          {item?.jobApplicationStatus}
                        </div>
                      </td>

                      <td class="py-3 text-xs">
                        <div className="flex gap-5 justify-center">
                          <a
                            href={`https://www.milestone101.com/${item?.performerId?.performerId}`}
                            target="_blank"
                          >
                            <HiEye
                              className="bg-[#0a65cc] cuesor-pointer text-white p-2"
                              size={32}
                            />
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Table End */}

            <div className="SwvrMnvr flex">
              <ul>
                <li className="prev">
                  {" "}
                  <NavLink to={"#"}>
                    {" "}
                    <img alt="Swhn" src="/assets/images/leftarrow.png" />{" "}
                  </NavLink>{" "}
                </li>
                <li>
                  {" "}
                  <NavLink to={"1"}> 1 </NavLink>{" "}
                </li>
                <li>
                  {" "}
                  <NavLink to={"#"}> 2 </NavLink>{" "}
                </li>
                <li>
                  {" "}
                  <NavLink to={"#"}> 3 </NavLink>{" "}
                </li>
                <li>
                  {" "}
                  <NavLink to={"#"}> .. </NavLink>{" "}
                </li>
                <li>
                  {" "}
                  <NavLink to={"#"}> 7 </NavLink>{" "}
                </li>
                <li>
                  {" "}
                  <NavLink to={"#"}> 8 </NavLink>{" "}
                </li>
                <li>
                  {" "}
                  <NavLink to={"#"}> 9 </NavLink>{" "}
                </li>
                <li>
                  {" "}
                  <NavLink to={"#"}> 10 </NavLink>{" "}
                </li>
                <li className="next">
                  {" "}
                  <NavLink to={"#"}>
                    {" "}
                    <img alt="Swhn" src="/assets/images/rightarrow.png" />{" "}
                  </NavLink>{" "}
                </li>
              </ul>

              <div className="flex ml-2">
                <select
                  id="countries"
                  class="bg-gray-50 border text-xs border-gray-300 text-gray-900  rounded-[2px] focus:ring-blue-500 focus:border-blue-500 block w-full p-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option selected value="10">
                    10
                  </option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="100">200</option>
                  <option value="200">200</option>
                  <option value="300">300</option>
                  <option value="400">400</option>
                  <option value="500">500</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
