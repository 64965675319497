import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { UserLogout } from "../apiconfig/authService";
const LeftSideBar = () => {
  const [toggleState, setToggleState] = React.useState();
  const toggleTab = (index) => {
    setToggleState(index);
  };
  const [selected, setSelected] = useState(null);
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  var token = localStorage.getItem("token");
  var user = localStorage.getItem("user");

  if (token == null) {
    window.location.replace("/");
    // redirect("/login");
  }

  const navigate = useNavigate();

  const handleLogout = () => {
    UserLogout().then((res) => {
      console.log(res);
      navigate("/");
    });
  };

  const ClsoIconSwn = () => {
    setToggleState();
  };
  let MenuDataShow="";
  console.log(user);
  if(user=="cmsuser")
  {
    console.log("cmsuser");
    MenuDataShow = [
      {
        id: 999,
        images: "/assets/images/promotion.png",
        text: "Website CMS",
        LevelOne: [
          {
            id: 888,
            MenuSec: "Latest Updates",
            LinkMenu: "/cms/latest-updates",
          },
          {
            id: 102,
            MenuSec: "Testimonials",
            LinkMenu: "/cms/testimonials",
          },
          {
            id: 776,
            MenuSec: "Partners",
            LinkMenu: "/cms/partners",
          },          
          {
            id: 777,
            MenuSec: "Home",
            LevelTwo: [
              {
                id: 101,
                MenuSecLevel: "Banner",
                LinkSubMenu: "/cms/home-banner-listing",
              },
              {
                id: 102,
                MenuSecLevel: "Top Videos",
                LinkSubMenu: "/cms/top-videos-listing",
              },
              {
                id: 102,
                MenuSecLevel: "Top Performer",
                LinkSubMenu: "/cms/top-performer-listing",
              },
            ],
          },
          {
            id: 763,
            MenuSec: "Lifestyle",
            LinkMenu: "/cms/lifestyle-list",
          },          
          {
            id: 765,
            MenuSec: "Library",
            LinkMenu: "/cms/library",
          },
          {
            id: 15,
            MenuSec: "Career",
            LinkMenu: "#",
            LevelTwo: [
              {
                id: 101,
                MenuSecLevel: "Add Job",
                LinkSubMenu: "/cms/add-job",
              },
              {
                id: 102,
                MenuSecLevel: "Application",
                LinkSubMenu: "/cms/application",
              },
            ],
          },
        ],
      },
      
    ];
  }
  else
  {
    console.log("admin user");
   MenuDataShow = [
    {
      id: 1,
      images: "/assets/images/admin.png",
      text: "DASHBOARD",
      LevelOne: [
        {
          id: 3,
          MenuSec: "Dashboard",
          LinkMenu: "/Dashboard",
        },
      ],
    },
    {
      id: 14,
      images: "/assets/images/people.png",
      text: "MANAGE USER",
      LevelOne: [
        {
          id: 21,
          MenuSec: " Performer",
          LinkMenu: "/manage/performer",
        },
        {
          id: 21,
          MenuSec: " Employer",
          LinkMenu: "/manage/employer",
        },
      ],
    },
    {
      id: 130,
      images: "/assets/images/recipt.png",
      text: "MANAGE CATEGORY",
      LevelOne: [
        {
          id: 15,
          MenuSec: "Performer",
          LinkMenu: "#",
          LevelTwo: [
            {
              id: 101,
              MenuSecLevel: "Add Category",
              LinkSubMenu: "/add-category",
            },
            {
              id: 102,
              MenuSecLevel: "Add Attributes",
              LinkSubMenu: "/attribute/create-attribute",
            },
            {
              id: 102,
              MenuSecLevel: "Add Filters",
              LinkSubMenu: "/directory/create-filter-performer",
            },
          ],
        },
        {
          id: 16,
          MenuSec: "Employer",
          LinkMenu: "#",
          LevelTwo: [
            {
              id: 104,
              MenuSecLevel: "Add Category",
              LinkSubMenu: "/directory/employer-addCategory",
            },
            {
              id: 105,
              MenuSecLevel: "Add Attributes",
              LinkSubMenu: "/managed-jobs/dynamic-employer",
            },
            {
              id: 106,
              MenuSecLevel: "Attributes List",
              LinkSubMenu: "/directory/attribute-employer",
            },
          ],
        },
      ],
    },

    {
      id: 124,
      images: "/assets/images/people.png",
      text: "SUBSCRIPTION",
      LevelOne: [
        {
          id: 15,
          MenuSec: "Add Subscription",
          LinkMenu: "/subscription/add-subscription",
        },
        {
          id: 16,
          MenuSec: "Subscription List",
          LinkMenu: "/subscription/subscription-list",
        },
      ],
    },

    {
      id: 33,
      images: "/assets/images/recipt.png",
      text: "JOBS",
      LevelOne: [
        {
          id: 36,
          MenuSec: "Job",
          LinkMenu: "/jobs",
        },
        {
          id: 37,
          MenuSec: "Manage Applicants",
          LinkMenu: "/jobs/manage-appliants",
        },
      ],
    },
    {
      id: 22,
      images: "/assets/images/promotion.png",
      text: "DIRECTORY",
      LevelOne: [
        {
          id: 24,
          MenuSec: "Add Category",
          LinkMenu: "/directory/add-category",
        },
        {
          id: 24,
          MenuSec: "Add Directory",
          LinkMenu: "/directory/add-directorypage",
        },
      ],
    },

    {
      id: 999,
      images: "/assets/images/promotion.png",
      text: "Website CMS",
      LevelOne: [
        {
          id: 888,
          MenuSec: "Latest Updates",
          LinkMenu: "/cms/latest-updates",
        },
        {
          id: 102,
          MenuSec: "Testimonials",
          LinkMenu: "/cms/testimonials",
        },
        {
          id: 776,
          MenuSec: "Partners",
          LinkMenu: "/cms/partners",
        },
        // {
        //   id: 103,
        //   MenuSec: "FAQ",
        //   LinkMenu: "/cms/faq",
        // },
        {
          id: 777,
          MenuSec: "Home",
          LevelTwo: [
            {
              id: 101,
              MenuSecLevel: "Banner",
              LinkSubMenu: "/cms/home-banner-listing",
            },
            {
              id: 102,
              MenuSecLevel: "Top Videos",
              LinkSubMenu: "/cms/top-videos-listing",
            },
            {
              id: 102,
              MenuSecLevel: "Top Performer",
              LinkSubMenu: "/cms/top-performer-listing",
            },
          ],
        },
        {
          id: 761,
          MenuSec: "Contact Us",
          LinkMenu: "/cms/contactus",
        },
        {
          id: 763,
          MenuSec: "Lifestyle",
          LinkMenu: "/cms/lifestyle-list",
        },
        {
          id: 763,
          MenuSec: "Header",
          LinkMenu: "/cms/header",
        },
        {
          id: 764,
          MenuSec: "Pages",
          LinkMenu: "/cms/pages",
        },
        {
          id: 765,
          MenuSec: "Library",
          LinkMenu: "/cms/library",
        },
        {
          id: 15,
          MenuSec: "Career",
          LinkMenu: "#",
          LevelTwo: [
            {
              id: 101,
              MenuSecLevel: "Add Job",
              LinkSubMenu: "/cms/add-job",
            },
            {
              id: 102,
              MenuSecLevel: "Application",
              LinkSubMenu: "/cms/application",
            },
          ],
        },
      ],
    },
    {
      id: 24,
      images: "/assets/svg/trash.svg",
      text: "Trash",
      LevelOne: [
        {
          id: 3,
          MenuSec: "Performer",
          LinkMenu: "/manage/users/Trash",
        },
        {
          id: 4,
          MenuSec: "Employer",
          LinkMenu: "/manage/users/Trash/Employer",
        },
      ],
    },
  ];
}

  console.log(MenuDataShow);
  return (
    <>
      <div
        className={
          toggleState === 1 ? "LeftSidebar ActivrSwnvr" : "LeftSidebar"
        }
      >
        <div className="col12Swmvr">
          {MenuDataShow?.map((MenuShwMn, index) => (
            <div className="ParSwnSwnMnvr" key={index}>
              <div
                className={
                  toggleState === MenuShwMn.id
                    ? "IcxonSwn ActivrSwnvr"
                    : "IcxonSwn"
                }
                onClick={() => toggleTab(MenuShwMn.id)}
              >
                <div className="WhteLnSwn">
                  <img alt="img" src={MenuShwMn.images} />
                </div>
              </div>
              <div
                className={
                  toggleState === MenuShwMn.id
                    ? "SwmvrSwmCl12  ActmnvrMn"
                    : "SwmvrSwmCl12"
                }
              >
                <h4> {MenuShwMn.text} </h4>
                <div className="ClsoIconSwn" onClick={ClsoIconSwn}>
                  <img alt="menu_open" src="/assets/images/menu_open.png" />
                </div>
                <ul>
                  {MenuShwMn?.LevelOne.map((item, index) => {
                    return (
                      <li className="DroDwnMn" onClick={() => toggle(item.id)}>
                        <NavLink to={item.LinkMenu} key={index}>
                          {" "}
                          {item.MenuSec}{" "}
                          {item.LevelTwo && item.LevelTwo.length > 0 ? (
                            <span
                              className={
                                selected === item.id ? "arrow DwnArw" : "arrow"
                              }
                            ></span>
                          ) : (
                            ""
                          )}
                        </NavLink>
                        {item.LevelTwo && item.LevelTwo.length > 0 ? (
                          <ul
                            key={index}
                            className={
                              selected === item.id ? "DroDwn Show" : "DroDwn"
                            }
                          >
                            {item.LevelTwo.map((Data, index) => (
                              <li key={index}>
                                {" "}
                                <NavLink to={Data.LinkSubMenu}>
                                  {" "}
                                  {Data.MenuSecLevel}{" "}
                                </NavLink>{" "}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          ""
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          ))}

          <div className="pt-5">
            <NavLink onClick={handleLogout}>
              <img
                alt="Logo"
                src="/assets/images/logout.png"
                className="w-[25px] mx-auto"
              />
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftSideBar;
