import React from "react";
import { AiOutlineRight } from "react-icons/ai";
import { MdEventNote } from "react-icons/md";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { useEffect, useState } from "react";
import { BsCheck } from "react-icons/bs";
import { AiFillCamera } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { AiOutlinePlus } from "react-icons/ai";
import Select from "react-select";

import { GrFormUpload } from "react-icons/gr";
import Header from "../sidebar/Header";
import LeftSideBar from "../sidebar/LeftSideBar";
import { NavLink, useParams } from "react-router-dom";
import { addnewBlogData } from "../apiconfig/authService";

const options = [
    { value: "Instant Job Alerts", label: "Instant Job Alerts" },
    { value: "Online Portfolio", label: "Online Portfolio" },
    { value: "Upload Photos", label: "Upload Photos" },
    { value: "Upload Videos", label: "Upload Videos" },
    { value: "Apply For Jobs", label: "Apply For Jobs" },
];

const CreateBlog = () => {
    let categoryId = useParams();
    categoryId = categoryId.id;

    const [category, setCategory] = useState("");
    const [title, setTitle] = useState("");
    const [author, setAuthor] = useState("");
    const [shortDesc, setShortDesc] = useState("");
    const [longDesc, setLongDesc] = useState("");
    const [shortImage, setSmallImage] = useState();
    const [longImage, setLongImage] = useState();

    const uploadLargePicture = (e) => {
        setLongImage({
            picturePreview: URL.createObjectURL(e.target.files[0]),
            pictureAsFile: e.target.files[0],
        });
    };
    const uploadSmallPicture = (e) => {
        setSmallImage({
            picturePreview: URL.createObjectURL(e.target.files[0]),
            pictureAsFile: e.target.files[0],
        });
    };

    const submitBlog = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append("smallImage", shortImage.pictureAsFile);
        formData.append("largeImage", longImage.pictureAsFile);
        formData.append("shortDescription", shortDesc);
        formData.append("longDescription", longDesc);
        formData.append("title", title);
        formData.append("author",author);
        formData.append("categoryId", categoryId);

        try {
            await addnewBlogData(formData);
            console.log(formData);
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <div>
            <Header />
            <div className="col12SwmDashBord bg-white">
                <div className="col4SemNvr">
                    <LeftSideBar />
                </div>
                <form onSubmit={submitBlog}>
                    <div className="col8SemNvr mt-[70px] ml-[5%]">
                        <div className="grid grid-cols-11">
                            <div className="col-span-9 py-4">
                                <div className="text-black flex items-center text-sm tracking-wider font-bold">
                                    CMS{" "}
                                    <span className="space-x-1 flex items-center text-secondary">
                                        <AiOutlineRight />
                                        Add Blog Category
                                    </span>
                                    <span className="space-x-1 flex items-center text-secondary">
                                        <AiOutlineRight />
                                        Add Blog Data
                                    </span>
                                </div>
                            </div>
                        </div>
                        {/* Tabs are Here  */}

                        {/* Start Complete Form */}
                        <div className="border-[1.5px] border-gray-200 py-4 rounded-md">
                            <div className="bg-grey pl-6 py-1 text-sm text-[#4A4A4A] font-semibold">
                                Create blog
                            </div>

                            <div className="flex py-5 pl-6 space-x-6 items-center">
                                <div className="w-[250px] ">
                                    <label className="text-sm font-semibold text-[#898989] pt-4">
                                        Category
                                    </label>
                                    {/* <div class="mt-3 w-52"> */}
                                    {/* <Select options={options} className="pt-2" /> */}
                                    <input
                                        type="text"
                                        placeholder="Enter Title of Blog"
                                        className="border-[1px] placeholder:text-xs  py-[.6rem] px-2 rounded-md  text-xs border-gray-300 w-full"
                                        onChange={(e) =>
                                            setCategory(e.target.value)
                                        }
                                    />
                                    <br />
                                    {/* <input
                                    type="text"
                                    placeholder="Enter Name"
                                    className="border-[1px] placeholder:text-xs  py-[.6rem] px-2 rounded-md  text-xs border-gray-300 w-full"
                                /> */}
                                </div>
                                <div className="w-96">
                                    <label className="text-sm font-semibold text-[#898989]">
                                        Title
                                    </label>
                                    <br />
                                    {/* <input
                                    type="text"
                                    placeholder="New Membership"
                                    className="border-[1px] placeholder:text-xs  py-[.6rem] px-2 rounded-md  text-xs border-gray-300 w-full"
                                /> */}
                                    {/* <Select
                                    defaultValue={[options[2], options[3]]}
                                    isMulti
                                    name="colors"
                                    options={options}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                /> */}
                                    <input
                                        type="text"
                                        placeholder="Enter Title of Blog"
                                        className="border-[1px] placeholder:text-xs  py-[.6rem] px-2 rounded-md  text-xs border-gray-300 w-full"
                                        onChange={(e) =>
                                            setTitle(e.target.value)
                                        }
                                    />
                                </div>
                                <div>
                                    <label className="text-sm font-semibold text-[#898989]">
                                        Author
                                    </label>
                                    <br />
                                    <div className="">
                                        <input
                                            type="text"
                                            onChange={(e) =>
                                                setAuthor(e.target.value)
                                            }
                                            placeholder="Enter Author of Blog"
                                            className="border-[1px] placeholder:text-xs  py-[.6rem] px-2 rounded-md  text-xs border-gray-300 w-full"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col mt-4 ml-4">
                                <label className="text-sm font-semibold text-[#898989]">
                                    Short Description
                                </label>
                                <br />
                                <div className="">
                                    <input
                                        type="text"
                                        onChange={(e) =>
                                            setShortDesc(e.target.value)
                                        }
                                        placeholder="Enter Brief Summary of Blog"
                                        className="border-[1px] placeholder:text-xs  py-[.6rem] px-2 rounded-md  text-xs border-gray-300 w-full"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col mt-4 ml-4">
                                <label className="text-sm font-semibold text-[#898989]">
                                    Long Description
                                </label>
                                <br />
                                <div className="">
                                    <input
                                        type="text"
                                        onChange={(e) =>
                                            setLongDesc(e.target.value)
                                        }
                                        placeholder="Enter Complete Blog"
                                        className="border-[1px] placeholder:text-xs  py-[.6rem] px-2 rounded-md  text-xs border-gray-300 w-full"
                                    />
                                </div>
                            </div>
                            <div className="mt-4 ml-4">
                                <form>
                                    <h1>Large Image File Upload</h1>
                                    <input
                                        type="file"
                                        onChange={uploadLargePicture}
                                    />
                                    {/* <button type="submit">Upload</button> */}
                                </form>
                            </div>
                            <div className="mt-4 ml-4">
                                <form>
                                    <h1>Small Image File Upload</h1>
                                    <input
                                        type="file"
                                        onChange={uploadSmallPicture}
                                    />
                                    {/* <button type="submit">Upload</button> */}
                                </form>
                            </div>
                        </div>
                        {/* Cancel & Save Btn */}

                        <div className="flex items-center justify-end pt-4 space-x-4 duration-300">
                            <NavLink
                                to="/subscription/add-subscription"
                                className=" px-14 py-1 border-[1px] border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                            >
                                CANCEL
                            </NavLink>
                            <button
                                type="submit"
                                onClick={submitBlog}
                                className=" px-14 py-1 border-[1px] border-primary text-white hover:bg-white bg-primary hover:text-primary rounded-md "
                            >
                                SAVE
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateBlog;
