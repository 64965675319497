import React, { useState, useEffect } from "react";
import LeftSideBar from "../sidebar/LeftSideBar";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../sidebar/Header";
import { AiOutlineClose } from "react-icons/ai";
import { MdOutlineDoneOutline } from "react-icons/md";
import { GiCancel } from "react-icons/gi";
import { Checkbox, Radio, Textarea } from "@material-tailwind/react";
import { useParams } from "react-router-dom";
import {
  getjobApplicationThroughId,
  getJobByItsId,
  updateJob,
} from "../apiconfig/authService";
import { Disclosure } from "@headlessui/react";
import { BiChevronUp } from "react-icons/bi";
import { toast } from "react-toastify";

function JobDetails() {
  const [state, setState] = useState({
    jobTitle: "Title",
    country: "India",
    state: "Rajasthan",
    city: "Jaipur",
    lookingFor: "Looking For",
    productionCompany: "Company",
    productionTitle: "Title",
    productionType: "Test Type",
    location: "India(Local)",
    jobLocation: "359, Sector 27, Gurgaon",
    projectDetails: "Project Details  Details",
    applyTime: "14:35",
    role: "Actor",
    startDate: "12 Jan 2022",
    endDate: "15 Jan 2022",
    lastApply: "25 Dec 2023",
    methodApply: "In Person Audition",
    methodDate: "23 Jan 2022",
    methodLocation: "Sonipat",
    jobVisibility: "Yes",
    roleTitle: "Title",
    roleType: "Type",
    minAge: 24,
    maxAge: 40,
    experience: "2 years",
    gender: "Female",
    ethnicity: "Ethnicity",
    payment: "Paying Job",
    salaryDetails: "US Dollar",
    minSalary: "123$",
    maxSalary: "234$",
    time: "Per Hour",
    character: "character text",
    brief: "brief text",
  });

  const navigate = useNavigate();

  // role details
  const role = [
    {
      id: 1,
      roleDetail: "Detail",
      roleTitle: "Title",
      roleType: "Type",
      yearsExperience: "2 years",
      gender: "Male",
      ethnicity: "Ethnicity",
      payment: "Paying Job",
      salaryDetails: "US Dollar",
      minSalary: "123$",
      maxSalary: "234$",
      time: "Per Hour",
    },
  ];

  //   Modal
  const [modal, setModal] = useState(false);
  const [close, setClose] = useState(false);
  const [InPersonChecked, setInPersonChecked] = useState(false);
  const openModal = (e) => {
    e.preventDefault();
    setModal(true);
    setClose(false);
  };

  const closeModal = () => {
    setClose(true);
    setModal(false);
    alert("Alert");
  };
  // Modal

  const id = useParams();
  const appId = id.id;

  const getJobDetailsById = async () => {
    try {
      const response = await getJobByItsId(appId);
      console.log("response", response.data);
      console.log("responsejobtitle", response.data.jobTitle);
      setState(response.data[0]);

      // setState({jobTitle:"mydata"})
    } catch (error) {
      console.log(error);
    }
  };

  const [jobStatus, setJobStatus] = useState("");
  const [reason, setReason] = useState("");

  //update job status

  // const updateJobStatus = async () => {
  //   const data = {
  //     id: jobId,
  //     performerId: state.categoryId,
  //     jobId : state.employerId,
  //     jobRoleId : state.productionTypeId,
  //     jobApplicationStatus: "ACTIVE",

  //   };
  //   // try {
  //   //   const response = await updateJob(data)
  //   // } catch (error) {
  //   //   console.log(error);
  //   // }
  // }
  // updateJobStatus();
  const handleApprove = async (e) => {
    e.preventDefault();
    const data = {
      id: state._id,
      status: "ACTIVE",
    };

    try {
      const response = await updateJob(data);
      if (response.status) {
        toast(response.message);
        navigate("/jobs");
      } else {
        toast(response.message);
      }
    } catch (error) {
      console.log(error);
    }
    console.log(data);
  };
  // const handleReject = async (e) => {
  //   e.preventDefault()
  //   const data = {
  //         id: state._id,
  //         status: "REJECTED",
  //       }

  //     try{
  //       const response = await updateJob(data)

  //     }
  //     catch(error){
  //       console.log(error);
  //     }
  //     console.log(data);
  // }

  useEffect(() => {
    getJobDetailsById();
  }, []);

  console.log("state data", state);
  return (
    <>
      <Header />
      <div className="col12SwmDashBord">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>
        <div className="col8SemNvr mt-[70px] ml-[5%]">
          <div className="block w-full p-2 bg-white">
            {/* Brecrums */}
            <nav class="flex MngeBredcums px-4" aria-label="Breadcrumb">
              <ol class="inline-flex items-center space-x-1 md:space-x-3">
                <li>
                  <div class="flex items-center">
                    <NavLink
                      to="#"
                      class="ml-1 text-xs font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white"
                    >
                      {" "}
                      Jobs{" "}
                    </NavLink>
                  </div>
                </li>

                <li aria-current="page">
                  <div class="flex items-center">
                    <svg
                      class="w-4 h-4 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span class="ml-1  font-medium text-gray-500 md:ml-2 dark:text-gray-400 text-xs">
                      {" "}
                      Job Details{" "}
                    </span>
                  </div>
                </li>
              </ol>
            </nav>

            <div className="flex justify-between mt-2 mb-2 px-4">
              <h4 className="text-xl font-bold"> Job Detail</h4>
              <div className="space-x-4">
                <button className=" px-6 py-1 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md ">
                  Save
                </button>
                <NavLink
                  to="/jobs"
                  className=" px-6 py-1.5 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                >
                  Back
                </NavLink>
              </div>
            </div>

            {/* End Brecrums */}

            <div className="block w-full  mt-2 bg-white ">
              <div className="p-2  border-[1px]  block">
                {/* <h4 className="text-lg font-bold px-3"> Job Detail</h4> */}
                <div class="py-3 grid grid-cols-2 gap-3 mb-0 w-full px-2 ">
                  <div class="w-full  my-3 justify-between   border flex items-center">
                    <label class="block w-full  px-3 py-2 md:mb-0 border-r tracking-wide text-black-700 text-xs font-bold mb-2">
                      Job Title
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <input
                        value={state.jobTitle}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="w-full  my-3 justify-between   border flex items-center">
                    <label class="block w-full  px-3 py-2 md:mb-0 border-r tracking-wide text-black-700 text-xs font-bold mb-2">
                      Country
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <input
                        value={state.country}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full   my-3 justify-between border flex items-center">
                    <label class="block w-full  px-3 border-r py-2 md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2">
                      looking for
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <input
                        value={state?.categoryId?.name}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full   my-3 justify-between border flex items-center">
                    <label class="block w-full  px-3 border-r py-2   md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2">
                      Production Company
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <input
                        value={state.productionCompany}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full  my-3 justify-between border flex items-center">
                    <label class="block w-full  px-3  md:mb-0 border-r py-2  tracking-wide text-black-700 text-xs font-bold mb-2">
                      Production Title
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <input
                        value={state.productionTitle}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="w-full my-3 justify-between border flex items-center">
                    <label class="block w-full  px-3  md:mb-0 border-r py-2  tracking-wide text-black-700 text-xs font-bold mb-2">
                      Production Type
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <input
                        value={state?.productionTypeId?.name}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="w-full   my-3 justify-between border flex items-center">
                    <label class="block w-full  px-3  md:mb-0 border-r py-2  tracking-wide text-black-700 text-xs font-bold mb-2">
                      Location
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <input
                        value={state.location}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  {state.local ? (
                    <div class="w-full my-3 justify-between border flex items-center">
                      <label class="block w-full  px-3  md:mb-0 border-r py-2  tracking-wide text-black-700 text-xs font-bold mb-2">
                        State
                      </label>
                      <div className="w-full md:w-[80%] px-3">
                        <input
                          value={state.state}
                          class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                          id="grid-first-name"
                          type="text"
                        />
                      </div>
                    </div>
                  ) : null}
                  {state.local ? (
                    <div class="w-full my-3 justify-between border flex items-center">
                      <label class="block w-full  px-3  md:mb-0 border-r py-2  tracking-wide text-black-700 text-xs font-bold mb-2">
                        City
                      </label>
                      <div className="w-full md:w-[80%] px-3">
                        <input
                          value={state.city}
                          class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                          id="grid-first-name"
                          type="text"
                        />
                      </div>
                    </div>
                  ) : null}

                  <div class="w-full  my-3 justify-between border flex items-center">
                    <label class="block w-full  px-3  md:mb-0 border-r py-4  tracking-wide text-black-700 text-xs font-bold mb-2">
                      Job Location
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <input
                        value={state.jobLocation}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full   my-3 justify-between border flex items-center">
                    <label class="block w-full  px-3  md:mb-0 border-r py-2  tracking-wide text-black-700 text-xs font-bold mb-2">
                      Project Starting Date
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <input
                        value={state.startFrom}
                        class="appearance-none block w-full text-xs text-gray-700 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="w-full   my-3 justify-between border flex items-center">
                    <label class="block w-full px-3  md:mb-0 border-r py-2  tracking-wide text-black-700 text-xs font-bold mb-2">
                      Project Ending Date
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <input
                        value={state.endOn}
                        class="appearance-none block w-full text-xs text-gray-700 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="px-2">
                    {" "}
                    <div class="w-full grid grid-cols-12  my-3 justify-between border  items-center">
                      <label class="block w-full col-span-3  px-3  md:mb-0 border-r py-4  tracking-wide text-black-700 text-xs font-bold mb-2">
                        Project Details
                      </label>
                      <div className="w-full md:w-[100%] px-3 col-span-9">
                        <textarea
                          value={state.projectDetails}
                          class="appearance-none block w-full resize-none text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                          id="grid-first-name"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="w-full   my-3 justify-between border flex items-center">
                    <label class="block w-full px-3  md:mb-0 border-r py-2  tracking-wide text-black-700 text-xs font-bold mb-2">
                      Method To Apply
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <input
                        value={state.methodToApply}
                        class="appearance-none block w-full text-xs text-gray-700 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="w-full   my-3 justify-between border flex items-center">
                    <label class="block w-full px-3  md:mb-0 border-r py-2  tracking-wide text-black-700 text-xs font-bold mb-2">
                      Method Date
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <input
                        value={new Date(state.createdAt).toLocaleDateString()}
                        class="appearance-none block w-full text-xs text-gray-700 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="w-full   my-3 justify-between border flex items-center">
                    <label class="block w-full px-3  md:mb-0 border-r py-2  tracking-wide text-black-700 text-xs font-bold mb-2">
                      Method Location
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <input
                        value={state.location}
                        class="appearance-none block w-full text-xs text-gray-700 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="w-full   my-3 justify-between border flex items-center">
                    <label class="block w-full px-3  md:mb-0 border-r py-2  tracking-wide text-black-700 text-xs font-bold mb-2">
                      Job Visibility
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <input
                        value={state.jobVisibility}
                        class="appearance-none block w-full text-xs text-gray-700 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="block w-full  my-6 bg-white ">
              <div className="p-2  border-[1px]  block">
                {/* <h4 className="font-semibold ">Method To Apply</h4> */}
                {/* <div className="flex items-center text-sm font-semibold gap-2">
                  <div className="flex items-center text-xs">
                    <Checkbox color="purple" />
                    <label>Request Self Tape</label>
                  </div>
                  <div className="flex items-center text-xs">
                    <Checkbox
                      color="purple"
                      onChange={() => setInPersonChecked(!InPersonChecked)}
                    />
                    <label>In Person Audition</label>
                  </div>
                  <div className="flex items-center text-xs">
                    <Checkbox color="purple" />
                    <label>Submit Profile</label>
                  </div>
                </div> */}

                <div class="py-3 grid grid-cols-2 gap-3 mb-0 w-full px-2 ">
                  {InPersonChecked ? (
                    <div class="w-full my-3 justify-between border flex items-center">
                      <label class="block w-full  px-3  md:mb-0 border-r py-2  tracking-wide text-black-700 text-xs font-bold mb-2">
                        Date
                      </label>
                      <div className="w-full md:w-[80%] px-3">
                        <input
                          value={state.roleTitle}
                          class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                          id="grid-first-name"
                          type="date"
                        />
                      </div>
                    </div>
                  ) : null}
                  {InPersonChecked ? (
                    <div class="w-full  my-3 justify-between border flex items-center">
                      <label class="block w-full  px-3  md:mb-0 border-r py-2  tracking-wide text-black-700 text-xs font-bold mb-2">
                        Location
                      </label>
                      <div className="w-full md:w-[80%] px-3">
                        <input
                          value={state.jobLocation}
                          class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                          id="grid-first-name"
                          type="text"
                        />
                      </div>
                    </div>
                  ) : null}

                  {/* <div className="">
                    <h4 className="font-semibold">Job Visibility</h4>
                    <div className="flex gap-10 text-xs font-semibold">
                      <div>
                        <Radio name="type" label="Yes" />
                      </div>
                      <div>
                        <Radio name="type" label="No" className="" />
                      </div>
                    </div>
                  </div> */}
                </div>
                <h4 className="font-semibold">Audition Detail</h4>
                <div className="py-3 grid grid-cols-2 gap-3 mb-0 w-full px-2">
                  <div class="w-full   my-3 justify-between border flex items-center">
                    <label class="block w-full  px-3  md:mb-0 border-r py-2  tracking-wide text-black-700 text-xs font-bold mb-2">
                      Character
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <input
                        value={state?.categoryId?.name}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="w-full   my-3 justify-between border flex items-center">
                    <label class="block w-52  px-3  md:mb-0 border-r py-2  tracking-wide text-black-700 text-xs font-bold mb-2">
                      Brief
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <textarea
                        value={state?.brief}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="w-full   my-3 justify-between border flex items-center">
                    <label class="block w-full  px-3  md:mb-0 border-r py-2  tracking-wide text-black-700 text-xs font-bold mb-2">
                      Voice Note
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <audio
                        // src="https://file-examples.com/storage/feefe3d0dd63b5a899e4775/2017/11/file_example_MP3_700KB.mp3"
                        controls
                      />
                    </div>
                  </div>
                  <div class="w-full   my-3 justify-between border flex items-center">
                    <label class="block w-full  px-3  md:mb-0 border-r py-2  tracking-wide text-black-700 text-xs font-bold mb-2">
                      Document Upload
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <a target="_blank" href={state?.documentUpload}>
                        {/* <a href="#"> */}
                        <button className="rounded-full py-1 px-4 bg-gray-100 font-medium">
                          View Pdf
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full px-4  ">
              <h3 className="font-bold text-lg">Role Details</h3>
              <div className="mx-auto w-full rounded-2xl bg-white p-2 pt-3 pb-6">
                {state?.jobRoleIds?.map((jobRoles) => {
                  return (
                    <div className="mb-2">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex w-full justify-between rounded-lg bg-black/80  px-4 py-2 text-left text-sm font-medium text-white hover:bg-primary focus:outline-none  ">
                              <span className="">
                                Role Title : {jobRoles?.title}
                              </span>
                              <BiChevronUp
                                className={`${
                                  open ? "rotate-180 transform" : ""
                                } h-5 w-5 text-white`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                              <div>
                                {/* {role.map((item) => ( */}
                                <div className="grid grid-cols-12">
                                  <div className="col-span-5 text-primary font-bold">
                                    <h4 className="py-1.5">Role Detail</h4>
                                    <h4 className="py-1.5">Role Title</h4>
                                    <h4 className="py-1.5">Role Type</h4>
                                    <h4 className="py-1.5">
                                      Year Of Experience
                                    </h4>
                                    <h4 className="py-1.5">Gender</h4>
                                    <h4 className="py-1.5">Ethnicity</h4>
                                    <h4 className="py-1.5">Salary Details</h4>
                                    <h4 className="py-1.5">Min. & Max.</h4>
                                    <h4 className="py-1.5">Salary Type</h4>
                                  </div>
                                  <div className="col-span-7">
                                    <h4 className="text-black py-1.5">
                                      {jobRoles?.details}
                                    </h4>
                                    <h4 className="text-black py-1.5">
                                      {jobRoles?.title}
                                    </h4>
                                    <h4 className="text-black py-1.5">
                                      {jobRoles?.type}
                                    </h4>
                                    <h4 className="text-black py-1.5">
                                      {jobRoles?.yearsOfExperience}
                                    </h4>
                                    <h4 className="text-black py-1.5">
                                      {jobRoles?.gender}
                                    </h4>
                                    <h4 className="text-black py-1.5">
                                      {jobRoles?.ethnicity.toString()}
                                    </h4>
                                    <h4 className="text-black py-1.5">
                                      {jobRoles?.salaryDetails?.paymentFlow}
                                    </h4>
                                    <h4 className="text-black flex space-x-4 py-1.5">
                                      <p>
                                        {" "}
                                        {jobRoles?.salaryDetails?.minAmount +
                                          " -- " +
                                          jobRoles?.salaryDetails?.maxAmount}
                                      </p>

                                      <p>{jobRoles?.salaryDetails?.currency}</p>
                                    </h4>
                                    <h4 className="text-black py-1.5">
                                      {jobRoles?.time}
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                  );
                })}
              </div>
            </div>
            {/* Cancel & Save Btn */}
            <div className="flex items-center justify-end space-x-4 mb-4 duration-300">
              <button
                onClick={openModal}
                className=" px-14 py-1 border-[1px] border-primary text-primary hover:bg-primary hover:text-white rounded-md "
              >
                REJECTED
              </button>
              <button
                type="button"
                onClick={handleApprove}
                className=" px-14 py-1 border-[1px] border-primary text-white hover:bg-white bg-primary hover:text-primary rounded-md "
              >
                APPROVED
              </button>
            </div>
          </div>
        </div>

        {/* Modal section */}
        <div
          className={`w-screen h-screen fixed top-0 left-0 bg-black bg-opacity-20 ${
            modal ? "block duration-500" : "hidden duration-500"
          } ${close ? "hidden" : ""}`}
        >
          <div
            className={`text-sm absolute top-[50%] left-[50%]  w-[38%] translate-x-[-50%] h-auto  translate-y-[-50%] bg-[#fff] rounded-md shadow-lg pb-6 ${
              modal ? "duration-500" : "duration-500"
            }`}
          >
            <div className="bg-primary w-full flex items-center justify-between px-6 py-4 rounded-tl-md rounded-tr-md text-white">
              <div className="text-sm font-bold">Reason of Cancellation</div>
              <AiOutlineClose
                size={20}
                onClick={closeModal}
                className="cursor-pointer font-bold"
              />
            </div>

            <div className="text-center p-6">
              <Textarea label="Reason" color="purple" />
            </div>

            <div className="grid grid-cols-12 gap-5 px-6 py-8">
              <div className="col-span-6">
                <div className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white">
                  <MdOutlineDoneOutline className="text-2xl" />
                  <div class="uppercase">
                    <button
                      type="button"
                      onClick={async function handleReject(e) {
                        e.preventDefault();
                        const data = {
                          id: state._id,
                          status: "REJECTED",
                        };

                        try {
                          const response = await updateJob(data);
                          if (response.status == 200) {
                            toast(response.message);
                            setClose(true);
                            setModal(false);
                          } else {
                            toast(response.message);
                          }
                        } catch (error) {
                          console.log(error);
                        }
                        console.log(data);
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-span-6">
                <div
                  onClick={closeModal}
                  className="flex text-primary  justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white"
                >
                  <GiCancel className="text-2xl" />
                  <div class="uppercase">
                    <span>Cancel</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal section */}
      </div>
    </>
  );
}

export default JobDetails;
