import React, { useState, useEffect, Fragment } from "react";
import LeftSideBar from "../sidebar/LeftSideBar";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { HiEye } from "react-icons/hi";
import { MdDelete, MdOutlineDoneOutline } from "react-icons/md";
import { FaHandHoldingUsd } from "react-icons/fa";
import { NavLink, Link } from "react-router-dom";

import Header from "../sidebar/Header";
import { GiCancel } from "react-icons/gi";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { getAllContactusDataShow } from "../apiconfig/authService";
import { Pagination } from "rsuite";
import 'rsuite/dist/rsuite.min.css';
export default function ApplicationData() {
  const [contactusData, setAllContactusData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [refetchData, setRefetchData] = useState(false);

  // Tinku
  const [params, setParams] = useState({ pageNo: 1, pageSize: 10 });
  const getAllContactUsData = async () => {
    try {
      const response = await getAllContactusDataShow(pageSize === "All" ? totalCount : pageSize, pageSize === "All" ? 1 : pageNo);
      setAllContactusData(response.data);
      setTotalCount(response.pagination.totalCount);
      setTotalPages(Math.ceil(response.pagination.totalPages));
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageClick = async (data) => {
    console.log("data.selected", data.selected);
    const currentPage = data.selected + 2;
    // alert(currentPage);
    setPageSize("10");
    setParams({ ...params, pageNo: currentPage });
    console.log(currentPage);
    getAllContactUsData(params);
  };

  useEffect(() => {
    getAllContactUsData();
  }, [pageNo, refetchData,]);

  const handlePageSizeChange = (e) => {
    setPageSize(e.target.value);
    setPageNo(1);
    setRefetchData(!refetchData);
  };

  return (
    <>
      <Header />
      <div className="col12SwmDashBord bg-white ">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>
        <div className="col8SemNvr mt-[70px] ml-[5%]">
          {/* Bredcrums */}
          <div className="flex justify-between items-end">
            <div className=" ">
              <div className="HdingSwnvr my-3 px-5">
                <h5> Contact Us </h5>
              </div>
            </div>
          </div>

          {/* Bredcrums End */}
          <div className="flex col12WdthSwhnMn mt-2">
          <div className="flex">
            <label>Show </label>
            <div class="flex mt-[-2px] mr-2 ml-2 items-center ServeCtgrySwn">
              <div class="relative w-full">
                <select
                  className="bg-gray-50 py-1 border border-gray-300 text-gray-500 text-sm rounded-sm  block w-full px-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                  value={pageSize}
                  onChange={handlePageSizeChange}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={250}>250</option>
                  <option value={500}>500</option>
                  <option value="All">All</option>
                </select>
              </div>
            </div>
            <label> records per page. </label>
          </div>
            <div class=" relative  shadow-md sm:rounded-lg  mt-5">
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
                <thead class="text-sm text-gray-700 uppercase bg-orange-100	 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" class="py-4 px-4 w-[100px]">
                      S No.
                    </th>
                    <th scope="col" class="py-4 px-4 w-[100px]">
                      Name
                    </th>
                    <th scope="col" class="py-4 px-4 w-[100px]">
                      Phone Number
                    </th>

                    <th scope="col" class="py-4 px-4 w-[100px]">
                      Email
                    </th>

                    <th scope="col" class="py-4 px-4 w-[200px]">
                      Message
                    </th>
                  </tr>
                </thead>

                <tbody className="TableService">
                  {contactusData &&
                    contactusData.map((itemContactus, index) => (
                      <tr
                        class="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                        key={index}
                      >
                        <th
                          scope="row"
                          class="py-3 px-4 font-medium text-sm text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {index + 1}
                        </th>
                        <th
                          scope="row"
                          class="py-3 px-4 font-medium text-sm text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {itemContactus.name}
                        </th>
                        <td class="py-3 text-sm">
                          {itemContactus.phoneNumber}
                        </td>
                        <td class="py-3 text-sm">{itemContactus.email}</td>
                        <td class="py-3 text-sm">{itemContactus.msg}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className=" pb-[20px] pl-[20px] pr-[20px] w-full flex justify-between mt-3">
            {totalCount === 0 ? <div>Showing 0 - 0 entries of 0 entries. </div> : pageSize === "All" ? (<div>Showing {1} - {totalCount} entries of {totalCount} entries.</div>) :
              (<div>Showing {(pageNo - 1) * pageSize + 1} - {totalCount < (pageSize * pageNo) ? totalCount : pageSize * pageNo} entries of {totalCount} entries.</div>)}
            {/* Pagination Start */}
            <div style={{ margin: "100" }}>
              <Pagination
                prev
                last
                next
                first
                size='sm'
                ellipsis
                boundaryLinks
                onSelect={setPageNo}
                limit={pageSize ==="ALL" ? totalCount : pageSize}
                onChangeLimit={setPageSize}
                layout={['pager', 'skip']}
                pages={totalPages}
                total={totalCount}
                maxButtons={5}
                activePage={pageNo}
                onChangePage={(page) => { setPageNo(page) }}
              />
            </div>
            {/* Pagination End */}
          </div>
        </div>
      </div>
    </>
  );
}
