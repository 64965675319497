import React from "react";
import { AiOutlineRight } from "react-icons/ai";
import { MdEventNote } from "react-icons/md";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { useEffect, useState } from "react";
import { BsCheck } from "react-icons/bs";
import { AiFillCamera } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { AiOutlinePlus } from "react-icons/ai";
import Select from "react-select";

import { GrFormUpload } from "react-icons/gr";
import Header from "../sidebar/Header";
import LeftSideBar from "../sidebar/LeftSideBar";
import { NavLink } from "react-router-dom";

const options = [
    { value: "Instant Job Alerts", label: "Instant Job Alerts" },
    { value: "Online Portfolio", label: "Online Portfolio" },
    { value: "Upload Photos", label: "Upload Photos" },
    { value: "Upload Videos", label: "Upload Videos" },
    { value: "Apply For Jobs", label: "Apply For Jobs" },
];

const ViewFeature = () => {

    return (
        <div>
            <Header />
            <div className="col12SwmDashBord bg-white">
                <div className="col4SemNvr">
                    <LeftSideBar />
                </div>
                <div className="col8SemNvr mt-[70px] ml-[5%]">
                    <div className="grid grid-cols-11">
                        <div className="col-span-9 py-4">
                            <div className="text-black flex items-center text-sm tracking-wider font-bold">
                                Subscription{" "}
                                <span className="space-x-1 flex items-center text-secondary">
                                    <AiOutlineRight />
                                    Add Subscription
                                </span>
                                <span className="space-x-1 flex items-center text-secondary">
                                    <AiOutlineRight />
                                    View Item
                                </span>
                            </div>
                        </div>

                    </div>
                    {/* Tabs are Here  */}

                    {/* Start Complete Form */}
                    <div className="border-[1.5px] border-gray-200 py-4 rounded-md">

                        <div className=" bg-grey pl-6 py-1 text-sm text-[#4A4A4A] font-semibold">
                            Create Item
                        </div>
                        <div className="flex py-5 pl-6 space-x-6 items-center">
                            <div className="w-[250px]">
                                <label className="text-sm font-semibold text-[#898989]">
                                    Name
                                </label>
                                <br />
                                <input
                                    type="text"
                                    placeholder="Enter Name"
                                    className="border-[1px] placeholder:text-xs  py-[.6rem] px-2 rounded-md  text-xs border-gray-300 w-full"
                                />
                            </div>
                            <div className="w-96">
                                <label className="text-sm font-semibold text-[#898989]">
                                    Features
                                </label>
                                <br />
                                {/* <input
                                    type="text"
                                    placeholder="New Membership"
                                    className="border-[1px] placeholder:text-xs  py-[.6rem] px-2 rounded-md  text-xs border-gray-300 w-full"
                                /> */}
                                <Select
                                    defaultValue={[options[2], options[4]]}
                                    isMulti
                                    name="colors"
                                    options={options}
                                    className="basic-multi-select"
                                    classNamePrefix="select" 
                                />
                            </div>
                            <div>
                                <label className="text-sm font-semibold text-[#898989]">
                                    Free / Paid
                                </label>
                                <br />
                                <div className="">
                                    <div className="flex gap-4 justify-center">
                                    <div className="form-check">
                                                <input  className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                <label className="form-check-label inline-block text-gray-800" for="flexRadioDefault1">
                                                    Free
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input  className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                                <label className="form-check-label inline-block text-gray-800" for="flexRadioDefault2">
                                                    Paid
                                                </label>
                                            </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    {/* Cancel & Save Btn */}
                    <div className="flex items-center justify-end pt-4 space-x-4 duration-300">
                        <NavLink to="/subscription/add-subscription"

                            className=" px-14 py-1 border-[1px] border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                        >
                            CANCEL
                        </NavLink>
                        <button
                            type="button"
                            className=" px-14 py-1 border-[1px] border-primary text-white hover:bg-white bg-primary hover:text-primary rounded-md "
                        >
                            SAVE
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewFeature;
