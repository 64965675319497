import React, { useState, useEffect, Fragment } from "react";
import LeftSideBar from "../sidebar/LeftSideBar";
import { HiEye } from "react-icons/hi";
import { MdDelete, MdOutlineDoneOutline, MdSettingsBackupRestore } from "react-icons/md";
import { NavLink, Link } from "react-router-dom";
import { GiCancel } from "react-icons/gi";
import Header from "../sidebar/Header";
import { AiOutlineClose } from "react-icons/ai";
import { deleteEmployerPermanently, getAllEmployers, restoreEmployerById } from "../apiconfig/authService";
import {
    setEmployerActivationStatus,
} from "../apiconfig/authService";
import { toast } from "react-toastify";
import { Pagination } from "rsuite";
import 'rsuite/dist/rsuite.min.css';
export default function TrashEmployer() {
    const employerDetails = [
        {
            id: 1,
            employerName: "Sanjay Jangid",
            email: "admin@gmail.com",
            phone: "0987654576",
            accountActivation: "Active",
        },
        {
            id: 2,
            employerName: "Sanjay Jangid",
            email: "admin@gmail.com",
            phone: "0987654576",
            accountActivation: "Active",
        },
        {
            id: 3,
            employerName: "Sanjay Jangid",
            email: "admin@gmail.com",
            phone: "0987654576",
            accountActivation: "Active",
        },
        {
            id: 4,
            employerName: "Sanjay Jangid",
            email: "admin@gmail.com",
            phone: "0987654576",
            accountActivation: "Inactive",
        },
        {
            id: 5,
            employerName: "Sanjay Jangid",
            email: "admin@gmail.com",
            phone: "0987654576",
            accountActivation: "Active",
        },
        {
            id: 6,
            employerName: "Sanjay Jangid",
            email: "admin@gmail.com",
            phone: "0987654576",
            accountActivation: "Inactive",
        },
        {
            id: 7,
            employerName: "Sanjay Jangid",
            email: "admin@gmail.com",
            phone: "0987654576",
            accountActivation: "Active",
        },
        {
            id: 8,
            employerName: "Sanjay Jangid",
            email: "admin@gmail.com",
            phone: "0987654576",
            accountActivation: "Active",
        },
    ];

    const restoreEmployer = async () => {
        try {
            //   const response = await restoreDeletedemployer();
            const response = await restoreEmployerById(empId);
            fetchAllEmployers();
        } catch (error) {
            console.log(error);
        }
    }


    //   Modal
    const [modal, setModal] = useState(false);
    const [close, setClose] = useState(false);
    const [restoreModal, setRestoreModal] = useState(false);
    const [closeRestore, setCloseRestore] = useState(false);
    const openModal = () => {
        setModal(true);
        setClose(false);
    };

    const closeModal = () => {
        setClose(true);
        setModal(false);
    };

    const openRestoreModal = () => {
        setRestoreModal(true);
        setCloseRestore(false);
    };
    
    const closeRestoreModal = () => {
        setRestoreModal(false);
        setCloseRestore(true);
    };
    
    const [searchId, setSearchId] = useState("");
    const [date, setDate] = useState("");
    
    const [allEmployers, setAllEmployers] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [refetchData, setRefetchData] = useState(false);

    const fetchAllEmployers = async () => {
        try {
            const response = await getAllEmployers(true, pageSize === "All" ? totalCount : pageSize, pageSize === "All" ? 1 : pageNo, searchId, date,);
            setAllEmployers(response.data);
            setTotalCount(response.pagination.totalCount);
            setTotalPages(Math.ceil(response.pagination.totalPages));
            console.log(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const setEmployerAccountActivation = async (data) => {
        try {
            const response = await setEmployerActivationStatus(data);
        } catch (error) {
            console.log(error);
        }
    };

    const [empId, setEmpId] = useState("");

    const deleteEmployer = async () => {
        try {
            const response = await deleteEmployerPermanently(empId);
            if (response.status == true) {
                toast(response.message);
            } else {
                toast(response.message);
            }
            fetchAllEmployers();
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchAllEmployers();
    }, [pageNo, refetchData,searchId, date]);
    const handlePageSizeChange = (e) => {
        setPageSize(e.target.value);
        setPageNo(1);
        setRefetchData(!refetchData);
    };


    return (
        <>
            <Header />
            <div className="col12SwmDashBord bg-white ">
                <div className="col4SemNvr">
                    <LeftSideBar />
                </div>
                <div className="col8SemNvr mt-[70px] ml-[5%]">
                    {/* Bredcrums */}
                    <div className="grid grid-cols-12">
                        <div className="col-span-10 ">
                            <div className="HdingSwnvr my-3 px-5">
                                <h5> Employer </h5>
                            </div>
                        </div>
                    </div>
                    <hr className="w-full bg-gray-500 h-[2px]" />
                    {/* Bredcrums End */}

                    <div className="flex col12WdthSwhnMn mt-2">
                        <div className="flex  items-center mb-5 gap-4">
                            <div className="WdtPerMn">
                                <label>Search by EmployerId or Name</label>
                                <div class="flex items-center ServeCtgrySwn pt-3">
                                    <label for="simple-search" class="sr-only">
                                        Search
                                    </label>
                                    <div class="relative w-full">
                                        <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                            <svg
                                                aria-hidden="true"
                                                class="w-5 h-5 text-gray-500 dark:text-gray-400"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                        </div>
                                        <input
                                            type="text"
                                            onChange={(e) => setSearchId(e.target.value)}
                                            id="simple-search"
                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm  block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                                            placeholder="Search "
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="WdtPerMn">
                                <label>Search by Date</label>
                                <div class="flex items-center ServeCtgrySwn pt-3">
                                    <div class="relative w-full">
                                        <input
                                            type="date"
                                            onChange={(e) =>
                                                setDate(e.target.value.split("-").reverse().join("/"))
                                            }
                                            className="bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-sm  block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Table  */}
                        <div className="flex">
                            <label>Show </label>
                            <div class="flex mt-[-2px] mr-2 ml-2 items-center ServeCtgrySwn">
                                <div class="relative w-full">
                                    <select
                                        className="bg-gray-50 py-1 border border-gray-300 text-gray-500 text-sm rounded-sm  block w-full px-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                                        value={pageSize}
                                        onChange={handlePageSizeChange}
                                    >
                                        <option value={10}>10</option>
                                        <option value={25}>25</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                        <option value={250}>250</option>
                                        <option value={500}>500</option>
                                        <option value="All">All</option>
                                    </select>
                                </div>
                            </div>
                            <label> records per page. </label>
                        </div>
                        <div class="relative shadow-md sm:rounded-lg mt-5">
                            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
                                <thead class="text-sm text-gray-700 uppercase bg-orange-100	 dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                        <th scope="col" class="py-4 px-4">
                                            Id
                                        </th>
                                        <th scope="col" class="py-4 px-4">
                                            Employer Name
                                        </th>
                                        <th scope="col" class="py-4 px-4">
                                            Email
                                        </th>
                                        <th scope="col" class="py-4 px-4">
                                            Phone
                                        </th>
                                        <th scope="col" class="py-4 px-4 text-center">
                                            Account Activation
                                        </th>
                                        <th scope="col" class="py-4 px-6 ml-6">
                                            Status
                                        </th>

                                        <th scope="col" class="py-4 px-4">
                                            Action
                                        </th>
                                    </tr>
                                </thead>

                                <tbody className="TableService">
                                    {allEmployers.map && allEmployers
                                        // .filter((value) => {
                                        //     if (searchId === "" && date === "") {
                                        //         return value;
                                        //     } else if (
                                        //         searchId !== "" &&
                                        //         date === "" &&
                                        //         (value?.employerId
                                        //             ?.toLowerCase()
                                        //             .includes(searchId?.toLowerCase()) ||
                                        //             value?.fullName
                                        //                 ?.toLowerCase()
                                        //                 .includes(searchId?.toLowerCase()))
                                        //     ) {
                                        //         return value;
                                        //     } else if (
                                        //         searchId === "" &&
                                        //         date !== "" &&
                                        //         new Date(value?.createdAt).toLocaleDateString(
                                        //             "en-GB"
                                        //         ) === date
                                        //     ) {
                                        //         return value;
                                        //     }
                                        // })
                                        .map((item, index) => (
                                            <tr
                                                class="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                                                key={index}
                                            >
                                                <th
                                                    scope="row"
                                                    class="py-3 px-4 font-medium text-sm text-gray-900 whitespace-nowrap dark:text-white"
                                                >
                                                    {item.employerId}
                                                </th>
                                                <th
                                                    scope="row"
                                                    class="py-3 px-4 font-medium text-sm text-gray-900 whitespace-nowrap dark:text-white"
                                                >
                                                    {item.fullName}
                                                </th>
                                                <td class="py-3 text-sm">{item.email}</td>
                                                <td class="py-3 px-4 text-sm">{item.phoneNumber}</td>

                                                <td class="py-3 px-2 text-sm text-center">
                                                    {/* <button className="bg-primary w-28 py-2 text-white font-semibold">
                          {" "}
                          {item.accountActivation}
                        </button> */}
                                                    <label class="inline-flex relative items-center cursor-pointer">
                                                        <input
                                                            type="checkbox"
                                                            value=""
                                                            onClick={() =>
                                                                setEmployerAccountActivation(item._id)
                                                            }
                                                            checked={!item.isDeleted}
                                                            class="sr-only peer"
                                                        />
                                                        <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                                    </label>
                                                </td>

                                                <td className="py-3  text-sm px-4">
                                                    <div
                                                        className={` text-black mx-7 text-center py-2 rounded-md ${item?.employerstatus?.toLowerCase() ===
                                                            "active" ||
                                                            item?.employerstatus?.toLowerCase() === "approved"
                                                            ? "bg-green-500"
                                                            : item?.employerstatus?.toLowerCase() ===
                                                                "requested" ||
                                                                item?.employerstatus?.toLowerCase() ===
                                                                "pending" ||
                                                                item?.employerstatus?.toLowerCase() ===
                                                                "applied"
                                                                ? "bg-blue-500"
                                                                : item?.employerstatus?.toLowerCase() ===
                                                                    "rejected"
                                                                    ? "bg-red-500"
                                                                    : null
                                                            }`}
                                                    >
                                                        {item?.employerstatus?.toUpperCase()}
                                                    </div>
                                                </td>

                                                <td class="py-3 text-sm">
                                                    <div className="flex gap-5">
                                                        <Link to={`/manage/employer-detail/${item._id}`}>
                                                            {" "}
                                                            <HiEye
                                                                className="bg-[#0a65cc] text-white p-2"
                                                                size={32}
                                                            />
                                                        </Link>
                                                        <MdDelete
                                                            onClick={(e) => {
                                                                setEmpId(item._id);
                                                                openModal();
                                                            }}
                                                            className="bg-red-600 text-white p-2"
                                                            size={32}
                                                        />
                                                        <MdSettingsBackupRestore
                                                            className="bg-[#0a65cc] cuesor-pointer text-white p-2"
                                                            size={32}
                                                            onClick={(e) => {
                                                                openRestoreModal();
                                                                setEmpId(item._id);
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>

                        {/* Table End */}
                    </div>
                    <div className=" pb-[20px] pl-[20px] pr-[20px] w-full flex justify-between mt-3">
                        {totalCount === 0 ? <div>Showing 0 - 0 entries of 0 entries. </div> : pageSize === "All" ? (<div>Showing {1} - {totalCount} entries of {totalCount} entries.</div>) :
                            (<div>Showing {(pageNo - 1) * pageSize + 1} - {totalCount < (pageSize * pageNo) ? totalCount : pageSize * pageNo} entries of {totalCount} entries.</div>)}
                        {/* Pagination Start */}
                        <div style={{ margin: "100" }}>
                            <Pagination
                                prev
                                last
                                next
                                first
                                size='sm'
                                ellipsis
                                boundaryLinks
                                onSelect={setPageNo}
                                limit={pageSize === "ALL" ? totalCount : pageSize}
                                onChangeLimit={setPageSize}
                                layout={['pager', 'skip']}
                                pages={totalPages}
                                total={totalCount}
                                maxButtons={5}
                                activePage={pageNo}
                                onChangePage={(page) => { setPageNo(page) }}
                            />
                        </div>
                        {/* Pagination End */}
                    </div>
                </div>
                {/* Modal section */}
                <div
                    className={`w-screen h-screen fixed top-0 left-0 bg-black bg-opacity-20 ${modal ? "block duration-500" : "hidden duration-500"
                        } ${close ? "hidden" : ""}`}
                >
                    <div
                        className={`text-sm absolute top-[50%] left-[50%]  w-[38%] translate-x-[-50%] h-auto  translate-y-[-50%] bg-[#fff] rounded-md shadow-lg pb-6 ${modal ? "duration-500" : "duration-500"
                            }`}
                    >
                        <div className="bg-primary w-full flex items-center justify-between px-6 py-4 rounded-tl-md rounded-tr-md text-white">
                            <div className="text-sm font-bold">Delete</div>
                            <AiOutlineClose
                                size={20}
                                onClick={closeModal}
                                className="cursor-pointer font-bold"
                            />
                        </div>

                        <div className="text-center py-8">
                            <h2 className="font-semibold text-lg">
                                Are you sure you permanently want to delete this item?
                            </h2>
                        </div>

                        <div className="grid grid-cols-12 gap-5 px-6 py-8">
                            <div className="col-span-6">
                                <div className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white">
                                    <MdOutlineDoneOutline className="text-2xl" />
                                    <div
                                        onClick={(e) => {
                                            deleteEmployer();
                                            closeModal();
                                        }}
                                        className="uppercase"
                                    >
                                        <span>Confirm</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-6">
                                <div
                                    onClick={closeModal}
                                    className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white"
                                >
                                    <GiCancel className="text-2xl" />
                                    <div class="uppercase">
                                        <span>Cancel</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Modal section */}

                {/* Restore Modal section */}
                <div
                    className={`w-screen h-screen fixed top-0 left-0 bg-black bg-opacity-20 ${restoreModal ? "block duration-500" : "hidden duration-500"
                        } ${closeRestore ? "hidden" : ""}`}
                >
                    <div
                        className={`text-sm absolute top-[50%] left-[50%]  w-[38%] translate-x-[-50%] h-auto  translate-y-[-50%] bg-[#fff] rounded-md shadow-lg pb-6 ${restoreModal ? "duration-500" : "duration-500"
                            }`}
                    >
                        <div className="bg-primary w-full flex items-center justify-between px-6 py-4 rounded-tl-md rounded-tr-md text-white">
                            <div className="text-sm font-bold">Restore</div>
                            <AiOutlineClose
                                size={20}
                                onClick={closeRestoreModal}
                                className="cursor-pointer font-bold"
                            />
                        </div>

                        <div className="text-center py-8">
                            <h2 className="font-semibold text-lg">
                                Are you sure you want to restore this item?
                            </h2>
                        </div>

                        <div className="grid grid-cols-12 gap-5 px-6 py-8">
                            <div className="col-span-6">
                                <div className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white">
                                    <MdOutlineDoneOutline className="text-2xl" />
                                    <div
                                        className="uppercase"
                                        onClick={(e) => {
                                            restoreEmployer();
                                            closeRestoreModal();
                                        }}
                                    >
                                        <span>Confirm</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-6">
                                <div
                                    onClick={closeRestoreModal}
                                    className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white"
                                >
                                    <GiCancel className="text-2xl" />
                                    <div class="uppercase">
                                        <span>Cancel</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Restore Modal section */}


            </div>
        </>
    );
}
