import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { loginWithPassword } from "../../apiconfig/authService";
import { DialogBody, DialogHeader, Dialog } from "@material-tailwind/react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AdminLoginapi } from "../../apiconfig/authService";
import { setToken } from "../../utils/localStorage";

const Credentials = () => {
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState("password");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);
  const [values, setValues] = useState({
    username: "",
    password: "",
  });
  console.log("sdsd", values);
  const togglePassword = () => {
    passwordType === "password"
      ? setPasswordType("text")
      : setPasswordType("password");
  };
  // const handleUser = (e) => {
  //   setValues({
  //     ...values,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  // login api
  const handleLogin = async (e) => {
    e.preventDefault();
    if (!values.username) {
      toast("please enter username !");
    } else if (!values.password) {
      toast("please enter password");
    } else {
      const data = {
        username: values.username,
        password: values.password,
      };
      try {
        const response = await AdminLoginapi(data);
        console.log(response);
        if (response.code) {
          setToken(response.data.token);
          localStorage.setItem("user", response.data.username);
          toast("login successful !");
          navigate("/dashboard");
        } else {
          toast(response.message);
        }
      } catch (error) {
        toast(error.response.data.message);
      }
    }
  };
  // login api

  return (
    <div>
      <>
        <div className="grid grid-cols-12 md:h-screen">
          <div
            className="col-span-12 md:col-span-7 h-screen"
            style={{ backgroundImage: "url('/assets/images/welcome.png')" }}
          >
            <div className="flex justify-center items-center h-full">
              <img
                alt="img"
                src="/assets/images/logo_purple-bg.png"
                className=" w-96"
              />
            </div>
          </div>

          <div className="col-span-12 md:col-span-5 relative py-10 flex flex-col justify-center items-center">
            <div className=" flex flex-col justify-center items-center">
              <div className="LogPswmvrMn">
                {/* <p
                  className="text-primary font-bold text-3xl italic"
                  style={{ fontSize: "bold", fontFamily: "monospace" }}
                >
                  Milestone 101
                </p> */}
              </div>
              <div className="text-center text-5xl text-primary baskervville">
                Welcome to
              </div>
            </div>
            <div className=" md:w-[400px] mx-auto md:mb-0 px-8 xl:px-0 lg:px-8">
              <div className="text-center text-lg text-lightgold pb-6 pt-4 font-semibold">
                Enter Your credentials
              </div>

              <div className="">
                <form className="space-y-6">
                  <div className="">
                    <input
                      value={values.username}
                      onChange={(e) =>
                        setValues({ ...values, username: e.target.value })
                      }
                      type="text"
                      name="username"
                      placeholder="Username"
                      className="outline-none placeholder-primary border-[1px] border-[#164732] border-opacity-50 text-sm  w-full h-[45px] rounded-md shadow-md  pl-4"
                    />
                    {/* <div className="text-xs font-semibold text-red-400 pt-1">
                      {" "}
                      {errors.emailOrPhone && <p>{errors.emailOrPhone}</p>}
                    </div> */}
                  </div>
                  <div className="">
                    <div className="outline-none placeholder-primary  h-[45px]  p-4 pl-4 space-x-2 flex justify-between items-center shadow-md  rounded-md border-[#164732] border-opacity-50 w-full text-sm  border-[1px]">
                      <input
                        value={values.password}
                        onChange={(e) =>
                          setValues({ ...values, password: e.target.value })
                        }
                        type={passwordType}
                        name="password"
                        placeholder="Password"
                        className="outline-none placeholder-primary  h-[40px]  rounded-md w-full border-none text-sm"
                      />

                      <div
                        className="cursor-pointer pr-2 font-semibold text-primary text-opacity-60"
                        onClick={togglePassword}
                      >
                        {passwordType === "password" ? "Show" : "Hide"}
                      </div>
                    </div>
                    {/* <div className="text-xs font-semibold text-red-400 pt-1">
                      {" "}
                      {errors.password && <p>{errors.password}</p>}
                    </div> */}
                  </div>
                </form>
                {/* <div className="text-lightgold text-right mt-4 text-sm font-semibold hover:text-primary">
                  <Link to="/reset-password">Forgot your Password?</Link>
                </div> */}

                <button className="w-full  font-semibold text-md mt-8 text-white">
                  <button
                    onClick={handleLogin}
                    className="text-uppercase w-full  py-3 bg-primary block outline-none rounded-md text-sm hover:bg-lightgold duration-500"
                  >
                    Login
                  </button>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="bgdrop w-screen">
          <Dialog open={open} handler={handleOpen} className="max-w-[600px]">
            <DialogHeader className="text-base font-semibold bg-primary text-white">
              Alert!!
            </DialogHeader>
            <DialogBody className="w-full text-lg font-semibold flex flex-col justify-center items-center">
              <div className="text-md text-primary block pb-2"></div>
            </DialogBody>
            <div className="p-4 flex justify-center items-center gap-3">
              <button
                onClick={handleOpen}
                className="mr-1 bg-primary hover:bg-lightgold duration-500 text-sm px-10 rounded-md text-white font-semibold py-2"
              >
                <span>Okay</span>
              </button>
              {/* <button
                onClick={handleOpen}
                className=" bg-primary hover:bg-lightgold duration-500 text-sm px-10 rounded-md text-white font-semibold py-2"
              >
                <span>Confirm</span>
              </button> */}
            </div>
          </Dialog>
        </div>
      </>
    </div>
  );
};

export default Credentials;
