import {
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { UserLogout } from "../apiconfig/authService";
// import Dropdown from 'react-bootstrap/Dropdown';

const Header = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    UserLogout().then((res) => {
      console.log(res);
      navigate("/");
    });
  };

  const handleChangePassword = () => {
    navigate("../change-password");
  };

  return (
    <>
      <header className="bg-primary px-3 pt-2 fixed top-0 left-0 w-full z-[10]">
        <nav>
          <div className="flex justify-between items-center">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <NavLink className="w-[120px] inline-block">
                <img
                  className="w-full"
                  alt="Logo"
                  src="/assets/images/MilestoneWhite.png"
                />
              </NavLink>
            </div>

            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 flex items-center justify-end">
              <Menu>
                <MenuHandler>
                  <div className="flex items-center">
                    <span className="text-sm mr-2 text-white"> milestone </span>
                    <span className="text-xs flex justify-center items-center bg-white w-[28px] h-[28px] rounded-[50%]">
                      {" "}
                      N{" "}
                    </span>
                  </div>
                </MenuHandler>
                <MenuList className="divide-y-1 divide-y">
                  <MenuItem onClick={handleChangePassword} className="hover:text-white bg-transparent hover:bg-primary text-black group flex w-full items-center rounded-md px-2 py-2 text-sm">
                    Change Password
                  </MenuItem>
                  <MenuItem onClick={handleLogout} className="hover:text-white bg-transparent hover:bg-primary text-black group flex w-full items-center rounded-md px-2 py-2 text-sm">
                    Logout
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
