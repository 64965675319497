import React, { useState, useEffect } from "react";
import LeftSideBar from "../sidebar/LeftSideBar";
import { NavLink } from "react-router-dom";
import Header from "../sidebar/Header";
import Select from "react-select";
import { Country, State, City } from "country-state-city";
import { getDirectoryTypes , addNewDirectory } from "../apiconfig/authService"


function Home() {
    const [editState, setEditState] = useState(true);
    const options = [
        { value: "Production Type", label: "Production Type" },
        { value: "Ethnicity", label: "Ethnicity" },
    ];
    const [state, setState] = useState({
        // name: "",
        // email: "",
        // phone: "",
        // telephone: "",
        // address: "",
        // country: "",
        // state: "",
        // city: "",
        // pincode: "",
        // branchoffice: "",

        type :"",
        name:"",
        address:"",
        country:"",
        state:"",
        city:"",
        branchOffice:"",
        email:"",
        secondaryEmail:"placeholder@email.com",
        phoneNumber:"",
        website:""
    });

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

      const [upload1, setUpload1] = useState();
      function handleUpload1(e) {
        console.log(e.target.files);
        document.getElementById("banner1").classList.remove("hidden");
        setUpload1(URL.createObjectURL(e.target.files[0]));
      }

       const [upload2, setUpload2] = useState();
       function handleUpload2(e) {
         console.log(e.target.files);
         document.getElementById("banner2").classList.remove("hidden");
         setUpload2(URL.createObjectURL(e.target.files[0]));
       }
        const [upload3, setUpload3] = useState();
        function handleUpload3(e) {
          console.log(e.target.files);
          document.getElementById("banner3").classList.remove("hidden");
          setUpload3(URL.createObjectURL(e.target.files[0]));
        }

         const [uploadThumb, setUploadThumb] = useState();
         function handleUploadthumb(e) {
           console.log(e.target.files);
           document.getElementById("thumbnail").classList.remove("hidden");
           setUploadThumb(URL.createObjectURL(e.target.files[0]));
         }
           const [uploadImage, setUploadImage] = useState();
           function handleUploadimage(e) {
             console.log(e.target.files);
             document.getElementById("imageup").classList.remove("hidden");
             setUploadImage(URL.createObjectURL(e.target.files[0]));
           }
    const handleSubmit = async () => {
        console.log(state);
        try {
            const response = await addNewDirectory({
                type : state.type.value,
                name:state.name,
                address: state.address,
                country:state.country.name,
                state:state.state.name,
                city:state.city.name,
                branchOffice:state.branchOffice,
                email:state.email,
                secondaryEmail:"placeholder@email.com",
                phoneNumber:state.phoneNumber,
                website:state.website
            })
        } catch (error) {
            console.log(error);
        }
    } 

    let mycountry = Country.getAllCountries();
    let mystate = State.getStatesOfCountry(state.country?.isoCode);
    let mycity = City.getCitiesOfState(
        state.country?.isoCode,
        state.state?.isoCode
    );

    const [dropDown, setDropDown] = useState([]);

  
    const getDropDownOptions = async () => {
        try {
            const response = await getDirectoryTypes();
            console.log(response.data);
            setDropDown(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    let arr = [];
    for (let i = 0; i < dropDown.length; i++) {
        arr[i] = dropDown[i].name;
    }
    
    console.log("statedropdown" , state.type);

    var list = [];
    arr.forEach(function(element) {
    list.push({ label:element, value: element })
    });

    useEffect(() => {
        getDropDownOptions();
        
    }, []);

    return (
      <>
        <Header />
        <div className="col12SwmDashBord">
          <div className="col4SemNvr">
            <LeftSideBar />
          </div>
          <div className="col8SemNvr mt-[70px] ml-[5%]">
            <div className="block w-full p-2 bg-white">
              {/* Brecrums */}
              <div className="flex justify-between mt-2 mb-2 px-4">
                <h4 className="text-xl font-bold">Home</h4>
                <div className="space-x-4">
                  <button
                    onClick={handleSubmit}
                    className=" px-6 py-1 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                  >
                    Save
                  </button>
                  <NavLink
                    to="/cms/latest-updates"
                    className=" px-6 py-1.5 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                  >
                    Back
                  </NavLink>
                </div>
              </div>

              {/* End Brecrums */}

              <div className="block w-full bg-white ">
                <div className="p-4 block">
                  <div class="py-3  gap-3 border-[1px] mb-0 w-full px-2 ">
                    <div class="w-full  my-3">
                      <label
                        class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                        for="grid-first-name"
                      >
                        Upload banner 1
                      </label>
                      <div className="">
                        <input
                          onChange={handleUpload1}
                          class="appearance-none block w-full text-xs border text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                          type="file"
                        />
                      </div>
                      <div id="banner1" className="my-3 hidden">
                        <img className="w-32 h-32" src={upload1} alt="" />
                      </div>
                    </div>

                    <div class="w-full  my-3">
                      <label
                        class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                        for="grid-first-name"
                      >
                        Upload banner 2
                      </label>
                      <div className="">
                        <input
                          onChange={handleUpload2}
                          class="appearance-none block w-full text-xs border text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                          type="file"
                        />
                      </div>
                      <div id="banner2" className="my-3 hidden">
                        <img className="w-32 h-32" src={upload2} alt="" />
                      </div>
                    </div>

                    <div class="w-full  my-3">
                      <label
                        class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                        for="grid-first-name"
                      >
                        Upload banner 3
                      </label>
                      <div className="">
                        <input
                          onChange={handleUpload3}
                          class="appearance-none block w-full text-xs border text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                          type="file"
                        />
                      </div>
                      <div id="banner3" className="my-3 hidden">
                        <img className="w-32 h-32" src={upload3} alt="" />
                      </div>
                    </div>

                    <label
                      class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xl font-bold mb-2"
                      for="grid-first-name"
                    >
                      Our Top Videos
                    </label>

                    <div class="w-full  my-3">
                      <label
                        class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                        for="grid-first-name"
                      >
                        Upload thumbnail
                      </label>
                      <div className="">
                        <input
                          onChange={handleUploadthumb}
                          class="appearance-none block w-full text-xs border text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                          type="file"
                        />
                      </div>
                      <div id="thumbnail" className="my-3 hidden">
                        <img className="w-32 h-32" src={uploadThumb} alt="" />
                      </div>
                    </div>
                    <div class="w-full  my-3">
                      <label
                        class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                        for="grid-first-name"
                      >
                        Video Link
                      </label>
                      <div className="">
                        <input
                          class="appearance-none block w-full text-xs border text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                          type="text"
                        />
                      </div>
                    </div>

                    <label
                      class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xl font-bold mb-2"
                      for="grid-first-name"
                    >
                      Our Top Performers
                    </label>

                    <div class="w-full  my-3">
                      <label
                        class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                        for="grid-first-name"
                      >
                        Upload Image
                      </label>
                      <div className="">
                        <input
                          onChange={handleUploadimage}
                          class="appearance-none block w-full text-xs border text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                          type="file"
                        />
                      </div>
                      <div id="imageup" className="my-3 hidden">
                        <img className="w-32 h-32" src={uploadImage} alt="" />
                      </div>
                    </div>
                    <div class="w-full  my-3">
                      <label
                        class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                        for="grid-first-name"
                      >
                        Name
                      </label>
                      <div className="">
                        <input
                          class="appearance-none block w-full text-xs border text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                          type="text"
                        />
                      </div>
                    </div>
                    <div class="w-full  my-3">
                      <label
                        class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                        for="grid-first-name"
                      >
                        Category
                      </label>
                      <div className="">
                        <input
                          class="appearance-none block w-full text-xs border text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                          type="text"
                        />
                      </div>
                    </div>

                    {/* <div class="w-full  my-3">
                      <label
                        class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                        for="grid-first-name"
                      >
                        Upload Image
                      </label>
                      <div className="">
                        <input
                          onChange={handleUpload}
                          class="appearance-none block w-full text-xs border text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                          type="file"
                        />
                      </div>
                      <div className="my-3">
                        <img className="" src={upload} alt="" />
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default Home;
