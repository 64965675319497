import React, { useState, useEffect } from "react";

import Header from "../sidebar/Header";
import LeftSideBar from "../sidebar/LeftSideBar";
import { NavLink } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { BiEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { addEmployerCategory, deleteEmpCategory, getEmployerCategory, updateEmployerCategory, getEmployerCategoryPage } from "../apiconfig/authService";
import { MdOutlineDoneOutline } from "react-icons/md";
import { GiCancel } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { toast } from "react-toastify";
import { Pagination } from "rsuite";
import 'rsuite/dist/rsuite.min.css';

const AddEmployerDirectory = () => {
  // const [edit, setEdit] = useState();

  // const handleOpen = () => {
  //   setEdit(true);
  // };

  //   Modal
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  // Modal
  //   Modal




  //   Modal
  let [edit, setEdit] = useState(false);

  function closeedit() {
    setEdit(false);
  }

  const openedit = () => {
    setEdit(true);
  };


  //delete modal
  const [modal, setModal] = useState(false);
  const [close, setClose] = useState(false);
  const openDeleteModal = () => {
    setModal(true);
    setClose(false);
  };

  const closeDeleteModal = () => {
    setClose(true);
    setModal(false);
  };

  //delete modal 

  const performerDetails = [
    {
      id: 1,
      SNo: 1,
      categoryName: "Sanjay Jangid",
    },
  ];
  const [category, setCategory] = useState("");

  const [catId, setCatId] = useState("")

  const handleAddEmployerCategory = async () => {
    if (category === "") {
      toast("Invalid category name.");
      return;
    }

    const data = {
      name: category,
      description: "d",
    }
    try {
      const response = await addEmployerCategory(data)
      console.log(response.data);
      getCategories()
    } catch (error) {
      console.log(error);
    }
    closeModal();
  }
  const [getCats, setGetCats] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [refetchData, setRefetchData] = useState(false);

  const getCategories = async () => {
    try {
      const response = await getEmployerCategoryPage(pageSize === "All" ? totalCount : pageSize, pageSize === "All" ? 1 : pageNo)
      console.log(response);
      setGetCats(response.data);
      setTotalCount(response.pagination.totalCount);
      setTotalPages(Math.ceil(response.pagination.totalPages));
    } catch (error) {
      console.log(error);
    }
  }

  const deleteEmployerCategory = async () => {
    try {
      const response = await deleteEmpCategory({
        params:
        {
          id: catId

        }
      })
      if (response.status == 200) {
        toast(response.message);
      } else {
        toast(response.message);
      }
      getCategories()
      closeDeleteModal()
    } catch (error) {
      console.log(error);
    }
  }

  const [empUpdateId, setEmpUpdateId] = useState("")
  const [empUpdateName, setEmpUpdateName] = useState("")
  const [empUpdateDesc, setEmpUpdateDesc] = useState("d")

  const editEmpCategory = async () => {
    if (empUpdateName === "") {
      toast("Invalid category name.");
      return;
    }

    const data = {
      id: empUpdateId,
      name: empUpdateName,
      description: empUpdateDesc
    }
    try {
      const response = await updateEmployerCategory(data);
      if (response.status == 200) {
        toast(response.message);
      } else {
        toast(response.message);
      }
      closeedit();
      getCategories()
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getCategories()
  }, [refetchData, pageNo])

  const handleNextClick = () => {
    if (pageNo < totalPages)
      setPageNo(pageNo + 1);
  };

  const handlePrevClick = () => {
    if (pageNo > 1)
      setPageNo(pageNo - 1);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(e.target.value);
    setPageNo(1);
    setRefetchData(!refetchData);
  };

  return (
    <div>
      <Header />
      <div className="col12SwmDashBord bg-white mb-20">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>

        <div className="col8SemNvr mt-[70px] ml-[5%]">
          <div className="py-4 mt-10 mx-10 ">
            <button
              onClick={openModal}
              className="px-8 py-2 bg-white border-2 border-black flex justify-end items-end"
            >
              Add Category
            </button>
          </div>
          <div className="">
            <div className="mt-20 ">
                <div className="flex">
                  <label>Show </label>
                  <div class="flex mt-[-2px] mr-2 ml-2 items-center ServeCtgrySwn">
                    <div class="relative w-full">
                      <select
                        className="bg-gray-50 py-1 border border-gray-300 text-gray-500 text-sm rounded-sm  block w-full px-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                        value={pageSize}
                        onChange={handlePageSizeChange}
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={250}>250</option>
                        <option value={500}>500</option>
                        <option value="All">All</option>
                      </select>
                    </div>
                  </div>
                  <label> records per page. </label>
                </div>
              <div className="">
                <div class="  shadow-md sm:rounded-lg  mt-5">
                  <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
                    <thead class="text-sm text-gray-700 uppercase bg-orange-100	 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" class="py-4 px-4">
                          S No.
                        </th>
                        <th scope="col" class="py-4 px-4">
                          Category Name
                        </th>

                        <th scope="col" class="py-4 px-4">
                          Action
                        </th>
                      </tr>
                    </thead>

                    <tbody className="">
                      {getCats.map((item, index) => (
                        <tr
                          class="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                          key={index + 1}
                        >
                          <th
                            scope="row"
                            class="py-3 px-4 font-medium text-sm text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {index + 1}
                          </th>
                          <th
                            scope="row"
                            class="py-3 px-4 font-medium text-sm text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {item.name}
                          </th>

                          <td class="py-3 text-sm">
                            <div className="flex gap-5">
                              <BiEdit
                                className="bg-blue-600 cursor-pointer text-white p-1"
                                size={32}
                                onClick={(e) => {
                                  openedit();
                                  setEmpUpdateId(item._id)
                                }}
                              />
                              <MdDelete
                                onClick={(e) => {
                                  openDeleteModal();
                                  setCatId(item._id)
                                }}
                                className="bg-red-600 cursor-pointer text-white p-2"
                                size={32}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className=" pb-[20px] mt-[20px] w-full flex justify-between">
                  {totalCount === 0 ? <div>Showing 0 - 0 entries of 0 entries. </div> : pageSize === "All" ? (<div>Showing {1} - {totalCount} entries of {totalCount} entries.</div>) :
                    (<div>Showing {(pageNo - 1) * pageSize + 1} - {totalCount < (pageSize * pageNo) ? totalCount : pageSize * pageNo} entries of {totalCount} entries.</div>)}
                  {/* <div>
                      <button
                        type="button"
                        onClick={handlePrevClick}
                        className={pageNo > 1 ? " px-6 py-1 border-[1px] ml-[20px] border-primary text-white hover:bg-white bg-primary hover:text-primary rounded-md " : " px-6 py-1 border-[1px]  ml-[20px] border-primary text-white rounded-md bg-primary"}
                      >
                        Prev
                      </button>
                      <button
                        type="button"
                        onClick={handleNextClick}
                        className={pageNo < totalPages ? " px-6 py-1 border-[1px] ml-[10px] border-primary text-white hover:bg-white bg-primary hover:text-primary rounded-md " : " px-6 py-1 border-[1px] ml-[10px] border-primary text-white rounded-md bg-primary"}
                      >
                        Next
                      </button>
                    </div> */}
                  {/* Pagination Start */}
                  <div style={{ margin: "100" }}>
                    <Pagination
                      prev
                      last
                      next
                      first
                      size='sm'
                      ellipsis
                      boundaryLinks
                      onSelect={setPageNo}
                      limit={pageSize ==="ALL" ? totalCount : pageSize}
                      onChangeLimit={setPageSize}
                      layout={['pager', 'skip']}
                      pages={totalPages}
                      total={totalCount}
                      maxButtons={5}
                      activePage={pageNo}
                      onChangePage={(page) => { setPageNo(page) }}
                    />
                  </div>
                  {/* Pagination End */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal section */}
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-[80%] transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                    <div className=" mt-[10px] ml-[3%] mr-[3%]">
                      <div className="grid grid-cols-11">
                        <div className="col-span-11 py-4 flex justify-between">
                          <div className="text-black flex items-center text-sm tracking-wider font-bold">
                            Employer Category{" "}
                          </div>
                          <div><AiOutlineClose
                            size={20}
                            className="cursor-pointer font-bold"
                            onClick={closeModal}
                          /></div>
                        </div>
                      </div>

                      <div className="mb-10">
                        <div className=" mb-5">
                          <div className="border-[1.5px] border-gray-200 py-4 rounded-md">
                            <div className="flex justify-between bg-grey pl-6 py-1 text-sm text-[#4A4A4A] font-semibold">
                              Add Category
                            </div>

                            <div className="flex py-5 px-4 space-x-6 items-center">
                              <div className="w-full">
                                <label className="text-sm font-semibold text-[#898989]">
                                  Enter Category
                                </label>
                                <br />
                                <input
                                  type="text"
                                  onChange={(e) => { setCategory(e.target.value) }}
                                  placeholder="Enter Category"
                                  className="border-[1px] outline-none placeholder:text-sm  py-2.5 my-2 px-2 rounded-md  text-sm border-gray-300 w-full"
                                />
                              </div>
                            </div>
                          </div>

                          <button
                            onClick={(e) => {
                              // handleOpen();
                              handleAddEmployerCategory();
                            }}
                            className="flex items-center justify-center pt-4 space-x-4 duration-300"
                          >
                            <p className=" px-6 py-1 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md ">
                              Save
                            </p>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
        {/* Modal section */}




        {/* delete modal */}
        <div
          className={`w-screen h-screen fixed top-0 left-0 bg-black bg-opacity-20 ${modal ? "block duration-500" : "hidden duration-500"
            } ${close ? "hidden" : ""}`}
        >
          <div
            className={`text-sm absolute top-[50%] left-[50%]  w-[38%] translate-x-[-50%] h-auto  translate-y-[-50%] bg-[#fff] rounded-md shadow-lg pb-6 ${modal ? "duration-500" : "duration-500"
              }`}
          >
            <div className="bg-primary w-full flex items-center justify-between px-6 py-4 rounded-tl-md rounded-tr-md text-white">
              <div className="text-sm font-bold">Delete</div>
              <AiOutlineClose
                size={20}
                onClick={closeDeleteModal}
                className="cursor-pointer font-bold"
              />
            </div>

            <div className="text-center py-8">
              <h2 className="font-semibold text-lg">
                Are you sure you want to delete this item?
              </h2>
            </div>

            <div className="grid grid-cols-12 gap-5 px-6 py-8">
              <div className="col-span-6">
                <div className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white">
                  <MdOutlineDoneOutline className="text-2xl" />
                  <div
                    onClick={deleteEmployerCategory}
                    className="uppercase">
                    <span>Confirm</span>
                  </div>
                </div>
              </div>

              <div className="col-span-6">
                <div
                  onClick={closeDeleteModal}
                  className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white"
                >
                  <GiCancel className="text-2xl" />
                  <div class="uppercase">
                    <span>Cancel</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* delete modal */}

        <Transition appear show={edit} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeedit}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-[80%] transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                    <div className=" mt-[10px] ml-[3%] mr-[3%]">
                      <div className="grid grid-cols-11 ">
                        <div className="col-span-11 py-4 flex justify-between">
                          <div className="text-black flex items-center text-sm tracking-wider font-bold">
                            Employer Category{" "}
                          </div>
                          <div><AiOutlineClose
                            size={20}
                            className="cursor-pointer font-bold"
                            onClick={closeedit}
                          /></div>
                        </div>
                      </div>

                      <div className="mb-10">
                        <div className=" mb-5">
                          <div className="border-[1.5px] border-gray-200 py-4 rounded-md">
                            <div className="flex justify-between bg-grey pl-6 py-1 text-sm text-[#4A4A4A] font-semibold">
                              Edit Category
                            </div>

                            <div className="flex py-5 px-4 space-x-6 items-center">
                              <div className="w-full">
                                <label className="text-sm font-semibold text-[#898989]">
                                  Edit Category
                                </label>
                                <br />
                                <input
                                  // value={editPerformer?.name}
                                  onChange={(e) =>
                                    setEmpUpdateName(e.target.value)

                                  }
                                  type="text"
                                  placeholder="edit employer category"
                                  className="border-[1px] outline-none placeholder:text-sm  py-2.5 my-2 px-2 rounded-md  text-sm border-gray-300 w-full"
                                />
                              </div>
                            </div>
                          </div>

                          <button
                            onClick={editEmpCategory}
                            className="flex items-center justify-center pt-4 space-x-4 duration-300"
                          >
                            <p className=" px-6 py-1 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md ">
                              Save
                            </p>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>

      </div >

    </div >
  );
};

export default AddEmployerDirectory;
