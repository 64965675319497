import React, { useState } from "react";
import { AiOutlineClose, AiOutlineRight } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { GiCancel } from "react-icons/gi";
import { MdDelete, MdOutlineDoneOutline } from "react-icons/md";
import Header from "../sidebar/Header";
import LeftSideBar from "../sidebar/LeftSideBar";
import FeildInputData from "./FeildInputData";
import { Disclosure } from "@headlessui/react";
import { BiChevronUp } from "react-icons/bi";
import {
  apiGetattributeapi,
  DeleteAbouteme,
  getAttributeById,
  getFilterData,
  GetperformerCategory,
  UpdateFilterDataapi,
} from "../apiconfig/authService";
import { useEffect } from "react";
import { toast } from "react-toastify";
const CreateFilterPerformer = () => {
  const data = [
    { id: 1, name: "Item One" },
    { id: 2, name: "Item Two" },
  ];
  const [feildData, setfeildData] = useState(data);
  const [items, setItems] = useState();
  const [id, setId] = useState("");

  const [performerDeleteId, setPerformerDeleteId] = useState("");

  //   Modal
  const [modal, setModal] = useState(false);
  const [close, setClose] = useState(false);
  const openModal = () => {
    setModal(true);
    setClose(false);
  };

  const closeModal = () => {
    setClose(true);
    setModal(false);
  };
  // Modal
  //   EditModal
  const [editmodal, seteditModal] = useState(false);
  const [editclose, seteditClose] = useState(false);
  const openeditModal = () => {
    seteditModal(true);
    seteditClose(false);
  };

  const editcloseModal = () => {
    seteditClose(true);
    seteditModal(false);
  };
  //Edit Modal close

  //Get Performer Category
  const [percate, setPercat] = useState([]);
  const getperformerCategory = async () => {
    try {
      const response = await GetperformerCategory();
      setPercat(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getperformerCategory();
  }, []);
  //Get Performer Category

  //Get Attribute List Api
  const [attribute, setAttribute] = useState([]);
  const GetAttribute = async () => {
    try {
      const response = await getAttributeById(items);
      if (response.data.statusCode == 200) {
        setAttribute(response.data.data);
      } else {
        setAttribute([]);
      }
      if (response.status == 200) {
        toast(response.message);
      } else {
        toast(response.message);
      }
    } catch (error) {}
  };

  useEffect(() => {
    GetAttribute();
  }, []);

  useEffect(() => {
    //  GetAttribute()
    const data = percate.filter((item) => items == item._id);
    setAttribute(data[0]?.abouteMeIDs);
  }, [items]);
  //Get Attribute List Api
  console.log(items);

  //Get Attribute List Api
  const [getFilter, setGetfilter] = useState([]);
  const [trigger, setTrigger] = useState(true);

  const GetFilterdata = async () => {
    try {
      const response = await getFilterData();
      setGetfilter(response.data);

      // setTrigger(false)
    } catch (error) {}
  };

  useEffect(() => {
    GetFilterdata();
  }, []);
  //Get Attribute List Api

  //Update Directive Category
  const [editfilter, setEditfilter] = useState();

  console.log(editfilter);
  const UpdateFilterData = async () => {
    if(editfilter.name===""){
      toast("Invalid name.");
      return;
    }

    const data = {
      id: editfilter._id,
      aboutMeId: editfilter.aboutMeId,
      name: editfilter.name,
    };

    try {
      const response = await UpdateFilterDataapi(data);
      if (response.status == 200) {
        toast(response.message);
      } else {
        toast(response.message);
      }
      GetFilterdata();
      GetFilterdata();
    } catch (error) {}
    editcloseModal();
  };
  //Update Performer Category

  //Delete Aboute  Me By Id
  const handleDeletePerformerAbouteme = async () => {
    // console.log("performerDeleteId:", performerDeleteId);
    try {
      const response = await DeleteAbouteme({
        id: performerDeleteId,
      });
      if (response.status == 200) {
        toast(response.message);
      } else {
        toast(response.message);
      }
      GetFilterdata();
    } catch (error) {}
  };
  //Delete Aboute  Me By Id

  const tableRows = feildData.map((info) => {
    return (
      <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
        <td className="py-3 px-4 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
          {info.id}
        </td>
        <td className="px-4">{info.name}</td>
        <td class="py-3 text-xs">
          <div className="flex gap-5">
            <BiEdit
              onClick={openeditModal}
              className="bg-blue-600 cursor-pointer text-white p-1"
              size={32}
            />
            <MdDelete
              onClick={openModal}
              className="bg-red-600 text-white p-2"
              size={32}
            />
          </div>
        </td>
      </tr>
    );
  });

  const addRows = (data) => {
    const totalStudentsData = feildData.length;
    data.id = totalStudentsData + 1;
    const updatedfeildData = [...feildData];
    updatedfeildData.push(data);
    setfeildData(updatedfeildData);
  };

  return (
    <div>
      <Header />
      <div className="col12SwmDashBord bg-white mb-20">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>
        <div className="col8SemNvr mt-[70px] ml-[5%] ">
          <div className="grid grid-cols-11">
            <div className="col-span-9 py-4">
              <div className="text-black flex items-center text-sm tracking-wider font-bold">
                Others{" "}
                <span className="space-x-1 flex items-center text-secondary">
                  <AiOutlineRight />
                  Performer
                </span>
                <span className="space-x-1 flex items-center text-secondary">
                  <AiOutlineRight />
                  Add Filters
                </span>
              </div>
            </div>
          </div>
          <hr className="h-[2px] shadow-sm w-full bg-[#61005D] mb-4 " />
          <div className="pb-2 px-4 flex justify-start text-lg font-bold">
            Create Filter
          </div>
          <hr />
          <div className="flex items-center">
            <div className="col-span-12 md:col-span-6 mb-4 px-4">
              <div className="w-72">
                <select
                  value={items}
                  onChange={(e) => setItems(e.target.value)}
                  className="bg-white mt-2 drop-md w-72 text-[#898989] border-[#89898940] border-[1px] shadow-md py-2 px-8 rounded-md"
                >
                  <option class="dropdown-item" value={null}>
                    Choose Filter
                  </option>
                  {percate &&
                    percate.map((item, ind) => {
                      return (
                        <option class="dropdown-item" value={item._id}>
                          {item.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>

            <div className="col-span-12 md:col-span-6 mb-4 px-4">
              <div className="w-72">
                <select
                  onChange={(e) => setId(e.target.value)}
                  className="bg-white mt-2 drop-md w-72 text-[#898989] border-[#89898940] border-[1px] shadow-md py-2 px-8 rounded-md"
                >
                  <option class="dropdown-item" value={null}>
                    Choose Attribute
                  </option>
                  {attribute &&
                    attribute.map((item, ind) => {
                      return (
                        <option class="dropdown-item" value={item._id}>
                          {item.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
          </div>
          {/* Start Complete Form */}
          <div className="mb-60">
            <div>
              <FeildInputData
                filterData={() => GetFilterdata}
                func={addRows}
                aboutId={id}
              />
              {getFilter &&
                getFilter.map((item, indx) => {
                  return (
                    <div className="">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex w-full justify-between rounded-lg bg-black/80 my-4 px-4 py-2 text-left text-sm font-medium text-white hover:bg-primary focus:outline-none  ">
                              <span className="">{item.name}</span>
                              <BiChevronUp
                                className={`${
                                  open ? "rotate-180 transform" : ""
                                } h-5 w-5 text-white`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                              <div class="  shadow-md sm:rounded-lg">
                                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
                                  <thead class="text-xs text-gray-700 uppercase bg-orange-100	 dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                      <th scope="col" class="py-4 px-4">
                                        S No.
                                      </th>
                                      <th scope="col" class="py-4 px-4 ">
                                        Filter Name
                                      </th>

                                      <th scope="col" class="py-4 px-4">
                                        Action
                                      </th>
                                    </tr>
                                  </thead>
                                  {item.AttributeIds.map((i, b) => (
                                    <tbody className="TableService">
                                      <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                                        <th
                                          scope="row"
                                          class="py-3 px-4 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white"
                                        >
                                          {b + 1}
                                        </th>
                                        <th
                                          scope="row"
                                          class="py-3 px-4 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white"
                                        >
                                          {i.name}
                                        </th>
                                        <td class="py-3 text-xs">
                                          <div className="flex gap-5">
                                            <BiEdit
                                              onClick={() => {
                                                openeditModal();
                                                setEditfilter(i);
                                              }}
                                              className="bg-blue-600 cursor-pointer text-white p-1"
                                              size={32}
                                            />
                                            <MdDelete
                                              // onClick={()=>handleDeletePerformerAbouteme(i._id)}
                                              onClick={(e) => {
                                                setPerformerDeleteId(i._id);
                                                openModal();
                                              }}
                                              className="bg-red-600 cursor-pointer text-white p-2"
                                              size={32}
                                            />
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  ))}
                                </table>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        {/* Modal section */}
        <div
          className={`w-screen h-screen fixed top-0 left-0 bg-black bg-opacity-20 ${
            modal ? "block duration-500" : "hidden duration-500"
          } ${close ? "hidden" : ""}`}
        >
          <div
            className={`text-sm absolute top-[50%] left-[50%]  w-[38%] translate-x-[-50%] h-auto  translate-y-[-50%] bg-[#fff] rounded-md shadow-lg pb-6 ${
              modal ? "duration-500" : "duration-500"
            }`}
          >
            <div className="bg-primary w-full flex items-center justify-between px-6 py-4 rounded-tl-md rounded-tr-md text-white">
              <div className="text-sm font-bold">Delete</div>
              <AiOutlineClose
                size={20}
                onClick={closeModal}
                className="cursor-pointer font-bold"
              />
            </div>

            <div className="text-center py-8">
              <h2 className="font-semibold text-lg">
                Are you sure you want to delete this item?
              </h2>
            </div>

            <div className="grid grid-cols-12 gap-5 px-6 py-8">
              <div className="col-span-6">
                <div className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white">
                  <MdOutlineDoneOutline className="text-2xl" />
                  <div
                    class="uppercase"
                    onClick={(e) => {
                      handleDeletePerformerAbouteme();
                      closeModal();
                    }}
                  >
                    <span>Confirm</span>
                  </div>
                </div>
              </div>

              <div className="col-span-6">
                <div
                  onClick={closeModal}
                  className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white"
                >
                  <GiCancel className="text-2xl" />
                  <div class="uppercase">
                    <span>Cancel</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal section */}
        {/* edit modal start */}
        <div
          className={`w-screen h-screen fixed top-0 left-0 bg-black bg-opacity-20 ${
            editmodal ? "block duration-500" : "hidden duration-500"
          } ${editclose ? "hidden" : ""}`}
        >
          <div
            className={`text-sm absolute top-[50%] left-[50%]  w-[38%] translate-x-[-50%] h-auto  translate-y-[-50%] bg-[#fff] rounded-md shadow-lg pb-6 ${
              editmodal ? "duration-500" : "duration-500"
            }`}
          >
            <div className="bg-primary w-full flex items-center justify-between px-6 py-4 rounded-tl-md rounded-tr-md text-white">
              <div className="text-sm font-bold">Edit Feild</div>
              <AiOutlineClose
                size={20}
                onClick={editcloseModal}
                className="cursor-pointer font-bold"
              />
            </div>

            <div className="text-center p-8">
              <input
                type="text"
                placeholder="Enter"
                value={editfilter?.name}
                onChange={(e) =>
                  setEditfilter({
                    ...editfilter,
                    name: e.target.value,
                  })
                }
                className="border-[1px] placeholder:text-xs  py-2.5 my-2 px-2 rounded-md  text-xs border-gray-300 w-full"
              />
            </div>

            <div className="grid grid-cols-12 gap-5 px-6 py-8">
              <div className="col-span-6">
                <div
                  onClick={(e) => {
                    UpdateFilterData();
                  }}
                  className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white"
                >
                  <MdOutlineDoneOutline className="text-2xl" />
                  <div class="uppercase">
                    <span>Confirm</span>
                  </div>
                </div>
              </div>

              <div className="col-span-6">
                <div
                  onClick={editcloseModal}
                  className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white"
                >
                  <GiCancel className="text-2xl" />
                  <div class="uppercase">
                    <span>Cancel</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* edit modal end */}
      </div>
    </div>
  );
};

export default CreateFilterPerformer;
