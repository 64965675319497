import React, { useState } from "react";
import LeftSideBar from "../sidebar/LeftSideBar";
import { NavLink } from "react-router-dom";
import Header from "../sidebar/Header";

function ProductionHouseView() {
  const [selectedFile, setSelectedFile] = React.useState();
  const [preview, setPreview] = React.useState();

  React.useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  const [state, setState] = useState({
    name: "Sanjay Jangid",
    email: "admin@gmail.com",
    phone: "976554578",
    state: "Haryana",
    city: "Gurgaon",
    
  });

  return (
    <>
      <Header />
      <div className="col12SwmDashBord">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>
        <div className="col8SemNvr mt-[70px] ml-[5%]">
          <div className="block w-full p-2 bg-white">
            {/* Brecrums */}
            <nav class="flex MngeBredcums" aria-label="Breadcrumb">
              <ol class="inline-flex items-center space-x-1 md:space-x-3">
                <li>
                  <div class="flex items-center">
                    <NavLink
                      to="/"
                      class="ml-1 text-xs font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white"
                    >
                      {" "}
                      Dynamic Directory{" "}
                    </NavLink>
                  </div>
                </li>
                <li aria-current="page">
                  <div class="flex items-center">
                    <svg
                      class="w-4 h-4 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span class="ml-1  font-medium text-gray-500 md:ml-2 dark:text-gray-400 text-xs">
                      {" "}
                      Production House{" "}
                    </span>
                  </div>
                </li>
                <li aria-current="page">
                  <div class="flex items-center">
                    <svg
                      class="w-4 h-4 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span class="ml-1  font-medium text-gray-500 md:ml-2 dark:text-gray-400 text-xs">
                      {" "}
                      Production House Detail{" "}
                    </span>
                  </div>
                </li>
              </ol>
            </nav>

            <div className="block mt-2 mb-2 p-0">
              <h4 className="text-sm font-bold"> Production House Detail</h4>
            </div>

            {/* End Brecrums */}

            <div className="block w-full  mt-2 bg-white ">
              <div className="p-4   block">
                <div class="flex flex-wrap py-3 border-[1px] mb-0 w-full ">
                  <div class="w-full md:w-1/2 px-3 my-3 justify-between  flex items-center">
                    <label
                      class="block w-full md:w-1/3 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                    Name
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        value={state.name}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full md:w-1/2 px-3 my-3 justify-between flex items-center">
                    <label
                      class="block w-full md:w-1/3 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Email
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        value={state.email}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full md:w-1/2 px-3 my-3 justify-between flex items-center">
                    <label
                      class="block w-full md:w-1/3 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Phone
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        value={state.phone}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full md:w-1/2 px-3 my-3 justify-between flex items-center">
                    <label
                      class="block w-full md:w-1/3 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      State
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        value={state.state}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="w-full md:w-1/2 px-3 my-3 justify-between flex items-center">
                    <label
                      class="block w-full md:w-1/3 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      City
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        value={state.city}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductionHouseView;
