import http from "./http";
import API_PATH from "./apiPath";

//Admin Login
export async function AdminLoginapi(data) {
  return http.apiurl.post(API_PATH.apiAdminLogin, data);
}

export async function AdminChangePasswordapi(data) {
  return http.apiurl.post(API_PATH.apiAdminChangePassword, data);
}

export async function PostProductionType(data) {
  //post Production
  return http.apiurl.post(API_PATH.apiAddProductiontype, data);
}

export async function PostEthencityType(data) {
  //post ethenecity
  return http.apiurl.post(API_PATH.apiAddEthnicity, data);
}

//Post Performer Category
export async function postPerformerCategory(data) {
  return http.apiurl.post(API_PATH.apiAddPerformercat, data);
}

//Get Performer Category
export async function GetperformerCategory() {
  return http.apiurl.get(API_PATH.apiGetperformerCat);
}

export async function GetPerformerCategoryPage(pageSize, pageNo) {
  return http.apiurl.get(
    API_PATH.apiGetperformerCat + "?pageSize=" + pageSize + "&pageNo=" + pageNo
  );
}

//Delete Performer Category
export async function DeleteperformerCategory(data) {
  return http.apiurl.delete(API_PATH.apiDeleteperformer, {
    data: {
      id: data.id,
    },
  });
}

//Update Performer Category
export async function UpdateperformerCategory(data) {
  return http.apiurl.put(API_PATH.apiUpdateperformerCategory, data);
}

export async function getAllEmployers(
  isDeleted,
  pageSize,
  pageNo,
  searchId,
  date
) {
  return http.apiurl.get(
    API_PATH.apiGetAllEmployers +
      "?isDeleted=" +
      isDeleted +
      "&pageSize=" +
      pageSize +
      "&pageNo=" +
      pageNo +
      "&searchId=" +
      searchId +
      "&date=" +
      date
  );
}

export async function getEmployersPage(
  pageSize,
  pageNo,
  searchId,
  date,
  isDeleted
) {
  // if (isDeleted) {
  return http.apiurl.get(
    API_PATH.apiGetAllEmployers +
      "?pageSize=" +
      pageSize +
      "&pageNo=" +
      pageNo +
      "&searchId=" +
      searchId +
      "&date=" +
      date +
      "&isDeleted=" +
      isDeleted
  );
  // } else {
  //   return http.apiurl.get(API_PATH.apiGetAllEmployers + "?pageSize=" + pageSize + "&pageNo=" + pageNo + "&searchId=" + searchId + "&date=" + date);
  // }
}
export async function getAllPerformers(
  isDeleted,
  pageSize,
  pageNo,
  searchId,
  date
) {
  if (isDeleted) {
    return http.apiurl.get(
      API_PATH.apiGetAllPerformers +
        "?isDeleted=" +
        isDeleted +
        "&pageSize=" +
        pageSize +
        "&pageNo=" +
        pageNo +
        "&searchId=" +
        searchId +
        "&date=" +
        date
    );
  } else {
    return http.apiurl.get(API_PATH.apiGetAllPerformers);
  }
}

export async function getPerformersPage(
  pageSize,
  pageNo,
  searchId,
  createdAt,
  isDeleted
) {
  // if (isDeleted) {
  return http.apiurl.get(
    API_PATH.apiGetPerformerPageAdmin +
      "?pageSize=" +
      pageSize +
      "&pageNo=" +
      pageNo +
      "&searchId=" +
      searchId +
      "&createdAt=" +
      createdAt +
      "&isDeleted=" +
      isDeleted
  );
  // } else {
  //   return http.apiurl.get(API_PATH.apiGetPerformerPageAdmin + "?pageSize=" + pageSize + "&pageNo=" + pageNo + "&createdAt=" + createdAt + "&searchId=" + searchId);
  // }
}

export async function getEmployerById(params) {
  return http.apiurl.get(API_PATH.apiGetEmployerById, params);
}
export async function getPerformerById(performerId) {
  return http.apiurl.get(API_PATH.apiGetPerformerById + performerId);
}

export async function getJobsList(pageSize, pageNo, searchId, date, status) {
  return http.apiurl.get(
    API_PATH.apiGetAllJobs +
      "?pageSize=" +
      pageSize +
      "&pageNo=" +
      pageNo +
      "&searchId=" +
      searchId +
      "&createdAt=" +
      date +
      "&status=" +
      status
  );
}

export async function deleteJob(jobId) {
  return http.apiurl.delete(API_PATH.deleteJob + "?id=" + jobId);
}

//Post Directive Category
export async function postDirectiveCategory(data) {
  return http.apiurl.post(API_PATH.apiPostDirectiveCategory, data);
}

//Get Directive Category
export async function GetperDirectiveCategory(pageSize, pageNo) {
  return http.apiurl.get(
    API_PATH.apiGetDirectoryCategory +
      "?pageSize=" +
      pageSize +
      "&pageNo=" +
      pageNo
  );
}

//Delete Performer Category
export async function DeleteDirectoryCategory(data) {
  return http.apiurl.delete(API_PATH.apiDeleteDirectiveCategory, {
    params: {
      id: data,
    },
  });
}

//update Directive Category
export async function UpdateDirectiveCategory(data) {
  return http.apiurl.put(API_PATH.apiUpdateDirectiveCategory, data);
}

//Get Attribute me master
export async function getAttributeaboutme() {
  return http.apiurl.get(API_PATH.apiGetAttributeaboutme);
}

//Delete Performer Category
export async function DeleteAttributeaboutme(data) {
  console.log();
  return http.apiurl.delete(API_PATH.apiDeleteAttributeabouteme, {
    data: {
      id: data.id,
    },
  });
}

//get job by id
export async function getJobByItsId(jobId) {
  return http.apiurl.get(API_PATH.apiGetJobById + jobId);
}

//post create attribute id
export async function PostAttributeAboutMemaster(data) {
  return http.apiurl.post(API_PATH.apiAddAttributeAboutMemaster, data);
}

//get job applications
export async function getJobApplications() {
  return http.apiurl.get(API_PATH.apiGetJobApplications);
}

//post filter attribute
export async function postfilterattribute(data) {
  return http.apiurl.post(API_PATH.apiAddFilter, data);
}

export async function updateJob(data) {
  return http.apiurl.put(API_PATH.apiUpdateJobStatus, data);
}

//get job application by id
export async function getjobApplicationThroughId(applicationId) {
  return http.apiurl.get(API_PATH.apiGetJobApplicationById + applicationId);
}

//add employer category
export async function addEmployerCategory(data) {
  return http.apiurl.post(API_PATH.apiAddEmployerCategory, data);
}

//get employer category
export async function getEmployerCategory() {
  return http.apiurl.get(API_PATH.apiGetEmployerCategory);
}

export async function getEmployerCategoryPage(pageSize, pageNo) {
  return http.apiurl.get(
    API_PATH.apiGetEmployerCategory +
      "?pageSize=" +
      pageSize +
      "&pageNo=" +
      pageNo
  );
}

//get Production Type
export async function getProductionType() {
  return http.apiurl.get(API_PATH.apiGetProductionType);
}

//Update Production Type
export async function UpdateProductionType(data) {
  return http.apiurl.put(API_PATH.apiUpdateProductionType, data);
}

//Delete Production Type
export async function DeleteProductionType(data) {
  return http.apiurl.delete(API_PATH.apiDeleteProductionType, {
    data: {
      id: data.id,
    },
  });
}

//Get Ethenecity api
export async function apiGetEthenecity() {
  return http.apiurl.get(API_PATH.apigetEthenecity);
}

//Update Ethencity Type
export async function UpdateEthencityType(data) {
  return http.apiurl.put(API_PATH.apiUpdateEthencity, data);
}

//Delete Ethencity Type
export async function DeleteEthencityType(data) {
  return http.apiurl.delete(API_PATH.apiDeleteEthencity, {
    data: {
      id: data.id,
    },
  });
}

//Get Attribute api
export async function apiGetattributeapi() {
  return http.apiurl.get(API_PATH.apiGetAttributeAbouteMemaster);
}

//get for dropDown in add directory
export async function getDirectoryTypes() {
  return http.apiurl.get(API_PATH.apiAddDirectoryDropDown);
}
//get for directory data
export async function getDirectoryData(pageSize, pageNo) {
  return http.apiurl.get(
    API_PATH.apiGetDirectoryData + "?pageSize=" + pageSize + "&pageNo=" + pageNo
  );
}
//get for directory data by id
export async function getDirectoryDataById(directoryId) {
  return http.apiurl.get(API_PATH.apiDirectoryDataById + directoryId);
}

//get filter data
export async function getFilterData() {
  return http.apiurl.get(API_PATH.apiGetfilter);
}

//get  Attribute by ID
export async function getAttributeById(id) {
  return http.apiurl.get(API_PATH.apiGetattributebyID + id);
}
//employer account deactivation
export async function setEmployerActivationStatus(data) {
  return http.apiurl.delete(API_PATH.apiEmployerActivation, {
    data: {
      id: data,
    },
  });
}
//performer account deactivation
export async function setPerformerActivationStatus(data) {
  return http.apiurl.delete(API_PATH.apiPerformerActivation, {
    data: {
      id: data,
    },
  });
}

//add directory
export async function addNewDirectory(data) {
  return http.apiurl.post(API_PATH.apiAddDirectory, data);
}

//Delete Attribute Aboute Me master
export async function DeletePerformerAbouteme(data) {
  return http.apiurl.delete(API_PATH.apiDeletePerformerAbouteMe, {
    data: {
      id: data.id,
    },
  });
}

//Delete  Aboute Me master
export async function DeleteAbouteme(data) {
  return http.apiurl.delete(API_PATH.apiDeleteAbouteMeFilter, {
    data: {
      id: data.id,
    },
  });
}

//Update FilterData
export async function UpdateFilterDataapi(data) {
  return http.apiurl.put(API_PATH.apiUpdateFilterData, data);
}

//Update Attribute master
export async function UpdateAttributeDataapi(data) {
  return http.apiurl.put(API_PATH.apiUpdateAttributeMaster, data);
}

//edit directory
export async function editDirectoryDataById(data) {
  return http.apiurl.put(API_PATH.apiEditDirectoryById, data);
}

//cms add blog
export async function addnewBlogData(data) {
  return http.apiurl.post(API_PATH.apiAddBlogData, data);
}

// get employer category by id
export async function getEmpCatById(params) {
  return http.apiurl.get("/employerCategory/getEmployerCategoryById", params);
}

//restore employer
export async function restoreEmployerById(id) {
  return http.apiurl.patch(`/employer/restoreEmployer?id=${id}`);
}

//delete employer
export async function deleteEmployerById(params) {
  return http.apiurl.delete("/employer/deleteEmployer", params);
}

export async function DeleteApplicantData(id) {
  return http.apiurl.delete(`/jobRole/deleteJobRole?id=${id}`);
}
//delete performer
export async function deletePerformerById(params) {
  return http.apiurl.delete("/performer/deletePerformer", params);
}

//restore deleted performer
export async function restoreDeletedPerformer(id) {
  return http.apiurl.patch(`/performer/restorePerformer?id=${id}`);
}

//update employer
export async function updateEmployer(data) {
  return http.apiurl.put("/employer/update", data);
}

//delete employer category
export async function deleteEmpCategory(data) {
  return http.apiurl.delete("employerCategory/deleteEmployerCategory", data);
}

//update performer status
export async function updatePerformerStatus(data) {
  return http.apiurl.put("/performer/updateperformerStatus", data);
}

//update employer status
export async function updateEmployerStatus(data) {
  return http.apiurl.put("/employer/updateEmployerStatus", data);
}

//get job applicants
export async function getJobApplicants(data) {
  return http.apiurl.get("jobApplication/getJobApplication", data);
}

//get all job roles
export async function getJobRoles(pageSize, pageNo, status) {
  return http.apiurl.get(
    `/jobRole/getAllJobRole?pageSize=${pageSize}&pageNo=${pageNo}&status=${status}`
  );
}

//update emp category
export async function updateEmployerCategory(data) {
  return http.apiurl.put("/employerCategory/upadteEmployerCategory", data);
}

export async function getAllBlogData() {
  return http.apiurl.get(API_PATH.apiGetAllBlogData);
}

export async function GetBlogDataPage(pageSize, pageNo) {
  return http.apiurl.get(
    API_PATH.apiGetAllBlogData + "?pageSize=" + pageSize + "&pageNo=" + pageNo
  );
}

export async function getAllBlogCategory() {
  return http.apiurl.get(API_PATH.apiGetAllBlogCategory);
}

export async function BlogPostData(data) {
  return http.apiurl.post(API_PATH.apiPostBlogData, data);
}

export async function BlogPutData(data) {
  return http.apiurl.put(API_PATH.apiPutBlogData, data);
}

export async function getBlogDetailsById(blogid) {
  return http.apiurl.get(API_PATH.apigetBlogDetailsById + blogid);
}

export async function DeleteBlogData(data) {
  return http.apiurl.delete(API_PATH.apiBlogDelete, {
    data: {
      id: data.id,
    },
  });
}

export async function DeleteLifeStyleData(data) {
  return http.apiurl.delete(API_PATH.apiLifeStyleDelete, {
    data: {
      id: data.id,
    },
  });
}

export async function getAllPartner(pageSize, pageNo) {
  return http.apiurl.get(
    API_PATH.apiGetAllPartner + "?pageSize=" + pageSize + "&pageNo=" + pageNo
  );
}

export async function AddPartnerData(data) {
  return http.apiurl.post(API_PATH.apiPartnerAdd, data);
}

export async function getPartnerById(partnerid) {
  return http.apiurl.get(API_PATH.apigetPartnerDetailsById + partnerid);
}

export async function PutPartnerData(data) {
  return http.apiurl.put(API_PATH.apiPartnerPut, data);
}

export async function DeletePartnerData(data) {
  return http.apiurl.delete(API_PATH.apiPartnerDelete, {
    data: {
      id: data.id,
    },
  });
}

export async function getAllLifeStyleCategory() {
  return http.apiurl.get(API_PATH.apiGetAllLifeStyleListingCategory);
}

export async function LifeStylePostData(data) {
  return http.apiurl.post(API_PATH.apiLifeStyleBlogData, data);
}

export async function LifeStylePutData(data) {
  return http.apiurl.put(API_PATH.apiLifeStyleputData, data);
}

export async function getAllLifeStyleData() {
  return http.apiurl.get(API_PATH.apiGetLifeStyle);
}

export async function getLifeStyleDetailsById(lifestyleid) {
  return http.apiurl.get(API_PATH.apigetLifeStyleDetailsById + lifestyleid);
}

export async function addJobDataShow(data) {
  return http.apiurl.post(API_PATH.apiaddJobData, data);
}

export async function getAllJobData(pageSize, pageNo) {
  return http.apiurl.get(
    API_PATH.apiAllJobData + "?pageSize=" + pageSize + "&pageNo=" + pageNo
  );
}

export async function DeleteJobList(id) {
  return http.apiurl.put(API_PATH.apiGetDelete + id);
}

export async function getAllHeaderMenu() {
  return http.apiurl.get(API_PATH.apigetAllHeaderMenu);
}

export async function DeleteHeader(id) {
  return http.apiurl.put(API_PATH.apiHeaderDelete + id);
}

export async function getAllHeaderMenuFilter(typevalue) {
  return http.apiurl.get(API_PATH.apigetAllHeaderMenuFilter + typevalue);
}

export async function getAllBannerListing(pageSize, pageNo) {
  return http.apiurl.get(
    API_PATH.apiBannerList + "?pageSize=" + pageSize + "&pageNo=" + pageNo
  );
}

export async function getAllBannerById(bnnerid) {
  return http.apiurl.get(API_PATH.apiBannerById + bnnerid);
}

export async function BannerEditDta(data) {
  return http.apiurl.put(API_PATH.apiBannerEdit, data);
}

export async function BannerPostData(data) {
  return http.apiurl.post(API_PATH.apiBannerPost, data);
}

export async function DeleteBannerData(id) {
  return http.apiurl.delete(API_PATH.apiBneerDelete + id);
}

export async function getAllVideoLiting(pageSize, pageNo) {
  return http.apiurl.get(
    API_PATH.apiGetAllVideos + "?pageSize=" + pageSize + "&pageNo=" + pageNo
  );
}

export async function DeleteVideoData(id) {
  return http.apiurl.delete(API_PATH.apiVideoDeleteDta + id);
}

export async function HomePagePostData(data) {
  return http.apiurl.post(API_PATH.apiHomePageVideosPost, data);
}

export async function getAllTopVideosById(videoid) {
  return http.apiurl.get(API_PATH.apiTopVideoById + videoid);
}

export async function VideoEditDta(data) {
  return http.apiurl.put(API_PATH.apiTopVideosEdit, data);
}

export async function getAllTopPerformerListing(pageSize, pageNo) {
  return http.apiurl.get(
    API_PATH.apigetPerformerList + "?pageSize=" + pageSize + "&pageNo=" + pageNo
  );
}
export async function getAllTopPerformerListingHome() {
  return http.apiurl.get(API_PATH.apigetPerformerList);
}

export async function DeletePerfomerData(id) {
  return http.apiurl.delete(API_PATH.apiTopPerfomerDeleteDta + id);
}

export async function AddTopPerFormerDta(data) {
  return http.apiurl.post(API_PATH.apiTopPerFormerData, data);
}

export async function getAllTopPerformerById(topperformerId) {
  return http.apiurl.get(API_PATH.apiTopPerFormeById + topperformerId);
}

export async function TopPerformerEditData(data) {
  return http.apiurl.put(API_PATH.apiTopPerFormerEditData, data);
}

export async function getJobById(jobid) {
  return http.apiurl.get(API_PATH.apiJobById + jobid);
}

export async function EditJobDataShow(data) {
  return http.apiurl.put(API_PATH.apiEditjobData, data);
}

export async function getAllApplications(pageSize, pageNo) {
  return http.apiurl.get(
    API_PATH.apiAllAplications + "?pageSize=" + pageSize + "&pageNo=" + pageNo
  );
}

export async function getAllLibrayListing(pageSize, pageNo) {
  return http.apiurl.get(
    API_PATH.apiLibrayListData + "?pageSize=" + pageSize + "&pageNo=" + pageNo
  );
}

export async function AddLibrayData(data) {
  return http.apiurl.post(API_PATH.apiLibrayData, data);
}

export async function DeleteLibrayData(id) {
  return http.apiurl.delete(API_PATH.apiLibrayDeleteData + id);
}

export async function getLibrayById(libraryid) {
  return http.apiurl.get(API_PATH.apiGetLibrayById + libraryid);
}

export async function PutLibrayDataApi(data) {
  return http.apiurl.put(API_PATH.apiPutLibryaData, data);
}

export async function getAllTestimonialData(pageSize, pageNo) {
  return http.apiurl.get(
    API_PATH.apiTestimonalDta + "?pageSize=" + pageSize + "&pageNo=" + pageNo
  );
}

export async function DeleteTestimonialData(id) {
  return http.apiurl.delete(API_PATH.apiDeleteTestimonalDta + id);
}

export async function addTestimonialDataShow(data) {
  return http.apiurl.post(API_PATH.apiTestimonialData, data);
}

export async function getTestimonialById(testimonial_id) {
  return http.apiurl.get(API_PATH.apiGetTestimonialById + testimonial_id);
}

export async function EditTestimonialDataShow(data) {
  return http.apiurl.put(API_PATH.apiPutTestimonialData, data);
}

export async function getAllContactusDataShow(pageSize, pageNo) {
  return http.apiurl.get(
    API_PATH.apiGetAllContactus + "?pageSize=" + pageSize + "&pageNo=" + pageNo
  );
}

export async function getAllPerformerAdmin() {
  return http.apiurl.post(API_PATH.apiGetAllPerformerAdmin);
}

export async function getAllEmployeeAdmin() {
  return http.apiurl.get(API_PATH.apiGetAllEmployeeAdmin);
}

export async function getAllJobAdmin() {
  return http.apiurl.get(API_PATH.apiGetAllJobAdmin);
}

export async function getSubscriptionList() {
  return http.apiurl.get(API_PATH.apiGetSubscriptionList);
}

export async function addSubcriptionsData(data) {
  return http.apiurl.post(API_PATH.apiAddSubcription, data);
}

// export async function DeleteSubcriptionData(id) {
//   return http.apiurl.delete(API_PATH.apiDeleteSubcription + id);
// }

export async function DeleteSubcriptionData(data) {
  return http.apiurl.delete(API_PATH.apiDeleteSubcription, {
    data: {
      id: data.id,
    },
  });
}

export async function getFeaureDataById(subScriptionId) {
  return http.apiurl.get(API_PATH.apigetSubscriptionList + subScriptionId);
}

export async function EditSubcriptionsData(data) {
  return http.apiurl.put(API_PATH.apisubScriptionPut, data);
}

export async function getSubscriptionOrderList(pageSize, pageNo) {
  return http.apiurl.get(
    API_PATH.apiGetSubscriptionOrderList +
      "?pageSize=" +
      pageSize +
      "&pageNo=" +
      pageNo
  );
}

export const UserLogout = async () => {
  try {
    localStorage.clear();
  } catch (err) {
    return alert("Logout Failed");
  }
};

export async function getAllCountry() {
  return http.apiurl.get(API_PATH.apiGetAllCountry);
}

export async function getAllState(data) {
  return http.apiurl.get(API_PATH.apiGetAllState + data);
}

export async function getAllCity(coutryValue, DataState) {
  return http.apiurl.get(
    `/city/getAllCity?country=${coutryValue}&state=${DataState}`
  );
}

export async function UpdateDirctyPage(data) {
  return http.apiurl.put(API_PATH.apiUpateDircPutApi, data);
}

export async function DeleteDirctory(id) {
  return http.apiurl.delete(API_PATH.apiDeleteDirectory + id);
}

export async function deletePerformerPermanently(id) {
  return http.apiurl.delete(API_PATH.apiDeletePerformerPermanently + id);
}

export async function deleteEmployerPermanently(id) {
  return http.apiurl.delete(API_PATH.apiDeleteEmployerPermanently + id);
}

export async function getAllMetaData() {
  return http.apiurl.get(API_PATH.apiMetaDataTag);
}

export async function addMetaDataPage(data) {
  return http.apiurl.post(API_PATH.apiMetaDataTag, data);
}

export async function deleteMetaDataPage(id) {
  return http.apiurl.delete(API_PATH.apiMetaDataTag + "?id=" + id);
}

export async function updateMetaDataPage(data) {
  return http.apiurl.put(API_PATH.apiMetaDataTag, data);
}

export async function getAllPagesData() {
  return http.apiurl.get(API_PATH.apiMetaDataTag + "/pages");
}
