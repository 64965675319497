import React, { useState, useEffect } from "react";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { HiEye } from "react-icons/hi";
import { MdDelete, MdOutlineDoneOutline } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { GiCancel } from "react-icons/gi";
import Header from "../sidebar/Header";
import LeftSideBar from "../sidebar/LeftSideBar";
import Select from "react-select";
import {
  DeleteAttributeaboutme,
  getAttributeaboutme,
  GetperformerCategory,
} from "../apiconfig/authService";

export default function AddAttribute() {
  //   Modal
  const [modal, setModal] = useState(false);
  const [close, setClose] = useState(false);

  const closeModal = () => {
    setClose(true);
    setModal(false);
  };
  // Modal

  //Get Attribute About me Api
  const [data, setData] = useState([]);
  const getAttribute = async () => {
    try {
      const response = await getAttributeaboutme();
      setData(response.data);
    } catch (error) { }
  };
  useEffect(() => {
    getAttribute();
  }, []);

  //Get Attribute About me Api

  //Delete About Me Master Attribite
  const handleDeleteAttribute = async (id) => {
    alert(id);
    try {
      const response = await DeleteAttributeaboutme({
        id: id,
      });
      getAttribute();
      console.log(response);
    } catch (error) { }
  };

  //Delete About Me Master Attribite

  //Get Performer Category
  const [percate, setPercat] = useState([{}]);
  const getperformerCategory = async () => {
    try {
      const response = await GetperformerCategory();
      setPercat(response.data);
    } catch (error) { }
  };

  useEffect(() => {
    getperformerCategory();
  }, []);

  //for Performer Category Select Listing
  const performercategory = [{}];
  percate.map((item) => {
    Object.assign(
      performercategory,
      performercategory.push({ value: item._id, label: item.name })
    );
  });
  //Get Performer Category

  return (
    <>
      <Header />
      <div className="col12SwmDashBord bg-white ">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>
        <div className="col8SemNvr mt-[70px] ml-[5%]">
          {/* Bredcrums */}
          <div className="grid grid-cols-12">
            <div className="col-span-10 ">
              <div className="HdingSwnvr my-3 px-5">
                <h5> Add Attribute </h5>
              </div>
            </div>
          </div>
          <hr className="w-full bg-gray-500 h-[2px]" />
          {/* Bredcrums End */}

          <div className="flex col12WdthSwhnMn mt-2">
            <div className="flex justify-between items-end mb-5">
              <div className=" pt-2">
                <label className="text-sm font-semibold text-[#898989]">
                  Select Category Type
                </label>
                <br />

                <div class="w-52">
                  <Select options={performercategory} className="text-black" />
                </div>
              </div>

              <div className="hover:shadow">
                <NavLink to="/attribute/create-attribute">
                  <div className="flex cursor-pointer hover:shadow items-center border-[1px] px-6 space-x-1 py-2 rounded-md shadow-md">
                    <AiOutlinePlus />
                    <span>Create Item</span>
                  </div>
                </NavLink>
              </div>
            </div>
            <hr />

            {/* Table  */}

            <div class=" relative  shadow-md sm:rounded-lg  mt-5">
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
                <thead class="text-xs text-gray-700 uppercase bg-orange-100	 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" class="py-4 px-4">
                      id
                    </th>
                    <th scope="col" class="py-4 px-4">
                      name
                    </th>
                    <th scope="col" class="py-4 px-4">
                      type
                    </th>
                    <th scope="col" class="py-4 px-4">
                      Attribute Type
                    </th>
                    <th scope="col" class="py-4 px-4">
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody className="TableService">
                  {data &&
                    data.map((item, index) => (
                      <tr
                        class="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                        key={index}
                      >
                        <th
                          scope="row"
                          class="py-3 px-4 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {index + 1}
                        </th>
                        <th
                          scope="row"
                          class="py-3 px-4 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {item.name}
                        </th>
                        <th
                          scope="row"
                          class="py-3 px-4 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {item.attributeType}
                        </th>
                        <th
                          scope="row"
                          class="py-3 px-4 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {item.attributeTypeValue}
                        </th>
                        <td class="py-3 text-xs">
                          <div className="flex gap-5">
                            <NavLink to="/view-attribute">
                              <HiEye
                                className="bg-[#0a65cc] text-white p-2"
                                size={32}
                              />
                            </NavLink>
                            <MdDelete
                              // onClick={openModal}
                              onClick={() => handleDeleteAttribute(item._id)}
                              className="bg-red-600 text-white p-2"
                              size={32}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {/* Table End */}

            <div className="SwvrMnvr flex">
              <ul>
                <li className="prev">
                  {" "}
                  <NavLink to={"#"}>
                    {" "}
                    <img alt="Swhn" src="/assets/images/leftarrow.png" />{" "}
                  </NavLink>{" "}
                </li>
                <li>
                  {" "}
                  <NavLink to={"1"}> 1 </NavLink>{" "}
                </li>
                <li>
                  {" "}
                  <NavLink to={"#"}> 2 </NavLink>{" "}
                </li>
                <li>
                  {" "}
                  <NavLink to={"#"}> 3 </NavLink>{" "}
                </li>
                <li>
                  {" "}
                  <NavLink to={"#"}> .. </NavLink>{" "}
                </li>
                <li>
                  {" "}
                  <NavLink to={"#"}> 7 </NavLink>{" "}
                </li>
                <li>
                  {" "}
                  <NavLink to={"#"}> 8 </NavLink>{" "}
                </li>
                <li>
                  {" "}
                  <NavLink to={"#"}> 9 </NavLink>{" "}
                </li>
                <li>
                  {" "}
                  <NavLink to={"#"}> 10 </NavLink>{" "}
                </li>
                <li className="next">
                  {" "}
                  <NavLink to={"#"}>
                    {" "}
                    <img alt="Swhn" src="/assets/images/rightarrow.png" />{" "}
                  </NavLink>{" "}
                </li>
              </ul>

              <div className="flex ml-2">
                <select
                  id="countries"
                  class="bg-gray-50 border text-xs border-gray-300 text-gray-900  rounded-[2px] focus:ring-blue-500 focus:border-blue-500 block w-full p-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option selected value="10">
                    10
                  </option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="100">200</option>
                  <option value="200">200</option>
                  <option value="300">300</option>
                  <option value="400">400</option>
                  <option value="500">500</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        {/* Modal section */}
        <div
          className={`w-screen h-screen fixed top-0 left-0 bg-black bg-opacity-20 ${modal ? "block duration-500" : "hidden duration-500"
            } ${close ? "hidden" : ""}`}
        >
          <div
            className={`text-sm absolute top-[50%] left-[50%]  w-[38%] translate-x-[-50%] h-auto  translate-y-[-50%] bg-[#fff] rounded-md shadow-lg pb-6 ${modal ? "duration-500" : "duration-500"
              }`}
          >
            <div className="bg-primary w-full flex items-center justify-between px-6 py-4 rounded-tl-md rounded-tr-md text-white">
              <div className="text-sm font-bold">Delete</div>
              <AiOutlineClose
                size={20}
                onClick={closeModal}
                className="cursor-pointer font-bold"
              />
            </div>

            <div className="text-center py-8">
              <h2 className="font-semibold text-lg">
                Are you sure you want to delete this item?
              </h2>
            </div>

            <div className="grid grid-cols-12 gap-5 px-6 py-8">
              <div className="col-span-6">
                <div className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white">
                  <MdOutlineDoneOutline className="text-2xl" />
                  <div class="uppercase">
                    <span>Confirm</span>
                  </div>
                </div>
              </div>

              <div className="col-span-6">
                <div
                  onClick={closeModal}
                  className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white"
                >
                  <GiCancel className="text-2xl" />
                  <div class="uppercase">
                    <span>Cancel</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal section */}
      </div>
    </>
  );
}
