import React, { useState, useEffect, Fragment } from "react";
import LeftSideBar from "../sidebar/LeftSideBar";
import Header from "../sidebar/Header";
import { AiOutlineClose, AiOutlineRight } from "react-icons/ai";
import { Input } from "@material-tailwind/react";
import { Disclosure } from "@headlessui/react";
import { BiChevronUp, BiEdit } from "react-icons/bi";
import {
  apiGetattributeapi,
  DeletePerformerAbouteme,
  GetperformerCategory,
  PostAttributeAboutMemaster,
  UpdateAttributeDataapi,
} from "../apiconfig/authService";
import { MdDelete, MdOutlineDoneOutline } from "react-icons/md";
import { GiCancel } from "react-icons/gi";
import { toast } from "react-toastify";

export default function Employer() {
  const [data, setData] = useState([]);
  const [perid, setPerid] = useState("");
  const [fieldname, setFieldName] = useState("");
  const [attributetype, setAtrributetype] = useState("text");
  const [attributevalue, setAttributevalue] = useState("single");
  const [morefilter, setMorefilter] = useState();
  const [dynamic, setDynamic] = useState();
  const [deletePerformerAboutemeId, setDeletePerformerAboutemeId] =
    useState("");

  //Post Attribute Api is Here
  const handlePostAttribute = async () => {
    if(fieldname==="")
      toast("Invalid field name.");

    const data = {
      performerCategoryId: perid,
      name: fieldname,
      attributeType: attributetype,
      isMoreSetting: morefilter,
      isDynamic: dynamic,
      attributeTypeValue: attributevalue,
    };

    try {
      const response = await PostAttributeAboutMemaster(data);
      if (response.code) {
        toast(response.message);
        setFieldName("");
      } else {
        toast(response.message);
      }
      GetAttribute();
    } catch (error) {}
  };

  const Getperformer = async () => {
    try {
      const response = await GetperformerCategory();
      console.log("Get Performer Response", response);
      setData(response.data);
      setPerid(response.data[0]._id);
    } catch (error) {}
  };

  useEffect(() => {
    Getperformer();
  }, []);
  //Get PerformerCategory

  //Get Attribute List Api
  const [attribute, setAttribute] = useState([]);
  const GetAttribute = async () => {
    try {
      const response = await apiGetattributeapi();
      console.log("Get Attribute Response", response);
      setAttribute(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    GetAttribute();
  }, []);
  //Get Attribute List Api

  //Delete PerformerAboute Me By Id
  const handleDeletePerformerAbouteme = async () => {
    try {
      const response = await DeletePerformerAbouteme({
        id: deletePerformerAboutemeId,
      });
      GetAttribute();
      if (response.status == true) {
        toast(response.message);
      } else {
        toast(response.message);
      }
    } catch (error) {}
  };
  //Delete PerformerAboute Me By Id

  //Update Addiing Attribute
  const [updateddata, setUpdateddata] = useState();

  const UpdateAttributeData = async () => {
    if(updateddata.name===""){
      toast("Invalid field name.");
      return;
    }

    const data = {
      id: updateddata._id,
      performerCategoryId: updateddata.performerCategoryId,
      name: updateddata.name,
      attributeType: updateddata.attributeType,
      isMoreSetting: updateddata.isMoreSetting,
      isDynamic: updateddata.isDynamic,
      attributeTypeValue: updateddata.attributeTypeValue,
    };
    try {
      const response = await UpdateAttributeDataapi(data);
      if (response.code) {
        toast(response.message);
        setFieldName("");
      } else {
        toast(response.message);
      }
      GetAttribute();
      editcloseModal();
    } catch (error) {}
    editmodal();
  };
  //Update Performer Category
  //Update Addiing Attribute

  //   Modal
  const [modal, setModal] = useState(false);
  const [close, setClose] = useState(false);
  const openModal = () => {
    setModal(true);
    setClose(false);
  };

  const closeModal = () => {
    setClose(true);
    setModal(false);
  };
  // Modal
  //   EditModal
  const [editmodal, seteditModal] = useState(false);
  const [editclose, seteditClose] = useState(false);
  const openeditModal = () => {
    seteditModal(true);
    seteditClose(false);
  };

  const editcloseModal = () => {
    seteditClose(true);
    seteditModal(false);
  };
  //Edit Modal close

  console.log("updated Data", updateddata);


  return (
    <>
      <Header />
      <div className="col12SwmDashBord bg-white ">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>
        <div className="col8SemNvr mt-[70px] ml-[5%]">
          {/* Bredcrums */}
          <div className="grid grid-cols-11">
            <div className="col-span-9 py-4">
              <div className="text-black flex items-center text-sm tracking-wider font-bold">
                Manage Category{" "}
                <span className="space-x-1 flex items-center text-secondary">
                  <AiOutlineRight />
                  Performer
                </span>
                <span className="space-x-1 flex items-center text-secondary">
                  <AiOutlineRight />
                  Add Attributes Filters
                </span>
              </div>
            </div>
          </div>
          <hr className="w-full bg-gray-500 h-[2px]" />

          {/* Bredcrums End */}

          <div className="flex space-x-8 items-center mb-5">
            <div className="w-[20%]">
              <label className="font-semibold pt-2">Create Attributes</label>

              <div class="mt-3 w-full">
                <div className="w-72">
                  <select
                    onChange={(e) => setPerid(e.target.value)}
                    className="bg-white mt-2 drop-md w-72 text-[#898989] border-[#89898940] border-[1px] shadow-md py-2 px-8 rounded-md"
                  >
                    {data &&
                      data.map((item, ind) => {
                        return (
                          <option
                            key={ind}
                            class="dropdown-item"
                            value={item?._id}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="flex col12WdthSwhnMn shadow">
            <div className="flex  gap-4 py-3">
              <div className="w-80 ">
                <Input
                  value={fieldname}
                  label="field name"
                  color="purple"
                  name="fieldName"
                  onChange={(e) => setFieldName(e.target.value)}
                />
              </div>

              <div className="w-80">
                <select
                  onChange={(e) => setAtrributetype(e.target.value)}
                  className="bg-white drop-md w-72 text-[#898989] border-[#89898940] border-[1px] shadow-md py-2 px-8 rounded-md"
                >
                  <option class="dropdown-item" value="text">
                    Text
                  </option>
                  <option class="dropdown-item" value="dropdown">
                    Dropdown
                  </option>
                </select>
              </div>
              <div className="w-80">
                <select
                  onChange={(e) => setAttributevalue(e.target.value)}
                  className="bg-white  drop-md w-72 text-[#898989] border-[#89898940] border-[1px] shadow-md py-2 px-8 rounded-md"
                >
                  <option class="dropdown-item" value="single">
                    Single
                  </option>
                  <option class="dropdown-item" value="multiple">
                    Multiple
                  </option>
                </select>
              </div>
            </div>
            <div className="flex items-center gap-4 py-2">
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  onChange={(e) => setMorefilter(e.target.checked)}
                />
                <span>More Filter</span>
              </div>
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  onChange={(e) => setDynamic(e.target.checked)}
                />
                <span>Is Dynamic</span>
              </div>
            </div>
            <button
              onClick={handlePostAttribute}
              className="text-white px-6 py-1 border-[2px] font-semibold border-primary bg-primary hover:text-white rounded-md "
            >
              Add
            </button>
          </div>

          <div className="space-y-4 py-8">
            <>
              {attribute &&
                attribute.map((item, ind) => {
                  return (
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <>
                            <Disclosure.Button className="flex w-full justify-between rounded-lg bg-black/80  px-4 py-2 text-left text-sm font-medium text-white hover:bg-primary focus:outline-none  ">
                              <span className="">{item.name}</span>
                              <BiChevronUp
                                className={`${
                                  open ? "rotate-180 transform" : ""
                                } h-5 w-5 text-white`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel
                              className={`px-4  pt-4 pb-2 text-sm text-gray-500`}
                            >
                              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
                                <thead class="text-xs text-gray-700 uppercase bg-orange-100	 dark:bg-gray-700 dark:text-gray-400">
                                  <tr>
                                    <th scope="col" class="py-4 px-4">
                                      S no.
                                    </th>
                                    <th scope="col" class="py-4 px-4">
                                      Name
                                    </th>
                                    <th scope="col" class="py-4 px-4">
                                      Type
                                    </th>
                                    <th scope="col" class="py-4 px-4">
                                      Attribute Type
                                    </th>

                                    <th scope="col" class="py-4 px-4">
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                {item.abouteMeIDs.map((i, ind) => (
                                  <tbody className="TableService">
                                    <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                                      <th
                                        scope="row"
                                        class="py-3 px-4 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white"
                                      >
                                        {ind + 1}
                                      </th>
                                      <th
                                        scope="row"
                                        class="py-3 px-4 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white"
                                      >
                                        {i.name}
                                      </th>
                                      <td class="py-3 text-xs px-8">
                                        {i.attributeType}
                                      </td>

                                      <td class="py-3 text-xs px-8">
                                        {i?.attributeTypeValue}
                                      </td>

                                      <td class="py-3 text-xs">
                                        <div className="flex gap-5">
                                          <BiEdit
                                            onClick={() => {
                                              openeditModal();
                                              setUpdateddata(i);
                                            }}
                                            className="bg-blue-600 cursor-pointer text-white p-1"
                                            size={32}
                                          />
                                          <MdDelete
                                            // onClick={()=>handleDeletePerformerAbouteme(i._id)}
                                            onClick={() => {
                                              openModal();
                                              setDeletePerformerAboutemeId(
                                                i._id
                                              );
                                            }}
                                            className="bg-red-600 cursor-pointer text-white p-2"
                                            size={32}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                ))}
                              </table>
                            </Disclosure.Panel>
                          </>
                        </>
                      )}
                    </Disclosure>
                  );
                })}
            </>
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between rounded-lg bg-black/80  px-4 py-2 text-left text-sm font-medium text-white hover:bg-primary focus:outline-none  ">
                    <span className="">Dancer</span>
                    <BiChevronUp
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-white`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
                      <thead class="text-xs text-gray-700 uppercase bg-orange-100	 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" class="py-4 px-4">
                            S no.
                          </th>
                          <th scope="col" class="py-4 px-4">
                            Name
                          </th>
                          <th scope="col" class="py-4 px-4">
                            Type
                          </th>
                          <th scope="col" class="py-4 px-4">
                            Attribute Type
                          </th>
                          <th scope="col" class="py-4 px-4">
                            Action
                          </th>
                        </tr>
                      </thead>

                      <tbody className="TableService">
                        <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                          <th
                            scope="row"
                            class="py-3 px-4 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            1
                          </th>
                          <th
                            scope="row"
                            class="py-3 px-4 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            SId
                          </th>
                          <td class="py-3 text-xs px-8">type Feild</td>

                          <td class="py-3 text-xs px-8">Singer</td>

                          <td class="py-3 text-xs">
                            <div className="flex gap-5">
                              <BiEdit
                                onClick={openeditModal}
                                className="bg-blue-600 cursor-pointer text-white p-1"
                                size={32}
                              />
                              <MdDelete
                                className="bg-red-600 cursor-pointer text-white p-2"
                                size={32}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        </div>
        {/* edit modal start */}
        <div
          className={`w-screen h-screen fixed top-0 left-0 bg-black bg-opacity-20 ${
            editmodal ? "block duration-500" : "hidden duration-500"
          } ${editclose ? "hidden" : ""}`}
        >
          <div
            className={`text-sm absolute top-[50%] left-[50%]  w-[50%] translate-x-[-50%] h-auto  translate-y-[-50%] bg-[#fff] rounded-md shadow-lg pb-6 ${
              editmodal ? "duration-500" : "duration-500"
            }`}
          >
            <div className="bg-primary w-full flex items-center justify-between px-6 py-4 rounded-tl-md rounded-tr-md text-white">
              <div className="text-sm font-bold">Edit Field</div>
              <AiOutlineClose
                size={20}
                onClick={editcloseModal}
                className="cursor-pointer font-bold"
              />
            </div>

            <div className="text-center space-y-4 p-8">
              <div className="flex items-center space-x-3">
                <div className="w-80 ">
                  <Input
                    label="field name"
                    color="purple"
                    name="fieldName"
                    value={updateddata?.name}
                    onChange={(e) =>
                      setUpdateddata({
                        ...updateddata,
                        name: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="w-80">
                  <select
                    onChange={(e) =>
                      setUpdateddata({
                        ...updateddata,
                        attributeType: e.target.value,
                      })
                    }
                    value={updateddata?.attributeType}
                    className="bg-white drop-md w-80 text-[#898989] border-[#89898940] border-[1px] shadow-md py-2 px-8 rounded-md"
                  >
                    <option class="dropdown-item" value="text">
                      Text
                    </option>
                    <option class="dropdown-item" value="dropdown">
                      Dropdown
                    </option>
                  </select>
                </div>
              </div>
              <div className="w-80">
                <select
                value={updateddata?.attributeTypeValue}
                  onChange={(e) =>
                    setUpdateddata({
                      ...updateddata,
                      attributeTypeValue: e.target.value,
                    })
                  }
                  className="bg-white  drop-md w-80 text-[#898989] border-[#89898940] border-[1px] shadow-md py-2 px-8 rounded-md"
                >
                  <option class="dropdown-item" value="single">
                    Single
                  </option>
                  <option class="dropdown-item" value="multiple">
                    Multiple
                  </option>
                </select>
              </div>
              <div className="flex items-center gap-4 py-2">
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    checked={updateddata?.isMoreSetting}
                    onChange={(e) =>
                      setUpdateddata({
                        ...updateddata,
                        isMoreSetting: e.target.checked,
                      })
                    }
                  />
                  <span>More Filter</span>
                </div>
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    checked={updateddata?.isDynamic}
                    onChange={(e) =>
                      setUpdateddata({
                        ...updateddata,
                        isDynamic: e.target.checked,
                      })
                    }
                  />
                  <span>Is Dynamic</span>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-12 gap-5 px-6 py-8">
              <div className="col-span-6">
                <div
                  onClick={UpdateAttributeData}
                  className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white"
                >
                  <MdOutlineDoneOutline className="text-2xl" />
                  <div class="uppercase">
                    <span>Confirm</span>
                  </div>
                </div>
              </div>

              <div className="col-span-6">
                <div
                  onClick={editcloseModal}
                  className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white"
                >
                  <GiCancel className="text-2xl" />
                  <div class="uppercase">
                    <span>Cancel</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* edit modal end */}
      </div>
      {/* Modal section */}
      <div
        className={`w-screen h-screen fixed top-0 left-0 bg-black bg-opacity-20 ${
          modal ? "block duration-500" : "hidden duration-500"
        } ${close ? "hidden" : ""}`}
      >
        <div
          className={`text-sm absolute top-[50%] left-[50%]  w-[38%] translate-x-[-50%] h-auto  translate-y-[-50%] bg-[#fff] rounded-md shadow-lg pb-6 ${
            modal ? "duration-500" : "duration-500"
          }`}
        >
          <div className="bg-primary w-full flex items-center justify-between px-6 py-4 rounded-tl-md rounded-tr-md text-white">
            <div className="text-sm font-bold">Delete</div>
            <AiOutlineClose
              size={20}
              onClick={closeModal}
              className="cursor-pointer font-bold"
            />
          </div>

          <div className="text-center py-8">
            <h2 className="font-semibold text-lg">
              Are you sure you want to delete this item?
            </h2>
          </div>

          <div className="grid grid-cols-12 gap-5 px-6 py-8">
            <div className="col-span-6">
              <div className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white">
                <MdOutlineDoneOutline className="text-2xl" />
                <div
                  className="uppercase"
                  onClick={(e) => {
                    handleDeletePerformerAbouteme();
                    closeModal();
                  }}
                >
                  <span>Confirm</span>
                </div>
              </div>
            </div>

            <div className="col-span-6">
              <div
                onClick={closeModal}
                className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white"
              >
                <GiCancel className="text-2xl" />
                <div class="uppercase">
                  <span>Cancel</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal section */}
    </>
  );
}
