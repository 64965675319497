import React from "react";
import { AiFillDelete, AiOutlineRight } from "react-icons/ai";
import { useEffect, useState } from "react";

import Header from "../sidebar/Header";
import LeftSideBar from "../sidebar/LeftSideBar";
import {
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Button,
    Option,
} from "@material-tailwind/react";
import { Input } from "@material-tailwind/react";
import AddForm from "./AddForm";
import AddFormTwo from "./AddFormTwo";


const ViewAttribute = () => {
    const [editState, setEditState] = useState(true);
    const [dropShow, setDropShow] = useState(false)
    const handleDropShow = (e) => {
        if (e.target.value === 'dropdown') {
            setDropShow(true);
        } else {
            setDropShow(false);
        }
    };
    const [arr, setArr] = useState([
        {
            id: new Date().getTime(),
            fieldName: "",
            placeholder: "",
            fieldType: {},
            required: false,
            options: [{ id: new Date().getTime(), value: "" }],
        },
    ]);
    const [test, setTest] = useState();
    const [open, setOpen] = useState(false);

    const [openDelete, setOpenDelete] = useState(false);
    const handleOpenDelete = (i) => {
        console.log(i);
        setTest(i);
        setOpenDelete(!openDelete);
    };

    const handleConfirm = (test) => {
        console.log(test);
        console.log(arr.filter((i, index) => index != test));
        setArr(arr.filter((i, index) => index != test));
        setOpenDelete(!openDelete);
    };

    const options = [
        // { value: "string", label: "String" },
        { value: "text", label: "text" },
        { value: "dropdown", label: "Dropdown" },
        { value: "multiselect", label: "Multiple Select" },
    ];

    const [category, setCategory] = useState("");

    const handleAdd = () => {
        setArr([
            ...arr,
            {
                id: new Date().getTime(),
                fieldName: "",
                placeholder: "",
                fieldType: {},
                required: false,
                options: [{ id: new Date().getTime(), value: "" }]
            },
        ]);
    };

    return (
        <div>
            <Header />
            <div className="col12SwmDashBord bg-white">
                <div className="col4SemNvr">
                    <LeftSideBar />
                </div>
                <div className="col8SemNvr mt-[70px] ml-[5%]">
                    <div className="grid grid-cols-11">
                        <div className="col-span-9 py-4">
                            <div className="text-black flex items-center text-sm tracking-wider font-bold">
                                Others{" "}
                                <span className="space-x-1 flex items-center text-secondary">
                                    <AiOutlineRight />
                                    Performer
                                </span>
                                <span className="space-x-1 flex items-center text-secondary">
                                    <AiOutlineRight />
                                    View Attributes & Filters
                                </span>
                            </div>
                        </div>

                    </div>
                    <hr className="h-[2px] shadow-sm w-full bg-[#61005D] mb-4 " />
                    <div className="flex justify-between mt-2 mb-2 px-4">
                        <div className="px-4 flex justify-start text-lg font-bold">
                            View Attribute
                        </div>
                        <button
                            onClick={() => setEditState(!editState)}
                            className="py-2 text-sm px-5 border bg-gray-700 text-white rounded-md"
                        >
                            {editState ? "Edit" : "Save"}
                        </button>
                    </div>
                    <hr />
                    <div className="col-span-12 md:col-span-6 mb-4">
                        <div className="w-72">
                            <select
                                // onChange={(e) => setOption(e.target.value)}
                                className="bg-white mt-2 drop-md w-72 text-[#898989] border-[#89898940] border-[1px] shadow-md py-2 px-8 rounded-md"
                            >
                                <option class="dropdown-item" value="Actor">Select Category</option>
                                <option class="dropdown-item" selected value="Actor">Actor</option>
                                <option class="dropdown-item" value="Singer">Singer</option>
                                <option class="dropdown-item" value="Dancer">Dancer</option>
                                <option class="dropdown-item" value="Entertainer">Entertainer</option>
                                <option class="dropdown-item" value="voiceover artist">voiceover artist</option>
                                <option class="dropdown-item" value="Crew">Crew</option>
                                <option class="dropdown-item" value="producer">producer</option>
                                <option class="dropdown-item" value="director">director</option>
                                <option class="dropdown-item" value="Theater Professional">Theater Professional</option>
                                <option class="dropdown-item" value="Music Professional">Music Professional</option>
                                <option class="dropdown-item" value="Model">Model</option>
                                <option class="dropdown-item" value="Talent">Talent</option>
                            </select>
                        </div>
                    </div>
                    <div className="">
                        {arr.map((a, i) => (
                            <>
                                <AddFormTwo
                                    key={a.id}
                                    fieldName={a.fieldName}
                                    setFieldName={(e) => {
                                        const newState = arr.map((obj) => {
                                            if (obj.id === a.id) {
                                                return { ...obj, fieldName: e.target.value };
                                            }
                                            return obj;
                                        });
                                        setArr(newState);
                                    }}
                                    fieldtypeoptions={options}
                                    required={a.required}
                                    placeholder={a.placeholder}
                                    setPlaceHolder={(e) => {
                                        const newState = arr.map((obj) => {
                                            if (obj.id === a.id) {
                                                return { ...obj, placeholder: e.target.value };
                                            }
                                            return obj;
                                        });
                                        setArr(newState);
                                    }}
                                    setRequired={(e) => {
                                        const newState = arr.map((obj) => {
                                            if (obj.id === a.id) {
                                                return { ...obj, required: e.target.value };
                                            }
                                            return obj;
                                        });
                                        setArr(newState);
                                    }}
                                    selectedElement={a.fieldType}
                                    select={a.required}
                                    setSelectedElement={(e) => {
                                        const newState = arr.map((obj) => {
                                            if (obj.id === a.id) {
                                                return { ...obj, fieldType: e };
                                            }
                                            return obj;
                                        });
                                        setArr(newState);
                                    }}
                                    //options
                                    options={a.options}
                                    addOption={(e) => {
                                        const newState = arr.map((obj) => {
                                            if (obj.id === a.id) {
                                                return {
                                                    ...obj,
                                                    options: [
                                                        ...obj.options,
                                                        { id: new Date().getTime(), value: "" },
                                                    ],
                                                };
                                            }
                                            return obj;
                                        });
                                        setArr(newState);
                                    }}
                                    removeOption={(id) => {
                                        const newState = arr.map((obj) => {
                                            if (obj.id === a.id) {
                                                const newOptions = obj.options.filter(
                                                    (i, index) => i.id !== id
                                                );

                                                return { ...obj, options: newOptions };
                                            }
                                            return obj;
                                        });
                                        setArr(newState);
                                    }}
                                    onChangeOption={(e, id) => {
                                        const newState = arr.map((obj) => {
                                            if (obj.id === a.id) {
                                                const newOptions = obj.options.map((i, index) => {
                                                    console.log(index);
                                                    if (id === i.id) {
                                                        return { ...i, value: e.target.value };
                                                    } else {
                                                        return i;
                                                    }
                                                });

                                                return { ...obj, options: newOptions };
                                            }
                                            return obj;
                                        });
                                        setArr(newState);
                                    }}
                                />
                                <div className="col-span-12 md:col-span-6 flex justify-end px-5 cursor-pointer pb-3">
                                    <div
                                        onClick={() => handleOpenDelete(i)}
                                        className="border-[#61005D] rounded-lg p-2 border-2 hover:scale-105 duration-400 transition-all"
                                    >
                                        <AiFillDelete size={20} color="purple" />
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                    <div className="grid grid-cols-12 justify-center items-center">
                        <div className="col-span-12 md:col-span-6 gap-4 flex justify-start py-3">
                            <Button color="purple">Save</Button>
                            <Button
                                onClick={handleAdd}
                                // onClick={() => setArr((oldArray) => [...oldArray, ""])}
                                color="purple"
                            >
                                Add More +
                            </Button>
                        </div>
                    </div>
                    <Dialog open={open}>
                        <DialogHeader>Confirm Add Category</DialogHeader>
                        <DialogBody divider>Do you want to continue</DialogBody>
                        <DialogFooter>
                            <Button
                                variant="text"
                                color="red"
                                // onClick={handleOpen}
                                className="mr-1"
                            >
                                <span>Cancel</span>
                            </Button>
                            <Button variant="gradient" color="green">
                                <span>Confirm</span>
                            </Button>
                        </DialogFooter>
                    </Dialog>
                    <Dialog open={openDelete} handler={handleOpenDelete}>
                        <DialogHeader>Confirm Deletion</DialogHeader>
                        <DialogBody divider>
                            Do you want to delete the last category you added?
                        </DialogBody>
                        <DialogFooter>
                            <Button
                                variant="text"
                                color="red"
                                onClick={handleOpenDelete}
                                className="mr-1"
                            >
                                <span>Cancel</span>
                            </Button>
                            <Button
                                variant="gradient"
                                color="green"
                                onClick={() => handleConfirm(test)}
                            >
                                <span>Confirm</span>
                            </Button>
                        </DialogFooter>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default ViewAttribute;
