import React, { useState } from "react";
import { AiOutlineRight } from "react-icons/ai";

import Select from "react-select";

import { GrFormUpload } from "react-icons/gr";
import Header from "../sidebar/Header";
import LeftSideBar from "../sidebar/LeftSideBar";
import { NavLink } from "react-router-dom";

const options = [
  { value: "Instant Job Alerts", label: "Instant Job Alerts" },
  { value: "Online Portfolio", label: "Online Portfolio" },
  { value: "Upload Photos", label: "Upload Photos" },
  { value: "Upload Videos", label: "Upload Videos" },
  { value: "Apply For Jobs", label: "Apply For Jobs" },
];

const DynamicPerformer = () => {
  const [name, setName] = useState("");
  const [disabled, setDisabled] = useState(true);

  function handleDisable() {
    setDisabled(!disabled);
  }

  return (
    <div>
      <Header />
      <div className="col12SwmDashBord bg-white">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>
        <div className="col8SemNvr mt-[70px] ml-[5%]">
          <div className="grid grid-cols-11">
            <div className="col-span-9 py-4">
              <div className="text-black flex items-center text-sm tracking-wider font-bold">
                Production{" "}
                <span className="space-x-1 flex items-center text-secondary">
                  <AiOutlineRight />
                  Production Type
                </span>
              </div>
            </div>
          </div>
          {/* Tabs are Here  */}

          {/* Start Complete Form */}
          <div className="border-[1.5px] border-gray-200 py-4 rounded-md">
            <div className="flex justify-between bg-grey pl-6 py-1 text-sm text-[#4A4A4A] font-semibold">
              Production Type
            </div>

            <div className="flex py-5 px-4 space-x-6 items-center">
              <div className="w-full">
                <label className="text-sm font-semibold text-[#898989]">
                  Add Production type
                </label>
                <br />
                <input
                  type="text"
                  placeholder="Enter Producation Type"
                  className="border-[1px] placeholder:text-xs  py-2.5 my-2 px-2 rounded-md  text-xs border-gray-300 w-full"
                />
              </div>
            </div>
          </div>
          {/* Cancel & Save Btn */}
          <div className="flex items-center justify-end pt-4 space-x-4 duration-300">
            <NavLink
              to="/subscription/add-subscription"
              className=" px-14 py-1 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
            >
              Add Production Type{" "}
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DynamicPerformer;
