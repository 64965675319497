import React, { useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { AdminChangePasswordapi } from '../../apiconfig/authService';

const Changepassword = () => {
  const navigate = useNavigate();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState();
  const [confirmNewPassword, setConfirmNewPassword] = useState();

  const handleCurrentPassword = (e) => {
    setCurrentPassword(e.target.value);
  };

  const handleNewPassword = (e) => {
    setNewPassword(e.target.value);
  };
  const handleConfirmNewPassword = (e) => {
    setConfirmNewPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (currentPassword === ""){
      toast("CURRENT PASSWORD should not be empty.");
      return;
    }
    if (newPassword === ""){
      toast("NEW PASSWORD should not be empty.");
      return;
    }
    if (confirmNewPassword === ""){
      toast("CONFIRM NEW PASSWORD should not be empty.");
      return;
    }
    if (newPassword !== confirmNewPassword){
      toast("NEW PASSWORD and CONFIRM PASSWORD do not match.");
      return;
    }
    
    const response = await AdminChangePasswordapi({
      currentPassword,
      newPassword,
    });
    if (response.code == 200) {
      toast(response.message);
      navigate("/dashboard");
    } else {
      toast(response.message);
    }
    console.log(response);
  };

  return (

    <>
      <div>
        <div className="h-screen grid grid-cols-12">
          <div className="col-span-12 md:col-span-7">
            <img src="/assets/images/welcome.png" alt='welcome' className="w-full h-[100%]" />
          </div>

          <div className='col-span-12 md:col-span-5'>
            <img src="/assets/images/logo.png" alt='Logo' className="pt-10 mx-auto" />

            <div className='mx-auto w-[80%] md:w-[400px] mt-24 mb-16 '>
              <div className='text-primary text-4xl text-center'>Change Password</div>
              {/* <div className='text-center text-lg mt-8 text-lightgold'>Create new Password</div>   */}

              <div className='mt-8'>
                <form className='space-y-6'>
                  <input value={currentPassword} onChange={handleCurrentPassword} type="password" name='Password' placeholder='Enter Current Password' className='outline-none placeholder-primary border-2 border-primary border-opacity-30  text-sm  w-full h-[45px] rounded-md shadow-sm pl-4' />
                </form>
                <form className='space-y-6 mt-[15px]'>
                  <input value={newPassword} onChange={handleNewPassword} type="password" name='Password' placeholder='Enter New Password' className='outline-none placeholder-primary border-2 border-primary border-opacity-30  text-sm  w-full h-[45px] rounded-md shadow-sm pl-4' />
                </form>
                <form className='space-y-6 mt-[15px]'>
                  <input value={confirmNewPassword} onChange={handleConfirmNewPassword} type="password" name='Password' placeholder='Confirm New Password' className='outline-none placeholder-primary border-2 border-primary border-opacity-30  text-sm  w-full h-[45px] rounded-md shadow-sm pl-4' />
                </form>
                <button onClick={handleSubmit} className="w-full bg-primary font-semibold text-md mt-8 text-white rounded-md py-2">
                  SUBMIT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Changepassword