import React, { useState, useEffect } from "react";
import LeftSideBar from "../sidebar/LeftSideBar";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../sidebar/Header";
import Select from "react-select";
import { Country, State, City } from "country-state-city";
import { getDirectoryTypes, addNewDirectory } from "../apiconfig/authService";
import { toast } from "react-toastify";

function AddNewDirectory() {
  const navigate = useNavigate();

  const [editState, setEditState] = useState(true);
  const options = [
    { value: "Production Type", label: "Production Type" },
    { value: "Ethnicity", label: "Ethnicity" },
  ];
  const [state, setState] = useState({
    // name: "",
    // email: "",
    // phone: "",
    // telephone: "",
    // address: "",
    // country: "",
    // state: "",
    // city: "",
    // pincode: "",
    // branchoffice: "",

    typeId: "",
    name: "",
    address: "",
    country: "",
    state: "",
    city: "",
    branchOffice: "",
    email: "",
    telePhone: "",
    phoneNumber: "",
    website: "",
  });

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    if (!state.type || !state.name || !state.email || !state.phoneNumber) {
      toast("Please Select Mandatory Fields");
    } else {
      try {
        const response = await addNewDirectory({
          typeId: state.type.value,
          name: state.name,
          address: state.address,
          country: state.country.name,
          state: state.state.name,
          city: state.city.name,
          branchOffice: state.branchOffice,
          email: state.email,
          telePhone: state.telePhone,
          phoneNumber: state.phoneNumber,
          website: state.website,
        });
        if (response.code == 200) {
          toast(response.message);
          navigate("/directory/add-directorypage");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  let mycountry = Country.getAllCountries();
  let mystate = State.getStatesOfCountry(state.country?.isoCode);
  let mycity = City.getCitiesOfState(
    state.country?.isoCode,
    state.state?.isoCode
  );

  const [dropDown, setDropDown] = useState([]);

  const getDropDownOptions = async () => {
    try {
      const response = await getDirectoryTypes();
      console.log(response.data);
      setDropDown(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  //   let arr = [];
  //   for (let i = 0; i < dropDown.length; i++) {
  //     arr[i] = dropDown[i].name;
  //   }

  var list = [];
  dropDown.forEach(function (element) {
    list.push({ value: element._id, label: element.name });
  });

  useEffect(() => {
    getDropDownOptions();
  }, []);

  console.log("list", list);

  return (
    <>
      <Header />
      <div className="col12SwmDashBord">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>
        <div className="col8SemNvr mt-[70px] ml-[5%]">
          <div className="block w-full p-2 bg-white">
            {/* Brecrums */}
            <nav class="flex MngeBredcums px-4" aria-label="Breadcrumb">
              <ol class="inline-flex items-center space-x-1 md:space-x-3">
                <li>
                  <div class="flex items-center">
                    <NavLink
                      to="#"
                      class="ml-1 text-xs font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white"
                    >
                      {" "}
                      Directory{" "}
                    </NavLink>
                  </div>
                </li>
                <li aria-current="page">
                  <div class="flex items-center">
                    <svg
                      class="w-4 h-4 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span class="ml-1  font-medium text-gray-500 md:ml-2 dark:text-gray-400 text-xs">
                      {" "}
                      View Directory{" "}
                    </span>
                  </div>
                </li>
              </ol>
            </nav>

            <div className="flex justify-between mt-2 mb-2 px-4">
              <h4 className="text-xl font-bold"> Director Detail</h4>
              <div className="space-x-4">
                <button
                  onClick={handleSubmit}
                  className=" px-6 py-1 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                >
                  Save
                </button>
                <NavLink
                  to="/directory/add-directorypage"
                  className=" px-6 py-1.5 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                >
                  Back
                </NavLink>
              </div>
            </div>

            {/* End Brecrums */}

            <div className="block w-full bg-white ">
              <div className="p-4 block">
                <div className="w-[20%]">
                  <label>
                    Select Category <span className="text-red-500">*</span>
                  </label>

                  <div class=" pb-4 w-full">
                    <Select
                      // label = {state.type}
                      value={state.type}
                      onChange={(e) =>
                        setState({
                          ...state,
                          type: e,
                        })
                      }
                      // onChange={(e) => setState(e.target.value)}
                      options={list}
                    />
                  </div>
                </div>
                <div class="py-3 grid grid-cols-2 gap-3 border-[1px] mb-0 w-full px-2 ">
                  <div class="w-full border px-3 my-3 justify-between  flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Name <span className="text-red-500">*</span>
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="name"
                        value={state.name}
                        onChange={handleChange}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between  flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Email <span className="text-red-500">*</span>
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="email"
                        value={state.email}
                        onChange={handleChange}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between  flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Phone Number <span className="text-red-500">*</span>
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="phoneNumber"
                        value={state.phoneNumber}
                        onChange={handleChange}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between  flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Telephone Number <span className="text-red-500">*</span>
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="telePhone"
                        // value={state.secondaryEmail}

                        onChange={handleChange}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between  flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Address
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <textarea
                        name="address"
                        value={state.address}
                        onChange={handleChange}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between  flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Country
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      {/* <input
                                                name="country"
                                                value={state.country}
                                                disabled={editState}
                                                onChange={handleChange}
                                                class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                                                id="grid-first-name"
                                                type="text"
                                            /> */}
                      <Select
                        name="country"
                        placeholder="Country"
                        getOptionLabel={(e) => e.name}
                        getOptionValue={(e) => e}
                        value={state.country}
                        onChange={(e) =>
                          setState({
                            ...state,
                            country: e,
                          })
                        }
                        options={mycountry}
                      />
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      State
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <Select
                        placeholder="State"
                        getOptionLabel={(e) => e.name}
                        getOptionValue={(e) => e}
                        value={state.state}
                        onChange={(e) =>
                          setState({
                            ...state,
                            state: e,
                          })
                        }
                        options={mystate}
                      />
                    </div>
                  </div>
                  <div class="w-full border px-3 my-3 justify-between flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      City
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <Select
                        placeholder="city"
                        getOptionLabel={(e) => e.name}
                        getOptionValue={(e) => e}
                        value={state.city}
                        onChange={(e) =>
                          setState({
                            ...state,
                            city: e,
                          })
                        }
                        options={mycity}
                      />
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Pincode
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="pincode"
                        // value={(e) => e.target.value}
                        onChange={(e) => e.target.value}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Website
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="website"
                        value={state.website}
                        onChange={handleChange}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Branch Office
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="branchOffice"
                        value={state.branchOffice}
                        onChange={handleChange}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddNewDirectory;
