import React, { useState, useEffect } from "react";
import LeftSideBar from "../sidebar/LeftSideBar";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../sidebar/Header";
import Select from "react-select";
import { Country, State, City } from "country-state-city";
import { addTestimonialDataShow } from "../apiconfig/authService";
import { toast } from "react-toastify";
function CreateTestimonial() {
  const navigate = useNavigate();

  const [testimonialAdd, setTestimonalAdd] = useState({
    name: "",
    designation: "",
    location: "",
    type: "",
    description: "",
  });

  const [LargeImg, setLargeImg] = useState();
  const [upload1, setUpload1] = useState();
  function handleUpload1(e) {
    console.log(e.target.files);
    setLargeImg(e.target.files[0]);
    setUpload1(URL.createObjectURL(e.target.files[0]));
  }

  const handleChange = (e) => {
    setTestimonalAdd({ ...testimonialAdd, [e.target.name]: e.target.value });
  };

  const [dropDown, setDropDown] = useState([]);

  var data = new FormData();
  data.append("name", testimonialAdd.name);
  data.append("designation", testimonialAdd.designation);
  data.append("location", testimonialAdd.location);
  data.append("type", testimonialAdd.type);
  data.append("description", testimonialAdd.description);
  data.append("file", LargeImg);

  const addTestimonialDetails = async () => {
    if (
      !testimonialAdd.name ||
      !testimonialAdd.designation ||
      !testimonialAdd.location ||
      !testimonialAdd.type ||
      !testimonialAdd.description
    ) {
      toast("Please Fill Details");
    } else {
      try {
        const response = await addTestimonialDataShow(data);

        if (response.code == 200) {
          navigate("/cms/testimonials");
          toast(response.message);
        } else {
          toast(response.message);
        }
        console.log(response);
      } catch (error) {}
    }
  };

  useEffect(() => {}, []);

  return (
    <>
      <Header />
      <div className="col12SwmDashBord">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>
        <div className="col8SemNvr mt-[70px] ml-[5%]">
          <div className="block w-full p-2 bg-white">
            {/* Brecrums */}
            <nav class="flex MngeBredcums px-4" aria-label="Breadcrumb">
              <ol class="inline-flex items-center space-x-1 md:space-x-3">
                <li>
                  <div class="flex items-center">
                    <NavLink
                      to="#"
                      class="ml-1 text-base font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white"
                    >
                      {" "}
                      Testimonial{" "}
                    </NavLink>
                  </div>
                </li>
                <li aria-current="page">
                  <div class="flex items-center">
                    <svg
                      class="w-4 h-4 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span class="ml-1  font-medium text-gray-500 md:ml-2 dark:text-gray-400 text-base">
                      {" "}
                      Add Testimonial{" "}
                    </span>
                  </div>
                </li>
              </ol>
            </nav>

            <div className="flex justify-between mt-2 mb-2 px-4">
              <h4 className="text-xl font-bold"> Testimonial Detail</h4>
              <div className="space-x-4">
                <button
                  onClick={addTestimonialDetails}
                  className=" px-6 py-1 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                >
                  Save
                </button>
                <NavLink
                  to="/cms/testimonials"
                  className=" px-6 py-1.5 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                >
                  Back
                </NavLink>
              </div>
            </div>

            {/* End Brecrums */}

            <div className="block w-full bg-white ">
              <div className="p-4 block">
                <div class="py-3 grid grid-cols-2 gap-3 border-[1px] mb-0 w-full px-2 ">
                  <div class="w-full border px-3 my-3 justify-between  flex items-center">
                    <label class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2">
                      Name
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="name"
                        value={testimonialAdd.name}
                        onChange={handleChange}
                        class="appearance-none block w-full text-base text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between  flex items-center">
                    <label class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2">
                      location
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="location"
                        value={testimonialAdd.location}
                        onChange={handleChange}
                        class="appearance-none block w-full text-base text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between  flex items-center">
                    <label class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2">
                      Designation
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="designation"
                        value={testimonialAdd.designation}
                        onChange={handleChange}
                        class="appearance-none block w-full text-base text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between  flex items-center">
                    <label class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2">
                      Type
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <select
                        name="type"
                        onChange={handleChange}
                        class="appearance-none bg-white border text-xs w-full rounded py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-700"
                      >
                        <option> Select Type </option>
                        <option value={"performer"}> Performer </option>
                        <option value={"employee"}> Employee </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="w-full border px-3 my-3 justify-between  flex items-center">
                  <label
                    class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                    for="grid-first-nameBnerImg"
                  >
                    Upload Image
                  </label>
                  <div className="w-full md:w-[90%] px-3">
                    <input
                      name="name"
                      onChange={handleUpload1}
                      class="appearance-none block w-full text-base text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="grid-first-nameBnerImg"
                      type="file"
                    />
                  </div>
                </div>
                <div class="w-full  px-3 my-3 justify-between  flex items-center">
                  <img className="w-[150px]" src={upload1} alt="" />
                </div>

                <label class="block w-full border-r py-2 px-3 mt-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2">
                  Description
                </label>
                <div class="w-full border px-3 my-1 justify-between  flex items-center">
                  <div className="w-full">
                    <textarea
                      placeholder="Write your description here..."
                      name="description"
                      onChange={handleChange}
                      class="appearance-none block w-full h-24 text-base text-gray-700  rounded py-2  leading-tight focus:outline-none focus:bg-white"
                      value={testimonialAdd.description}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateTestimonial;
