const API_PATH = {
  apiAdminLogin: "/admin/login",
  apiAdminChangePassword: "/admin/changePassword",
  apiAddProductiontype: "/productionType/addProductionType",
  apiAddEthnicity: "/ethnicity/addEthnicity",
  apiAddPerformercat: "/performerCategory/addPerformerCategory",
  apiGetperformerCat: "/performerCategory/getPerformerCategory",
  apiDeleteperformer: "/performerCategory/deletePerformerCategory",
  apiGetAllPerformers: "/performer/getAllPerformerAdmin",
  apiGetPerformerById: "/performer/getPerformerById?id=",
  apiGetEmployerById: "/employer/getEmployerById",
  apiGetAllEmployers: "/employer/getAllEmployer",
  apiGetAllJobs: "/job/getAllJobAdmin",
  deleteJob: "/job/deleteJob",
  apiUpdateperformerCategory: "/performerCategory/upadtePerformerCategory",
  apiPostDirectiveCategory: "/directoryType/addDirectoryType",
  apiGetDirectoryCategory: "/directoryType/getDirectoryType",
  apiDeleteDirectiveCategory: "/directoryType/deleteDirectoryType",
  apiUpdateDirectiveCategory: "/directoryType/upadteDirectoryType",
  apiGetAttributeaboutme: "/aboutMeMaster/getAboutMeMaster",
  apiDeleteAttributeabouteme: "/aboutMeMaster/deleteAboutMeMaster",
  apiGetJobById: "/job/getJobById?id=",
  apiGetJobApplications: "/jobApplication/getJobApplication",
  apiUpdateJobStatus: "/job/updateJobStatusById",
  apiGetJobApplicationById: "/jobApplication/getJobApplicationById?id=",
  apiAddAttributeAboutMemaster: "/aboutMeMaster/addAboutMeMaster",
  apiAddEmployerCategory: "/employerCategory/addEmployerCategory",
  apiGetEmployerCategory: "/employerCategory/getEmployerCategory",
  apiGetProductionType: "/productionType/getProductionType",
  apiGetJobApplications: "/jobApplication/getJobApplication",
  apiAddFilter: "/attributeMaster/addAttributeMaster",
  apiUpdateProductionType: "/productionType/upadteProductionType",
  apiDeleteProductionType: "/productionType/deleteProductionType",
  apigetEthenecity: "/ethnicity/getAllEthnicity",
  apiUpdateEthencity: "/ethnicity/updateEthnicity",
  apiDeleteEthencity: "/ethnicity/deleteEthnicity",
  apiGetAttributeAbouteMemaster: "/performerCategory/getPerformerCategory",
  apiAddDirectoryDropDown: "/directoryType/getDirectoryType",
  apiGetDirectoryData: "/directoryData/getDirectoryData",
  apiDirectoryDataById: "/directoryData/getDirectoryDataById?id=",
  apiGetfilter: "aboutMeMaster/getAboutMeMaster",
  apiGetattributebyID: "/aboutMeMaster/getAboutMeMasterById?id=",
  apiEmployerActivation: "/employer/deleteEmployer",
  apiPerformerActivation: "/performer/deletePerformer",
  apiAddDirectory: "/directoryData/addDirectoryData",
  apiEditDirectoryById: "/directoryData/upadteDirectoryData",
  apiAddBlogData: "/blogData/addBlogData",
  apiDeletePerformerAbouteMe: "/aboutMeMaster/deleteAboutMeMaster",
  apiDeleteAbouteMeFilter: "/attributeMaster/deleteAttributeMaster",

  apiUpdateFilterData: "/attributeMaster/upadteAttributeMaster",
  apiUpdateAttributeMaster: "/aboutMeMaster/upadteAboutMeMaster",
  apiGetAllBlogData: "/blogData/getBlogData",
  apiGetAllBlogCategory: "/blogCategory/getBlogCategory",
  apiPostBlogData: "/blogData/addBlogData",
  apiPutBlogData: "/blogData/upadteBlogData",
  apigetBlogDetailsById: "/blogData/getBlogDataById?id=",
  apiBlogDelete: "/blogData/deleteBlogData",
  apiGetAllPartner: "/partner/getAllPartner",
  apiPartnerAdd: "/partner/addPartner",
  apigetPartnerDetailsById: "/partner/getPartnerById?id=",
  apiPartnerPut: "/partner/updatePartner",
  apiPartnerDelete: "/partner/deletePartner",
  apiGetAllLifeStyleListingCategory: "/lifestyleCategory/getlifestyleCategory",
  apiLifeStyleBlogData: "/lifestyleData/addlifestyleData",
  apiGetLifeStyle: "/lifestyleData/getlifestyleData",
  apiLifeStyleputData: "/lifestyleData/upadtelifestyleData",
  apigetLifeStyleDetailsById: "/lifestyleData/getlifestyleDataById?id=",
  apiaddJobData: "/jobdata/addJobData",
  apiAllJobData: "/jobdata/getAllJobData",
  apiGetDelete: "/jobdata/deleteJobData?id=",
  apiLifeStyleDelete: "/lifestyleData/deletelifestyleData",
  apigetAllHeaderMenu: "/menuMaster/getmenuMaster",
  apiHeaderDelete: "/menuMaster/updateMenuMasterStatus?id=",
  apigetAllHeaderMenuFilter: "/menuMaster/getmenuMaster?type=",
  apiBannerList: "/banner/getbanner",
  apiBannerById: "/banner/getbannerById?id=",
  apiBannerEdit: "/banner/upadtebanner",
  apiBannerPost: "/banner/addbanner",
  apiBneerDelete: "/banner/deletebanner?id=",
  apiGetAllVideos: "/topVideo/gettopVideo",
  apiHomePageVideosPost: "/topVideo/addtopVideo",
  apiVideoDeleteDta: "/topVideo/deletetopVideo?id=",
  apiTopVideoById: "/topVideo/gettopVideoById?id=",
  apiTopVideosEdit: "/topVideo/upadtetopVideo",
  apigetPerformerList: "/topPerformer/gettopPerformer",
  apiTopPerfomerDeleteDta: "/topPerformer/deletetopPerformer?id=",
  apiTopPerFormerData: "/topPerformer/addtopPerformer",
  apiTopPerFormeById: "/topPerformer/gettopPerformerById?id=",
  apiTopPerFormerEditData: "/topPerformer/upadtetopPerformer",
  apiJobById: "/jobdata/getJobDataById?id=",
  apiEditjobData: "/jobdata/updateJobData",
  apiAllAplications: "/career/getcareer",
  apiLibrayListData: "/library/getlibrary",
  apiLibrayData: "/library/addlibrary",
  apiLibrayDeleteData: "/library/deletelibrary?id=",
  apiGetLibrayById: "/library/getlibraryById?id=",
  apiPutLibryaData: "/library/upadtelibrary",
  apiTestimonalDta: "/testimonial/gettestimonial",
  apiDeleteTestimonalDta: "/testimonial/deletetestimonial?id=",
  apiTestimonialData: "/testimonial/addtestimonial",
  apiGetTestimonialById: "/testimonial/gettestimonialById?id=",
  apiPutTestimonialData: "/testimonial/upadtetestimonial",
  apiGetAllContactus: "/contactus/getAllContactus",
  apiGetAllPerformerAdmin: "/performer/getAllPerformer",
  apiGetPerformerPageAdmin: "/performer/getPerformerPageAdmin",
  apiGetAllEmployeeAdmin: "/employer/getAllEmployer",
  apiGetAllJobAdmin: "/jobdata/getAllJobData",
  apiGetSubscriptionList: "/subscription/getSubscription",
  apiAddSubcription: "/subscription/addSubscription",
  apiDeleteSubcription: "/subscription/deleteSubscription?id=",
  apigetSubscriptionList: "/subscription/getSubscriptionById?id=",
  apisubScriptionPut: "/subscription/upadteSubscription",
  apiGetSubscriptionOrderList: "/subscriptionOrder/getSubscriptionOrder",
  apiGetAllCountry: "/country/getAllCountry",
  apiGetAllState: "/state/getAllState?country=",
  apiUpateDircPutApi: "/directoryData/upadteDirectoryData",
  apiDeleteDirectory: "/directoryData/deleteDirectoryData?id=",
  apiDeletePerformerPermanently: "/performer/delete-permanently?id=",
  apiDeleteEmployerPermanently: "/employer/delete-permanently?id=",
  apiMetaDataTag:"/metaData"
};
export default API_PATH;
