import React, { useState, useEffect, Fragment } from "react";
import LeftSideBar from "../sidebar/LeftSideBar";
import { HiEye } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import { NavLink, Link } from "react-router-dom";
import { MdDelete, MdOutlineDoneOutline } from "react-icons/md";
import { GiCancel } from "react-icons/gi";
import Select from "react-select";
import Header from "../sidebar/Header";
import {
  getJobApplications,
  getJobsList,
  deleteJob,
} from "../apiconfig/authService";
import { getToken } from "../utils/localStorage";
import { toast } from "react-toastify";
import { Pagination } from "rsuite";
import "rsuite/dist/rsuite.min.css";
export default function Jobs() {
  const options = [
    { value: "", label: "Clear" },
    { value: "active", label: "Active" },
    { value: "pending", label: "Pending" },
    { value: "rejected", label: "Rejected" },
  ];
  const performerDetails = [
    {
      id: 1,
      jobTitle: "Production Title",
      productionCompany: "Test Production",
      productionTitle: "Title",
      jobLocation: "Gurgaon",
      button: "Active",
    },
    {
      id: 2,
      jobTitle: "Production Title",
      productionCompany: "Test Production",
      productionTitle: "Title",
      jobLocation: "Gurgaon",
      button: "Pending",
    },
    {
      id: 3,
      jobTitle: "Production Title",
      productionCompany: "Test Production",
      productionTitle: "Title",
      jobLocation: "Gurgaon",
      button: "Rejected",
    },
    {
      id: 4,
      jobTitle: "Production Title",
      productionCompany: "Test Production",
      productionTitle: "Title",
      jobLocation: "Gurgaon",
      button: "Pending",
    },
    {
      id: 5,
      jobTitle: "Production Title",
      productionCompany: "Test Production",
      productionTitle: "Title",
      jobLocation: "Gurgaon",
      button: "Active",
    },
    {
      id: 6,
      jobTitle: "Production Title",
      productionCompany: "Test Production",
      productionTitle: "Title",
      jobLocation: "Gurgaon",
      button: "Rejected",
    },
    {
      id: 7,
      jobTitle: "Production Title",
      productionCompany: "Test Production",
      productionTitle: "Title",
      jobLocation: "Gurgaon",
      button: "Active",
    },
  ];
  const [jobName, setJobName] = useState("");
  const [dropSelect, setDropSelect] = useState("");
  const [date, setDate] = useState("");

  const [jobState, setJobState] = useState([]);
  const [jobId, setJobId] = useState("");
  const [refetchData, setRefetchData] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  console.log("JOB ID", jobId);

  const getAllJobApplications = async () => {
    try {
      const response = await getJobsList(
        pageSize === "All" ? totalCount : pageSize,
        pageSize === "All" ? 1 : pageNo,
        jobName,
        date,
        dropSelect
      );
      console.log(response.data);
      setJobState(response.data);
      setTotalCount(response.pagination.totalCount);
      setTotalPages(Math.ceil(response.pagination.totalPages));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllJobApplications();
  }, [pageNo, refetchData, date, dropSelect, jobName]);
  const handlePageSizeChange = (e) => {
    setPageSize(e.target.value);
    setPageNo(1);
    setRefetchData(!refetchData);
  };

  console.log("jobState", jobState);
  //   Modal
  const [modal, setModal] = useState(false);
  const [close, setClose] = useState(false);
  const openModal = () => {
    setModal(true);
    setClose(false);
  };

  const closeModal = () => {
    setClose(true);
    setModal(false);
  };

  const handleConfirmDeleteClick = async () => {
    setDeleteRecord(!deleteRecord);
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await deleteJob(jobId);
        toast(response.message);
        setRefetchData(!refetchData);
      } catch (error) {
        console.log(error);
      }
    })();
    closeModal();
  }, [deleteRecord]);

  return (
    <>
      <Header />
      <div className="col12SwmDashBord bg-white ">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>
        <div className="col8SemNvr mt-[70px] ml-[5%]">
          {/* Bredcrums */}
          <div className="grid grid-cols-12">
            <div className="col-span-10 ">
              <div className="HdingSwnvr my-3 px-5">
                <h5>Job Details</h5>
              </div>
            </div>
          </div>
          <hr className="w-full bg-gray-500 h-[2px]" />
          {/* Bredcrums End */}

          <div className="flex col12WdthSwhnMn mt-2 overflow-x-auto">
            <div className="flex space-x-8 items-center mb-5">
              <div className="WdtPerMn">
                <label>Search </label>
                <div class="flex items-center ServeCtgrySwn pt-3">
                  <label for="simple-search" class="sr-only">
                    Search
                  </label>
                  <div class="relative w-full">
                    <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                      <svg
                        aria-hidden="true"
                        class="w-5 h-5 text-gray-500 dark:text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <input
                      type="text"
                      id="simple-search"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm  block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                      placeholder="Search "
                      onChange={(e) => setJobName(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="WdtPerMn">
                <label>Search by Date</label>
                <div class="flex items-center ServeCtgrySwn pt-3">
                  <div class="relative w-full">
                    <input
                      type="date"
                      onChange={(e) =>
                        setDate(e.target.value.split("-").reverse().join("/"))
                      }
                      className="bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-sm  block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="w-[20%] ">
                <label>Filter By Status</label>

                <div class="mt-3 w-full">
                  <Select
                    onChange={(e) => setDropSelect(e.value)}
                    options={options}
                    className=""
                  />
                </div>
              </div>
            </div>

            <div className="flex">
              <label>Show </label>
              <div class="flex mt-[-2px] mr-2 ml-2 items-center ServeCtgrySwn">
                <div class="relative w-full">
                  <select
                    className="bg-gray-50 py-1 border border-gray-300 text-gray-500 text-sm rounded-sm  block w-full px-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                    value={pageSize}
                    onChange={handlePageSizeChange}
                  >
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={250}>250</option>
                    <option value={500}>500</option>
                    <option value="All">All</option>
                  </select>
                </div>
              </div>
              <label> records per page. </label>
            </div>
            {/* Table  */}

            <div class=" relative  shadow-md sm:rounded-lg  mt-5">
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
                <thead class="text-sm text-gray-700 uppercase bg-orange-100	 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" class="py-4 px-4">
                      S No.
                    </th>
                    <th scope="col" class="py-4 px-4">
                      Job Title
                    </th>
                    <th scope="col" class="py-4 px-4">
                      Production Company
                    </th>
                    <th scope="col" class="py-4 px-4">
                      Production Title
                    </th>
                    <th scope="col" class="py-4 px-4">
                      Posted Date
                    </th>
                    <th scope="col" class="py-4 px-6 ml-6">
                      Account Activation
                    </th>

                    <th scope="col" class="py-4 px-4">
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody className="TableService">
                  {jobState && jobState.map((item, index) => (
                    <tr
                      class="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                      key={index}
                    >
                      <th
                        scope="row"
                        class="py-3 px-4 font-medium text-sm text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {index + 1}
                      </th>
                      <th
                        scope="row"
                        class="py-3 px-4 font-medium text-sm text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {item.jobTitle}
                      </th>
                      <td class="py-3 text-sm px-8">
                        {item.productionCompany}
                      </td>
                      <td className="py-3 text-sm px-8">
                        {item.productionTitle}
                      </td>
                      <td className="py-3  text-sm px-8">
                        {new Date(item.createdAt).toLocaleDateString("en-GB")}
                      </td>

                      <td className="py-3  text-sm px-4">
                        <div
                          className={` text-white mx-7 text-center py-2 rounded-md ${
                            item.status.toLowerCase() === "active" ||
                            item.status.toLowerCase() === "approved"
                              ? "bg-green-500"
                              : item.status.toLowerCase() === "requested" ||
                                item.status.toLowerCase() === "shortlisted" ||
                                item.status.toLowerCase() === "pending" ||
                                item.status.toLowerCase() === "pending" ||
                                item.status.toLowerCase() === "applied"
                              ? "bg-blue-500"
                              : item.status.toLowerCase() === "rejected" ||
                                item.status.toLowerCase() === "draft"
                              ? "bg-red-500"
                              : null
                          }`}
                        >
                          {item.status.toUpperCase()}
                        </div>
                      </td>

                      <td class="py-3 text-sm">
                        <div className="flex gap-5">
                          <Link to={`/job-details/${item._id}`}>
                            <HiEye
                              className="bg-[#0a65cc] cuesor-pointer text-white p-2"
                              size={32}
                            />
                          </Link>
                          <MdDelete
                            onClick={(e) => {
                              openModal();
                              setJobId(item._id);
                            }}
                            className="bg-red-600 cursor-pointer text-white p-2"
                            size={32}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Table End */}
          </div>
          <div className=" pb-[20px] pl-[20px] pr-[20px] w-full flex justify-between mt-3">
            {totalCount === 0 ? (
              <div>Showing 0 - 0 entries of 0 entries. </div>
            ) : pageSize === "All" ? (
              <div>
                Showing {1} - {totalCount} entries of {totalCount} entries.
              </div>
            ) : (
              <div>
                Showing {(pageNo - 1) * pageSize + 1} -{" "}
                {totalCount < pageSize * pageNo
                  ? totalCount
                  : pageSize * pageNo}{" "}
                entries of {totalCount} entries.
              </div>
            )}
            {/* Pagination Start */}
            <div style={{ margin: "100" }}>
              <Pagination
                prev
                last
                next
                first
                size="sm"
                ellipsis
                boundaryLinks
                onSelect={setPageNo}
                limit={pageSize === "ALL" ? totalCount : pageSize}
                onChangeLimit={setPageSize}
                layout={["pager", "skip"]}
                pages={totalPages}
                total={totalCount}
                maxButtons={5}
                activePage={pageNo}
                onChangePage={(page) => {
                  setPageNo(page);
                }}
              />
            </div>
            {/* Pagination End */}
          </div>
        </div>

        {/* Modal section */}
        <div
          className={`w-screen h-screen fixed top-0 left-0 bg-black bg-opacity-20 ${
            modal ? "block duration-500" : "hidden duration-500"
          } ${close ? "hidden" : ""}`}
        >
          <div
            className={`text-sm absolute top-[50%] left-[50%]  w-[38%] translate-x-[-50%] h-auto  translate-y-[-50%] bg-[#fff] rounded-md shadow-lg pb-6 ${
              modal ? "duration-500" : "duration-500"
            }`}
          >
            <div className="bg-primary w-full flex items-center justify-between px-6 py-4 rounded-tl-md rounded-tr-md text-white">
              <div className="text-sm font-bold">Delete</div>
              <AiOutlineClose
                size={20}
                onClick={closeModal}
                className="cursor-pointer font-bold"
              />
            </div>

            <div className="text-center py-8">
              <h2 className="font-semibold text-lg">
                Are you sure you want to delete this item?
              </h2>
            </div>

            <div className="grid grid-cols-12 gap-5 px-6 py-8">
              <div className="col-span-6">
                <div className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white">
                  <MdOutlineDoneOutline className="text-2xl" />
                  <div className="uppercase" onClick={handleConfirmDeleteClick}>
                    <span>Confirm</span>
                  </div>
                </div>
              </div>

              <div className="col-span-6">
                <div
                  onClick={closeModal}
                  className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white"
                >
                  <GiCancel className="text-2xl" />
                  <div class="uppercase">
                    <span>Cancel</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal section */}
      </div>
    </>
  );
}
