import React, { Fragment, useEffect, useState } from "react";
import Header from "../sidebar/Header";
import LeftSideBar from "../sidebar/LeftSideBar";
import { BiEdit } from "react-icons/bi";
import { MdDelete, MdOutlineDoneOutline } from "react-icons/md";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { GiCancel } from "react-icons/gi";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
// import { HiEye } from "react-icons/hi";
import {
  addMetaDataPage,
  deleteMetaDataPage,
  getAllMetaData,
  getAllPagesData,
  updateMetaDataPage,
} from "../apiconfig/authService";
// xpecto developer 03/09/2024
function Pages() {
  const [metaData, setMetaData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [editData, setEditData] = useState({});
  const [seoData, setSeoData] = useState({
    pageName: "",
    title: "",
    description: "",
    keyword: "",
  });
  const getPagesData = async () => {
    try {
      const response = await getAllMetaData();
      console.log("metaData=====>", response.data);
      setMetaData(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllPages = async () => {
    try {
      const response = await getAllPagesData();
      console.log("pagesData=====>", response.data);
      setDropdownPages(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [dropdownPages, setDropdownPages] = useState([]);

  //   Modal
  const [isOpen, setIsOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  function closeModal() {
    setIsOpen(false);
    setSeoData({ pageName: "", title: "", description: "", keyword: "" });
  }
  function openModal() {
    setIsOpen(true);
  }
  function closeedit() {
    setEdit(false);
  }
  const openedit = () => {
    setEdit(true);
  };

  const [pageId, setPageId] = useState("");
  const [modalDelete, setModalDelete] = useState(false);
  const [closeDelete, setCloseDelete] = useState(false);
  const openModalDelete = () => {
    setModalDelete(true);
    setCloseDelete(false);
  };

  const closeModalDelete = () => {
    setCloseDelete(true);
    setModalDelete(false);
  };

  const handleAddChange = (e) => {
    setSeoData({ ...seoData, [e.target.name]: e.target.value });
  };
  console.log("add data", seoData);

  const handleAddPage = async () => {
    try {
      if(seoData.pageName===''){
        toast("Please Select Valid Page")
      }else{
        const response = await addMetaDataPage(seoData);
        if (response.code === 200) {
          toast(response.message);
          closeModal();
          setRefresh(!refresh);
          setSeoData({ pageName: "", title: "", description: "", keyword: "" });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeletePage = async (id) => {
    try {
      const response = await deleteMetaDataPage(id);
      if (response.code === 200) {
        toast(response.message);
        setRefresh(!refresh);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleActiveState = async (id, status) => {
    try {
      const response = await updateMetaDataPage({ id: id, isActive: !status });
      if (response.code === 200) {
        toast(response.message);
        setRefresh(!refresh);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditChange = (e) => {
    setEditData({ ...editData, [e.target.name]: e.target.value });
  };

  const handleUpdatePage = async () => {
    try {
      const response = await updateMetaDataPage({
        id: editData._id,
        description: editData.description,
        title: editData.title,
        keyword: editData.keyword,
      });
      if (response.code === 200) {
        toast(response.message);
        closeedit();
        setRefresh(!refresh);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPagesData();
    getAllPages();
  }, [refresh]);

  return (
    <>
      <Header />
      <div className="col12SwmDashBord bg-white ">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>
        <div className="col8SemNvr mt-[70px] ml-[5%]">
          {/* Bredcrums */}
          <div className="grid grid-cols-12">
            <div className="col-span-10 ">
              <div className="HdingSwnvr my-3 px-5">
                <h5> Pages </h5>
              </div>
            </div>
          </div>
          <hr className="w-full bg-gray-500 h-[2px]" />
          {/* Bredcrums End */}

          <div className="flex col12WdthSwhnMn mt-2">
            <div className="flex justify-between items-end mb-5">
              <div className="">
                {/* <label>Filter By Category</label> */}

                <div class="mt-3 w-52">
                  {/* <Select options={list} className="" /> */}
                  {/* <select>
                                       {dropDown.map((option,index) => (
                                           <option value={index + 1}>
                                               {option.name}
                                           </option>
                                       ))}
                                   </select>

                                    <Select name="select category">
                                       {dropDown.map((fbb) => (
                                           <option
                                               key={fbb.key}
                                               value={fbb.key}
                                           >
                                               {fbb.name}
                                           </option>
                                       ))}
                                       ;
                                   </Select> */}
                </div>
              </div>

              <div className="hover:shadow">
                {/* <NavLink to="/directory/create-category"> */}
                <div className="flex cursor-pointer hover:shadow items-center border-[1px] px-6 space-x-1 py-2 rounded-md shadow-md">
                  <AiOutlinePlus />
                  <span onClick={openModal}>Add Page</span>
                </div>
                {/* </NavLink> */}
              </div>
            </div>
            {/* <div className="flex">
              <label>Show </label>
              <div class="flex mt-[-2px] mr-2 ml-2 items-center ServeCtgrySwn">
                <div class="relative w-full">
                  <select
                    className="bg-gray-50 py-1 border border-gray-300 text-gray-500 text-sm rounded-sm  block w-full px-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                    value={pageSize}
                    onChange={handlePageSizeChange}
                  >
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={250}>250</option>
                    <option value={500}>500</option>
                    <option value="All">All</option>
                  </select>
                </div>
              </div>
              <label> records per page. </label>
            </div> */}
            {/* Table  */}

            <div class=" relative  shadow-md sm:rounded-lg  mt-5">
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
                <thead class="text-sm text-gray-700 uppercase bg-orange-100	 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" class="py-4 px-4">
                      S No.
                    </th>
                    <th scope="col" class="py-4 px-4">
                      Page Name
                    </th>
                    {/* <th scope="col" class="py-4 px-4">
                      Page Route
                    </th> */}
                    <th scope="col" class="py-4 px-4">
                      SEO Title
                    </th>
                    <th scope="col" class="py-4 px-4">
                      SEO Description
                    </th>
                    <th scope="col" class="py-4 px-4">
                      SEO Keyword
                    </th>
                    <th scope="col" class="py-4 px-4">
                      Active
                    </th>
                    <th scope="col" class="py-4 px-4">
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody className="TableService">
                  {metaData &&
                    metaData?.map((item, index) => (
                      <tr
                        class="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                        key={index}
                      >
                        <th
                          scope="row"
                          class="py-3 px-4 font-medium text-sm text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {index + 1}
                        </th>
                        <th
                          scope="row"
                          class="py-3 px-4 font-medium text-sm text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {item.pageName
                            .split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" ")}
                        </th>
                        {/* <td class="py-3 text-sm">
                          {item.routes ? item.routes : "NA"}
                        </td> */}
                        <td class="py-3 px-4 text-sm">{item.title}</td>
                        <td class="py-3 px-4 text-sm">{item.description}</td>
                        <td class="py-3 px-4 text-sm">{item.keyword}</td>
                        <td class="py-3 px-4 text-sm">
                          <label class="inline-flex relative items-center cursor-pointer">
                            <input
                              type="checkbox"
                              value=""
                              defaultChecked={item.isActive}
                              onClick={() =>
                                handleActiveState(item._id, item.isActive)
                              }
                              class="sr-only peer"
                            />
                            <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                          </label>
                        </td>

                        <td class="py-3 text-sm">
                          <div className="flex gap-5">
                            <BiEdit
                              onClick={() => {
                                setEditData(item);
                                openedit();
                              }}
                              className="bg-blue-600 cursor-pointer text-white p-1"
                              size={32}
                            />
                            {/* <HiEye
                              className="bg-[#0a65cc] text-white p-2"
                              size={32}
                            /> */}
                            <MdDelete
                              onClick={() => {
                                openModalDelete();
                                setPageId(item._id);
                              }}
                              className="bg-red-600 text-white p-2"
                              size={32}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            {/* Table End */}
          </div>
          <div className=" pb-[20px] pl-[20px] pr-[20px] w-full flex justify-between mt-3">
            {/* {totalCount === 0 ? (
              <div>Showing 0 - 0 entries of 0 entries. </div>
            ) : pageSize === "All" ? (
              <div>
                Showing {1} - {totalCount} entries of {totalCount} entries.
              </div>
            ) : (
              <div>
                Showing {(pageNo - 1) * pageSize + 1} -{" "}
                {totalCount < pageSize * pageNo
                  ? totalCount
                  : pageSize * pageNo}{" "}
                entries of {totalCount} entries.
              </div>
            )} */}

            {/* Pagination Start */}
            {/* <div style={{ margin: "100" }}>
              <Pagination
                prev
                last
                next
                first
                size="sm"
                ellipsis
                boundaryLinks
                onSelect={setPageNo}
                limit={pageSize === "ALL" ? totalCount : pageSize}
                onChangeLimit={setPageSize}
                layout={["pager", "skip"]}
                pages={totalPages}
                total={totalCount}
                maxButtons={5}
                activePage={pageNo}
                onChangePage={(page) => {
                  setPageNo(page);
                }}
              />
            </div> */}
            {/* Pagination End */}
          </div>
        </div>
        {/* Modal section */}

        {/* add modal */}
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-[80%] transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                    <div className=" mt-[10px] ml-[3%] mr-[3%]">
                      <div className="grid grid-cols-11">
                        <div className="col-span-11 py-4 flex justify-between">
                          <div className="text-black flex items-center text-sm tracking-wider font-bold">
                            Add Page{" "}
                          </div>
                          <div>
                            <AiOutlineClose
                              size={20}
                              className="cursor-pointer font-bold"
                              onClick={closeModal}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mb-10">
                        <div className=" mb-5">
                          <div className="border-[1.5px] border-gray-200 py-4 rounded-md">
                            {/* <div className="flex justify-between bg-grey pl-6 py-1 text-sm text-[#4A4A4A] font-semibold">
                              Add Category
                            </div> */}

                            <div className="flex py-5 px-4 space-x-6 items-center">
                              <div className="w-full">
                                <label className="text-sm font-semibold text-[#898989]">
                                  Page Name
                                </label>
                                <br />
                                <select
                                  value={seoData.pageName}
                                  onChange={handleAddChange}
                                  name="pageName"
                                  type="text"
                                  placeholder="Page Name"
                                  className="border-[1px] outline-none placeholder:text-sm  py-2.5 my-2 px-2 rounded-md  text-sm border-gray-300 w-full"
                                >
                                  <option value="">Select Page</option>
                                  {dropdownPages &&
                                    dropdownPages
                                      .filter(
                                        (page) =>
                                          !metaData
                                            .map((data) => {
                                              return data.pageName;
                                            })
                                            .includes(page.pageName)
                                      )
                                      .sort((a, b) =>
                                        a.pageName.localeCompare(b.pageName)
                                      )
                                      .map((item, index) => (
                                        <option value={item.pageName}>
                                          {item.pageName
                                            .split(" ")
                                            .map(
                                              (word) =>
                                                word.charAt(0).toUpperCase() +
                                                word.slice(1)
                                            )
                                            .join(" ")}
                                        </option>
                                      ))}
                                </select>
                              </div>
                              <div className="w-full">
                                <label className="text-sm font-semibold text-[#898989]">
                                  SEO Title
                                </label>
                                <br />
                                <input
                                  value={seoData.title}
                                  onChange={handleAddChange}
                                  name="title"
                                  type="text"
                                  placeholder="Title"
                                  className="border-[1px] outline-none placeholder:text-sm  py-2.5 my-2 px-2 rounded-md  text-sm border-gray-300 w-full"
                                />
                              </div>
                            </div>
                            <div className="flex py-5 px-4 space-x-6 items-center">
                              <div className="w-full">
                                <label className="text-sm font-semibold text-[#898989]">
                                  SEO Description
                                </label>
                                <br />
                                <textarea
                                  value={seoData.description}
                                  onChange={handleAddChange}
                                  name="description"
                                  type="text"
                                  placeholder="Description"
                                  className="border-[1px] outline-none placeholder:text-sm  py-2.5 my-2 px-2 rounded-md  text-sm border-gray-300 w-full"
                                />
                              </div>
                              <div className="w-full">
                                <label className="text-sm font-semibold text-[#898989]">
                                  SEO Keyword
                                </label>
                                <br />
                                <textarea
                                  value={seoData.keyword}
                                  onChange={handleAddChange}
                                  name="keyword"
                                  type="text"
                                  placeholder="Keyword"
                                  className="border-[1px] outline-none placeholder:text-sm  py-2.5 my-2 px-2 rounded-md  text-sm border-gray-300 w-full"
                                />
                              </div>
                            </div>
                          </div>

                          <button className="flex items-center justify-center pt-4 space-x-4 duration-300">
                            <p
                              onClick={handleAddPage}
                              className=" px-6 py-1 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                            >
                              Add Page
                            </p>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
        {/* Modal section */}
        {/* Modal Edit section */}
        <Transition appear show={edit} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeedit}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-[80%] transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                    <div className=" mt-[10px] ml-[3%] mr-[3%]">
                      <div className="grid grid-cols-11 ">
                        <div className="col-span-11 py-4 flex justify-between">
                          <div className="text-black flex items-center text-sm tracking-wider font-bold">
                            Update Page{" "}
                          </div>
                          <div>
                            <AiOutlineClose
                              size={20}
                              className="cursor-pointer font-bold"
                              onClick={closeedit}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mb-10">
                        <div className=" mb-5">
                          <div className="border-[1.5px] border-gray-200 py-4 rounded-md">
                            {/* <div className="flex justify-between bg-grey pl-6 py-1 text-sm text-[#4A4A4A] font-semibold">
                              Add Category
                            </div> */}

                            <div className="flex py-5 px-4 space-x-6 items-center">
                              <div className="w-full">
                                <label className="text-sm font-semibold text-[#898989]">
                                  Page Name
                                </label>
                                <br />
                                <input
                                  value={editData.pageName
                                    ?.split(" ")
                                    .map(
                                      (word) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1)
                                    )
                                    .join(" ")}
                                  onChange={handleEditChange}
                                  name="pageName"
                                  type="text"
                                  placeholder="Page Name"
                                  readOnly
                                  className="border-[1px] outline-none placeholder:text-sm  py-2.5 my-2 px-2 rounded-md  text-sm border-gray-300 w-full"
                                />
                              </div>
                              <div className="w-full">
                                <label className="text-sm font-semibold text-[#898989]">
                                  SEO Title
                                </label>
                                <br />
                                <input
                                  value={editData.title}
                                  onChange={handleEditChange}
                                  name="title"
                                  type="text"
                                  placeholder="Title"
                                  className="border-[1px] outline-none placeholder:text-sm  py-2.5 my-2 px-2 rounded-md  text-sm border-gray-300 w-full"
                                />
                              </div>
                            </div>
                            <div className="flex py-5 px-4 space-x-6 items-center">
                              <div className="w-full">
                                <label className="text-sm font-semibold text-[#898989]">
                                  SEO Description
                                </label>
                                <br />
                                <textarea
                                  value={editData.description}
                                  onChange={handleEditChange}
                                  name="description"
                                  type="text"
                                  placeholder="Description"
                                  className="border-[1px] outline-none placeholder:text-sm  py-2.5 my-2 px-2 rounded-md  text-sm border-gray-300 w-full"
                                />
                              </div>
                              <div className="w-full">
                                <label className="text-sm font-semibold text-[#898989]">
                                  SEO Keyword
                                </label>
                                <br />
                                <textarea
                                  value={editData.keyword}
                                  onChange={handleEditChange}
                                  name="keyword"
                                  type="text"
                                  placeholder="Keyword"
                                  className="border-[1px] outline-none placeholder:text-sm  py-2.5 my-2 px-2 rounded-md  text-sm border-gray-300 w-full"
                                />
                              </div>
                            </div>
                          </div>

                          <button
                            onClick={handleUpdatePage}
                            className="flex items-center justify-center pt-4 space-x-4 duration-300"
                          >
                            <p className=" px-6 py-1 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md ">
                              Update Page
                            </p>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>

        {/* delete modal */}
        <div
          className={`w-screen h-screen fixed top-0 left-0 bg-black bg-opacity-20 ${
            modalDelete ? "block duration-500" : "hidden duration-500"
          } ${closeDelete ? "hidden" : ""}`}
        >
          <div
            className={`text-sm absolute top-[50%] left-[50%]  w-[38%] translate-x-[-50%] h-auto  translate-y-[-50%] bg-[#fff] rounded-md shadow-lg pb-6 ${
              modalDelete ? "duration-500" : "duration-500"
            }`}
          >
            <div className="bg-primary w-full flex items-center justify-between px-6 py-4 rounded-tl-md rounded-tr-md text-white">
              <div className="text-sm font-bold">Delete</div>
              <AiOutlineClose
                size={20}
                onClick={closeModalDelete}
                className="cursor-pointer font-bold"
              />
            </div>

            <div className="text-center py-8">
              <h2 className="font-semibold text-lg">
                Are you sure you want to delete this item?
              </h2>
            </div>

            <div className="grid grid-cols-12 gap-5 px-6 py-8">
              <div className="col-span-6">
                <div className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white">
                  <MdOutlineDoneOutline className="text-2xl" />
                  <div
                    className="uppercase"
                    onClick={() => {
                      handleDeletePage(pageId);
                      closeModalDelete();
                    }}
                  >
                    <span>Confirm</span>
                  </div>
                </div>
              </div>

              <div className="col-span-6">
                <div
                  onClick={closeModalDelete}
                  className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white"
                >
                  <GiCancel className="text-2xl" />
                  <div class="uppercase">
                    <span>Cancel</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* delete modal */}
      </div>
    </>
  );
}

export default Pages;
