import React, { useState, useEffect } from "react";
import LeftSideBar from "../sidebar/LeftSideBar";
import { NavLink } from "react-router-dom";
import Header from "../sidebar/Header";
import { useParams } from "react-router-dom";
import {
  getPerformerById,
  updatePerformerStatus,
} from "../apiconfig/authService";
import { toast } from "react-toastify";

function PerformerDetail() {
  const [editState, setEditState] = useState(true);

  const [age, setAge] = useState("");

  const [state, setState] = useState({});
  //   performerId: "1234",
  //   performerName: "Shrimant",
  //   email: "abc@gmail.com",
  //   gender: "Male",
  //   screenAge: "24 Age",
  //   phone: "9867543234",
  //   aboutMe: "About Me",
  //   dateofBirth: "12 Jan 2000",
  //   actualAge: "22 Age",
  //   height: "23 inch",
  //   language: "Hindi",
  //   permanentAddress: "359, Sector 27, Gurgaon",
  //   currentAddress: "Gurgaon",
  //   instagram: "http/instagram.com",
  //   facebook: "http/facebook.com",
  //   twitter: "http/twitter.com",
  //   linkedln: "http/linkedln.com",
  //   createdAt: "Created",
  //   description: "I am a web designer",
  //   vision: "Vision",
  // });

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const id = useParams();
  const performerId = id.id;
  console.log(performerId);

  const getPerformerDetails = async () => {
    try {
      const response = await getPerformerById(performerId);
      console.log(response.data);
      setState(response.data);
      console.log("state", state);
    } catch (error) {
      console.log(error);
    }
    try {
      let currentYear = new Date();
      currentYear = currentYear.getFullYear();
      let dobYear = new Date(state?.[0]?.dob).toLocaleDateString();
      console.log(currentYear, dobYear);
      let currentAge = currentYear - dobYear;
      setAge(currentAge);
    } catch (error) {
      console.log(error);
    }
  };

  const handleApprove = async (e) => {
    e.preventDefault();
    const data = {
      id: state[0]._id,
      performerstatus: "Approved",
    };

    try {
      const response = await updatePerformerStatus(data);
      if (response.status == true) {
        toast(response.message);
      } else {
        toast(response.message);
      }
    } catch (error) {
      console.log(error);
    }
    console.log(data);
  };

  const handleDisapprove = async (e) => {
    e.preventDefault();
    const data = {
      id: state[0]._id,
      performerstatus: "Rejected",
    };

    try {
      const response = await updatePerformerStatus(data);
      if (response.status == true) {
        toast(response.message);
      } else {
        toast(response.message);
      }
    } catch (error) {
      console.log(error);
    }
    console.log(data);
  };

  useEffect(() => {
    getPerformerDetails();
  }, []);

  //   // age calculation
  //   var birthDate = new Date(state.dob).toLocaleDateString();
  //   console.log("dob" , birthDate);
  //   console.log(" birthDate"+ birthDate);

  //  // get difference from current date;
  //  var difference=Date.now() - birthDate.getTime();

  //  var  ageDate = new Date(difference);
  //  var calculatedAge=   Math.abs(ageDate.getUTCFullYear() - 1970);

  //  console.log("age" , calculatedAge);

  console.log("state", state);

  return (
    <>
      <Header />
      <div className="col12SwmDashBord">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>
        <div className="col8SemNvr mt-[70px] ml-[5%]">
          <div className="block w-full p-2 bg-white">
            {/* Brecrums */}
            <nav className="flex MngeBredcums px-2" aria-label="Breadcrumb">
              <ol class="inline-flex items-center space-x-1 md:space-x-3">
                <li>
                  <div class="flex items-center">
                    <NavLink
                      to="#"
                      class="ml-1 text-xs font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white"
                    >
                      {" "}
                      MANAGE USER{" "}
                    </NavLink>
                  </div>
                </li>
                <li aria-current="page">
                  <div class="flex items-center">
                    <svg
                      class="w-4 h-4 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <NavLink
                      to="/manage/performer"
                      class="ml-1  font-medium text-gray-500 md:ml-2 dark:text-gray-400 text-xs"
                    >
                      {" "}
                      Performer{" "}
                    </NavLink>
                  </div>
                </li>
                <li aria-current="page">
                  <div class="flex items-center">
                    <svg
                      class="w-4 h-4 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span class="ml-1  font-medium text-gray-500 md:ml-2 dark:text-gray-400 text-xs">
                      {" "}
                      Performer Detail{" "}
                    </span>
                  </div>
                </li>
              </ol>
            </nav>

            <div className="flex justify-between mt-2 mb-2 px-4">
              <h4 className="text-xl font-bold"> Performer Detail</h4>
              <div className="space-x-4">
                {/* <button
                  onClick={() => setEditState(!editState)}
                  className=" px-6 py-1 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                >
                  {editState ? "Edit" : "Save"}
                </button> */}
                <NavLink
                  to="/manage/performer"
                  className=" px-6 py-1.5 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                >
                  Back
                </NavLink>
              </div>
            </div>

            {/* End Brecrums */}

            <div className="block w-full  mt-2 bg-white ">
              <div className="p-4   block">
                <div class="py-3 grid grid-cols-2 gap-3 border-[1px] mb-0 w-full px-2">
                  <div class="w-full border  my-3 justify-between  flex items-center">
                    <label
                      class="block w-full border-r py-2  px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Performer Id
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <input
                        name="performerId"
                        disabled={editState}
                        onChange={handleChange}
                        value={state?.[0]?.performerId}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="w-full border  my-3 justify-between flex items-center">
                    <label
                      class="block w-full  px-3 border-r py-2  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Created at
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <input
                        name="createdAt"
                        onChange={handleChange}
                        disabled={editState}
                        value={new Date(
                          state?.[0]?.createdAt
                        ).toLocaleDateString("en-GB")}
                        class="appearance-none block w-full text-xs text-gray-700 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="w-full border  my-3 justify-between  flex items-center">
                    <label
                      class="block w-full border-r py-2  px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Performer Name
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <input
                        name="performerName"
                        disabled={editState}
                        onChange={handleChange}
                        value={state?.[0]?.name}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full border  my-3 justify-between  flex items-center">
                    <label
                      class="block w-full border-r py-2  px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Email Id
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <input
                        name="email"
                        disabled={editState}
                        onChange={handleChange}
                        value={state?.[0]?.email}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full border  my-3 justify-between  flex items-center">
                    <label
                      class="block w-full border-r py-2  px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Phone No.
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <input
                        name="phone"
                        disabled={editState}
                        onChange={handleChange}
                        value={state?.[0]?.phoneNumber}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  {/* <div class="w-full border  my-3 justify-between  flex items-center">
                    <label
                      class="block w-full border-r py-2  px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Age
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <input
                        name="screenAge"
                        disabled={editState}
                        onChange={handleChange}
                        value={state.screenAge}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div> */}

                  <div class="w-full border  my-3 justify-between flex items-center">
                    <label
                      class="block w-full border-r py-2  px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      DOB
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <input
                        name="dateofBirth"
                        disabled={editState}
                        onChange={handleChange}
                        value={new Date(state?.[0]?.dob).toLocaleDateString(
                          "en-GB"
                        )}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full border  my-3 justify-between  flex items-center">
                    <label
                      class="block w-full border-r py-2  px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Gender
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <input
                        name="gender"
                        disabled={editState}
                        onChange={handleChange}
                        value={state?.[0]?.gender}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full border  my-3 justify-between flex items-center">
                    <label
                      class="block w-full border-r py-2  px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Actual Age
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <input
                        name="actualAge"
                        disabled={editState}
                        handleChange={handleChange}
                        value={
                          new Date().getFullYear() -
                          new Date(state?.[0]?.dob).getFullYear()
                        }
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="w-full border  my-3 justify-between flex items-center">
                    <label
                      class="block w-full border-r py-2  px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Screen Age
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <input
                        name="screenAge"
                        disabled={editState}
                        handleChange={handleChange}
                        value={state?.[0]?.screenAge}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="w-full border  my-3 justify-between flex items-center">
                    <label
                      class="block w-full border-r py-2  px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Height
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <input
                        name="height"
                        disabled={editState}
                        onChange={handleChange}
                        value={state?.[0]?.height}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="w-full border my-3 justify-between flex items-center">
                    <label
                      class="block w-full border-r py-2  px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Language
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <input
                        name="language"
                        onChange={handleChange}
                        disabled={editState}
                        value={state?.[0]?.language.toString()}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="w-full border  my-3 justify-between flex items-center">
                    <label
                      class="block w-full border-r py-2  px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Permanent Address
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <textarea
                        name="permanentAddress"
                        onChange={handleChange}
                        disabled={editState}
                        value={
                          state?.[0]?.permanentAddress?.address +
                          ", " +
                          state?.[0]?.permanentAddress?.city +
                          ", " +
                          state?.[0]?.permanentAddress?.state +
                          ", " +
                          state?.[0]?.currentAddress?.country +
                          ", " +
                          +state?.[0]?.permanentAddress?.pinCode
                        }
                        // value={JSON.stringify(state?.[0]?.permanentAddress)?.replace(/[^\w\s]/gi, '  ')}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="w-full border  my-3 justify-between flex items-center">
                    <label
                      class="block w-full border-r py-2  px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      CurrentAddress
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <textarea
                        name="currentAddress"
                        onChange={handleChange}
                        disabled={editState}
                        value={
                          state?.[0]?.currentAddress?.address +
                          ", " +
                          state?.[0]?.currentAddress?.city +
                          ", " +
                          state?.[0]?.currentAddress?.state +
                          ", " +
                          state?.[0]?.currentAddress?.country +
                          ", " +
                          state?.[0]?.currentAddress?.pinCode
                        }
                        // value={JSON.stringify(state?.[0]?.currentAddress)?.replace(/[^\w\s]/gi, '  ')}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="col-span-2  w-full border my-3 justify-between flex items-center">
                    <label
                      class=" block w-52 border-r py-2  px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      About me
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <input
                        name="aboutMe"
                        onChange={handleChange}
                        disabled={editState}
                        value={state?.[0]?.bio}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="w-full border  my-3 justify-between flex items-center">
                    <label
                      class="block w-full border-r py-2  px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Instagram
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <input
                        name="instagram"
                        onChange={handleChange}
                        disabled={editState}
                        value={state?.[0]?.instagramLink}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="w-full border  my-3 justify-between flex items-center">
                    <label
                      class="block w-full border-r py-2  px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Facebook
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <input
                        name="facebook"
                        onChange={handleChange}
                        disabled={editState}
                        value={state?.[0]?.facebookLink}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="w-full border  my-3 justify-between flex items-center">
                    <label
                      class="block w-full border-r py-2  px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Twitter
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <input
                        name="twitter"
                        onChange={handleChange}
                        disabled={editState}
                        value={state?.[0]?.twitterLink}
                        class="appearance-none block w-full text-xs text-gray-700 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="w-full border  my-3 justify-between flex items-center">
                    <label
                      class="block w-full  px-3  md:mb-0 border-r py-2  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      LinkedIn
                    </label>
                    <div className="w-full md:w-[80%] px-3">
                      <input
                        name="linkedln"
                        onChange={handleChange}
                        disabled={editState}
                        value={state?.[0]?.linkedinLink}
                        class="appearance-none block w-full text-xs text-gray-700 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <a
                    href={`https://www.milestone101.com/${state?.[0]?.performerId}`}
                    target="_blank" rel="noreferrer"
                  >
                    <button className="w-52 px-4 py-1 border-[1px] border-primary text-white hover:bg-white bg-primary hover:text-primary rounded-md ">
                      View Resume
                    </button>
                  </a>

                  <div className="flex items-center justify-end space-x-4 mb-4 duration-300">
                    <button
                      type="button"
                      onClick={handleApprove}
                      className=" px-14 py-1 border-[1px] border-primary text-white hover:bg-white bg-primary hover:text-primary rounded-md "
                    >
                      APPROVED
                    </button>
                    <button
                      type="button"
                      onClick={handleDisapprove}
                      className=" px-14 py-1 border-[1px] border-primary text-white hover:bg-white bg-primary hover:text-primary rounded-md "
                      >
                      DISAPPROVE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PerformerDetail;
