import React, { useState, useEffect } from "react";
import LeftSideBar from "../sidebar/LeftSideBar";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../sidebar/Header";
import { MdDelete, MdOutlineDoneOutline } from "react-icons/md";
import { HiEye } from "react-icons/hi";
import { Country, State, City } from "country-state-city";
import { AddTopPerFormerDta } from "../apiconfig/authService";
import { toast } from "react-toastify";
function HomePerformer() {
  const [LargeImg, setLargeImg] = useState();
  const [upload1, setUpload1] = useState();
  function handleUpload1(e) {
    console.log(e.target.files);
    setLargeImg(e.target.files[0]);
    setUpload1(URL.createObjectURL(e.target.files[0]));
  }

  const navigate = useNavigate();

  const [Bneerstate, setHomeBnner] = useState({
    name: "",
    designation: "",
    location: "",
    description: "",
    profileLink: "",
  });

  const handleChange = (e) => {
    setHomeBnner({ ...Bneerstate, [e.target.name]: e.target.value });
  };

  var data = new FormData();
  data.append("name", Bneerstate.name);
  data.append("designation", Bneerstate.designation);
  data.append("location", Bneerstate.location);
  data.append("description", Bneerstate.description);
  data.append("profileLink", Bneerstate.profileLink);
  data.append("file", LargeImg);

  const PostPerformerData = async () => {
    try {
      if (
        !Bneerstate.name ||
        !Bneerstate.designation ||
        !Bneerstate.description ||
        !LargeImg
      ) {
        return toast("Please Fill Proper Details");
      } else {
        const response = await AddTopPerFormerDta(data);
        if (response.code === 200) {
          toast(response.message);
          navigate("/cms/top-performer-listing");
        } else {
          toast(response.message);
        }
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Header />
      <div className="col12SwmDashBord">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>
        <div className="col8SemNvr mt-[70px] ml-[5%]">
          <div className="block w-full p-2 bg-white">
            {/* Brecrums */}
            <div className="flex justify-between mt-2 mb-2 px-4">
              <h4 className="text-xl font-bold">Add Top Performer</h4>
              <div className="space-x-4">
                <button
                  onClick={PostPerformerData}
                  className=" px-6 py-1 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                >
                  Save
                </button>
                <NavLink
                  to="/cms/top-performer-listing"
                  className=" px-6 py-1.5 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                >
                  Back
                </NavLink>
              </div>
            </div>

            {/* End Brecrums */}

            <div className="block w-full bg-white ">
              <div className="p-4 block">
                <div class="py-3  gap-3 border-[1px] mb-0 w-full px-2 ">
                  <div class="w-full  my-3">
                    <label
                      class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                      for="grid-first-name"
                    >
                      Name
                    </label>
                    <div className="">
                      <input
                        class="appearance-none block w-full text-xs border text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                        name="name"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div class="w-full  my-3">
                    <label
                      class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                      for="grid-first-name"
                    >
                      Designation
                    </label>
                    <div className="">
                      <input
                        class="appearance-none block w-full text-xs border text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                        name="designation"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div class="w-full  my-3">
                    <label
                      class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                      for="grid-first-name"
                    >
                      Location
                    </label>
                    <div className="">
                      <input
                        class="appearance-none block w-full text-xs border text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                        name="location"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div class="w-full  my-3">
                    <label
                      class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                      for="grid-first-name"
                    >
                      Profile Link
                    </label>
                    <div className="">
                      <input
                        class="appearance-none block w-full text-xs border text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                        name="profileLink"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div class="w-full  my-3">
                    <label
                      class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                      for="grid-first-name"
                    >
                      Description
                    </label>
                    <div className="">
                      <input
                        class="appearance-none block w-full text-xs border text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                        name="description"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div class="w-full  my-3">
                    <label
                      class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                      for="grid-first-name"
                    >
                      Upload Image
                    </label>
                    <div className="">
                      <input
                        onChange={handleUpload1}
                        class="appearance-none block w-full text-xs border text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        type="file"
                        name="file"
                      />
                    </div>
                    <div id="banner1" className="my-3 hidden">
                      <img className="w-32 h-32" src={upload1} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePerformer;
