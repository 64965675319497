import React from "react";
import { AiOutlineRight } from "react-icons/ai";
import { MdEventNote } from "react-icons/md";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { useEffect, useState } from "react";
import { BsCheck } from "react-icons/bs";
import { AiFillCamera } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { AiOutlinePlus } from "react-icons/ai";
import Select from "react-select";

import { GrFormUpload } from "react-icons/gr";
import Header from "../sidebar/Header";
import LeftSideBar from "../sidebar/LeftSideBar";
import { NavLink, useNavigate } from "react-router-dom";
import { addSubcriptionsData } from "../apiconfig/authService";
import { toast } from "react-toastify";

const CreateFeature = () => {
  const navigate = useNavigate();

  const [subscription, setSubscription] = useState({
    type: "",
    Price: "",
    Discription: "",
  });

  const [isSubscribed, setIsSubscribed] = useState(true);

  const handleChangeCheckbox = (event) => {
    if (event.target.checked) {
      console.log("✅ Checkbox is checked");
    } else {
      console.log("⛔️ Checkbox is NOT checked");
    }
    setIsSubscribed((current) => !current);
  };

  console.log("Paid Free Course", subscription);

  const handleChange = (e) => {
    setSubscription({ ...subscription, [e.target.name]: e.target.value });
  };

  const SubmitSubcription = async () => {
    if (!subscription.type || !subscription.Discription) {
      toast("Invalid data.");
    } else {
      try {
        const response = await addSubcriptionsData({
          isAvailableInPaid: isSubscribed,
          type: subscription.type,
          Price: subscription.Price,
          Discription: subscription.Discription,
        });

        if (response.code == 200) {
          toast(response.message);
          navigate("/subscription/add-subscription");
        } else {
          toast(response.message);
        }

        console.log(response);
      } catch (error) {}
    }
  };

  return (
    <div>
      <Header />
      <div className="col12SwmDashBord bg-white">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>
        <div className="col8SemNvr mt-[70px] ml-[5%]">
          <div className="grid grid-cols-11">
            <div className="col-span-9 py-4">
              <div className="text-black flex items-center text-sm tracking-wider font-bold">
                Subscription{" "}
                <span className="space-x-1 flex items-center text-secondary">
                  <AiOutlineRight />
                  Add Subscription
                </span>
                <span className="space-x-1 flex items-center text-secondary">
                  <AiOutlineRight />
                  Create Feature
                </span>
              </div>
            </div>
          </div>
          {/* Tabs are Here  */}

          {/* Start Complete Form */}
          <div className="border-[1.5px] border-gray-200 py-4 rounded-md">
            <div className="bg-grey pl-6 py-1 text-sm text-[#4A4A4A] font-semibold">
              Create Item
            </div>

            <div className="flex py-5 px-6 space-x-6 items-center">
              <div className="w-full">
                <label className="text-sm font-semibold text-[#898989]">
                  Payment Type
                </label>
                <br />

                <select
                  id="countries"
                  name="type"
                  onChange={handleChange}
                  class=" border border-gray-300 text-gray-900 text-sm rounded-[5px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option selected>Select Payment Type</option>
                  <option value="Monthly">Monthly</option>
                  <option value="Quarterly">Quarterly</option>
                  <option value="Yearly">Yearly</option>
                </select>
              </div>
            </div>

            <div className="px-6">
              <div>
                <label className="text-sm font-semibold text-[#898989]">
                  Description
                </label>
                <br />
                <textarea
                  name="Discription"
                  onChange={handleChange}
                  className="border-[1px] placeholder:text-xs  py-[.6rem] px-2 rounded-md  text-xs border-gray-300 w-full outline-none"
                  rows="6"
                />
              </div>
            </div>

            <div className="px-6 mt-2">
              <div>
                <label className="text-sm font-semibold text-[#898989]">
                  is Available In
                </label>
                <br />
                <div className="flex mt-2">
                  <div class="flex items-center mr-2">
                    <input
                      id="default-radio-1"
                      type="radio"
                      value={isSubscribed}
                      checked={isSubscribed}
                      name="isAvailableInPaid"
                      onChange={handleChangeCheckbox}
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="default-radio-1"
                      class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Paid{" "}
                    </label>
                  </div>
                  <div class="flex items-center">
                    <input
                      id="default-radio-2"
                      type="radio"
                      value={isSubscribed}
                      name="isAvailableInPaid"
                      onChange={handleChangeCheckbox}
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="default-radio-2"
                      class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Free
                    </label>
                  </div>
                </div>
                {isSubscribed === true ? (
                  <div className=" w-full mt-2">
                    <label className="text-sm font-semibold text-[#898989]">
                      Price
                    </label>
                    <br />
                    <input
                      type="text"
                      name="Price"
                      placeholder="Enter price"
                      onChange={handleChange}
                      className="border-[1px] placeholder:text-xs  py-[.6rem] px-2 rounded-md  text-xs border-gray-300 w-full"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          {/* Cancel & Save Btn */}
          <div className="flex items-center justify-center pt-4 space-x-4 duration-300">
            <NavLink
              to="/subscription/add-subscription"
              className=" px-14 py-1 border-[1px] border-primary text-primary hover:bg-primary hover:text-white rounded-md "
            >
              CANCEL
            </NavLink>
            <button
              onClick={SubmitSubcription}
              className=" px-14 py-1 border-[1px] border-primary text-white hover:bg-white bg-primary hover:text-primary rounded-md "
            >
              CREATE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateFeature;
