import React, { useState, useEffect } from "react";
import LeftSideBar from "../sidebar/LeftSideBar";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../sidebar/Header";
import Select from "react-select";
import { MdDelete, MdOutlineDoneOutline } from "react-icons/md";
import { HiEye } from "react-icons/hi";
import { Country, State, City } from "country-state-city";
import { HomePagePostData } from "../apiconfig/authService";
import { toast } from "react-toastify";
function HomeVideos() {
  const [LargeImg, setLargeImg] = useState();
  const [upload1, setUpload1] = useState();
  function handleUpload1(e) {
    console.log(e.target.files);
    setLargeImg(e.target.files[0]);
    setUpload1(URL.createObjectURL(e.target.files[0]));
  }

  const navigate = useNavigate();

  const [Bneerstate, setHomeBnner] = useState({
    title: "",
    videoUrl: "",
  });

  const handleChange = (e) => {
    setHomeBnner({ ...Bneerstate, [e.target.name]: e.target.value });
  };

  var data = new FormData();
  data.append("title", Bneerstate.title);
  data.append("videoUrl", Bneerstate.videoUrl);
  data.append("file", LargeImg);

  const PostHomePageVideoData = async () => {
    try {
      if (!Bneerstate.title || !Bneerstate.videoUrl || !LargeImg) {
        return toast("Please Fill Proper Details");
      } else {
        const response = await HomePagePostData(data);
        if (response.code === 200) {
          toast(response.message);
          navigate("/cms/top-videos-listing");
        } else {
          toast(response.message);
        }
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Header />
      <div className="col12SwmDashBord">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>
        <div className="col8SemNvr mt-[70px] ml-[5%]">
          <div className="block w-full p-2 bg-white">
            {/* Brecrums */}
            <div className="flex justify-between mt-2 mb-2 px-4">
              <h4 className="text-xl font-bold">Add Top Videos</h4>
              <div className="space-x-4">
                <button
                  onClick={PostHomePageVideoData}
                  className=" px-6 py-1 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                >
                  Save
                </button>
                <NavLink
                  to="/cms/top-videos-listing"
                  className=" px-6 py-1.5 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                >
                  Back
                </NavLink>
              </div>
            </div>

            {/* End Brecrums */}

            <div className="block w-full bg-white ">
              <div className="p-4 block">
                <div class="py-3  gap-3 border-[1px] mb-0 w-full px-2 ">
                  <div class="w-full  my-3">
                    <label
                      class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                      for="grid-first-name"
                    >
                      Title
                    </label>
                    <div className="">
                      <input
                        class="appearance-none block w-full text-xs border text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                        name="title"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div class="w-full  my-3">
                    <label
                      class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                      for="grid-first-name"
                    >
                      Upload Thumbnail
                    </label>
                    <div className="">
                      <input
                        onChange={handleUpload1}
                        class="appearance-none block w-full text-xs border text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        type="file"
                        name="file"
                      />
                    </div>
                    <div id="banner1" className="my-3 hidden">
                      <img className="w-32 h-32" src={upload1} alt="" />
                    </div>
                  </div>

                  <div class="w-full  my-3">
                    <label
                      class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                      for="grid-first-name"
                    >
                      Video Url
                    </label>
                    <div className="">
                      <input
                        class="appearance-none block w-full text-xs border text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                        name="videoUrl"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeVideos;
