import React, { useState, useEffect, useRef } from "react";
import LeftSideBar from "../sidebar/LeftSideBar";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../sidebar/Header";
import Select from "react-select";
import { Country, State, City } from "country-state-city";
import JoditEditor from "jodit-react";
import { toast } from "react-toastify";
import { getAllBlogCategory, BlogPostData } from "../apiconfig/authService";

function CreatePost() {
  const editor = useRef(null);
  const [content, setContent] = useState("");

  const navigate = useNavigate();
  const [smallImg, setSmallImg] = useState();
  const [LargeImg, setLargeImg] = useState();
  const [categoryDataShow, setCatgoryData] = useState([]);
  const [isActive, setIsActive] = useState("false"); // New state for true/false value
  const [blogState, setBlogSate] = useState({
    categoryId: "",
    title: "",
    shortDescription: "",
    longDescription: "",
    author: "",
    type: "",
    metaTitle: "",
    metaDescription: "",
    metaKeyword: "",
  });

  console.log(smallImg);

  const handleChange = (e) => {
    setBlogSate({ ...blogState, [e.target.name]: e.target.value });
  };

  console.log("Post Name", blogState);

  const [upload, setUpload] = useState();
  function handleUpload(e) {
    console.log(e.target.files);
    setSmallImg(e.target.files[0]);
    setUpload(URL.createObjectURL(e.target.files[0]));
  }

  const [upload1, setUpload1] = useState();
  function handleUpload1(e) {
    console.log(e.target.files);
    setLargeImg(e.target.files[0]);
    setUpload1(URL.createObjectURL(e.target.files[0]));
  }

  const blogCategoryData = async () => {
    try {
      const response = await getAllBlogCategory();

      console.log("Post Data Show", response);
      setCatgoryData(response.data);
    } catch (error) {
      console.log(error);
    }
  };
// xpecto developer 03/09/2024
  var data = new FormData();
  data.append("categoryId", blogState.categoryId);
  data.append("type", blogState.type);
  data.append("title", blogState.title);
  data.append("shortDescription", blogState.shortDescription);
  data.append("longDescription", content);
  data.append("author", blogState.author);
  data.append("smallImage", smallImg);
  data.append("largeImage", LargeImg);
  data.append("seoTitle", blogState.metaTitle);
  data.append("seoDescription", blogState.metaDescription);
  data.append("seoKeyword", blogState.metaKeyword);
  data.append("isactive", isActive); // Add this line
  const PostBlogData = async () => {
    try {
      if (
        !blogState.categoryId ||
        !blogState.title ||
        !blogState.shortDescription ||
        // !blogState.longDescription ||
        !blogState.author ||
        !smallImg ||
        !LargeImg
      ) {
        return toast("Please Fill Proper Data");
      } else {
        const response = await BlogPostData(data);
        if (response.code === 200) {
          toast(response.message);
          navigate("/cms/latest-updates");
        } else {
          toast(response.message);
        }
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    blogCategoryData();
  }, []);

  return (
    <>
      <Header />
      <div className="col12SwmDashBord">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>
        <div className="col8SemNvr mt-[70px] ml-[5%]">
          <div className="block w-full p-2 bg-white">
            {/* Brecrums */}
            <nav class="flex MngeBredcums px-4" aria-label="Breadcrumb">
              <ol class="inline-flex items-center space-x-1 md:space-x-3">
                <li>
                  <div class="flex items-center">
                    <NavLink
                      to="#"
                      class="ml-1 text-xs font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white"
                    >
                      {" "}
                      Latest Updates{" "}
                    </NavLink>
                  </div>
                </li>
                <li aria-current="page">
                  <div class="flex items-center">
                    <svg
                      class="w-4 h-4 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span class="ml-1  font-medium text-gray-500 md:ml-2 dark:text-gray-400 text-xs">
                      {" "}
                      Create Post{" "}
                    </span>
                  </div>
                </li>
              </ol>
            </nav>

            <div className="flex justify-between mt-2 mb-2 px-4">
              <h4 className="text-xl font-bold"> Create Post</h4>
              <div className="space-x-4">
                <button
                  onClick={PostBlogData}
                  className=" px-6 py-1 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                >
                  Save
                </button>
                <NavLink
                  to="/cms/latest-updates"
                  className=" px-6 py-1.5 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                >
                  Back
                </NavLink>
              </div>
            </div>

            {/* End Brecrums */}

            <div className="block w-full bg-white ">
              <div className="p-4 block">
                <div class="py-3  gap-3 border-[1px] mb-0 w-full px-2 ">
                  <div class="w-full  my-3">
                    <div>
                      <label
                        class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                        for="grid-first-name"
                      >
                        Select Category{" "}
                        <small className="text-red-500 text-xs"> * </small>
                      </label>
                      <select
                        name="categoryId"
                        onChange={handleChange}
                        value={blogState.categoryId}
                        class="appearance-none block w-full text-xs border text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                      >
                        <option>Select Category</option>
                        {categoryDataShow &&
                          categoryDataShow.map((catdata, index) => (
                            <option value={catdata._id}>{catdata.name}</option>
                          ))}
                      </select>
                    </div>
                    <div>
                      <label
                        class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                        for="grid-first-name"
                      >
                        Select Type{" "}
                        <small className="text-red-500 text-xs"> * </small>
                      </label>
                      <select
                        name="type"
                        onChange={handleChange}
                        value={blogState.type}
                        class="appearance-none block w-full text-xs border text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                      >
                        <option value="">Select type</option>
                        <option value={"top"}>top</option>
                        <option value={"left"}>left</option>
                        <option value={"right"}>right</option>
                      </select>
                    </div>

                    <label
                      class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                      for="grid-first-name"
                    >
                      Title
                      <small className="text-red-500 text-xs"> * </small>
                    </label>
                    <div className="w-full">
                      <input
                        name="title"
                        value={blogState.title}
                        onChange={handleChange}
                        placeholder="Title"
                        class="appearance-none block w-full text-xs border text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="w-full">
                    <label
                      class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                      for="grid-first-name"
                    >
                      Author name
                      <small className="text-red-500 text-xs"> * </small>
                    </label>
                    <div className="w-full">
                      <input
                        name="author"
                        value={blogState.author}
                        onChange={handleChange}
                        placeholder="Author Name"
                        class="appearance-none block w-full text-xs border text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="w-full mt-4">
                    <label
                      className="block w-full py-2 px-3 md:mb-0 tracking-wide text-black-700 text-base font-bold mb-2"
                      htmlFor="boolean-dropdown"
                    >
                      Is Active
                      <small className="text-red-500 text-xs">(Optional)</small>
                    </label>
                    <select
                      name="dropdownValue"
                      onChange={(e) => setIsActive(e.target.value)}
                      value={isActive}
                      className="appearance-none block w-full text-xs border text-gray-700 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                    >
                      <option value="false">False</option>
                      <option value="true">True</option>
                    </select>
                  </div>

                  <div className="w-full">
                    <label
                      class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                      for="grid-first-name"
                    >
                      Short Description
                      <small className="text-red-500 text-xs"> * </small>
                    </label>
                    <div className="w-full">
                      <input
                        name="shortDescription"
                        value={blogState.shortDescription}
                        onChange={handleChange}
                        placeholder="Short Description"
                        class="appearance-none block w-full text-xs border text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                      />
                    </div>
                  </div>
                          
                  <div className="w-full">
                    <label
                      class="block w-full py-2 px-3 mt-3 md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                      for="grid-first-name"
                    >
                      Long Description
                      <small className="text-red-500 text-xs"> * </small>
                    </label>
                    <div class="w-full border px-3 justify-between  flex items-center">
                      <div className="w-full">
                        {/* <textarea
                          placeholder="Long Description................"
                          name="longDescription"
                          value={blogState.longDescription}
                          onChange={handleChange}
                          class="appearance-none block w-full text-xs text-gray-700 h-48  rounded p-2 leading-tight focus:outline-none focus:bg-white"
                          id="grid-first-name"
                          type="text"
                        /> */}
                        <JoditEditor
                          ref={editor}
                          value={content}
                          tabIndex={1} // tabIndex of textarea
                          onChange={(newContent) => setContent(newContent)}
                        />{" "}
                      </div>
                    </div>
                  </div>

                  <div class="w-full  my-3">
                    <label
                      class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                      for="grid-first-name"
                    >
                      Small Image{" "}
                      <small className="text-red-500 text-xs"> * </small>
                      <small className="text-xs"> (600 X 400) </small>
                    </label>
                    <div className="">
                      <input
                        onChange={handleUpload}
                        class="appearance-none block w-full text-xs border text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        type="file"
                      />
                    </div>
                    <div className="my-3">
                      <img className="w-[200px] " src={upload} alt="" />
                    </div>
                  </div>

                  <div class="w-full  my-3">
                    <label
                      class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                      for="grid-first-name"
                    >
                      Large Image{" "}
                      <small className="text-red-500 text-xs"> * </small>
                      <small className="text-xs"> (1360 X 420) </small>
                    </label>
                    <div className="">
                      <input
                        onChange={handleUpload1}
                        class="appearance-none block w-full text-xs border text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        type="file"
                      />
                    </div>
                    <div className="my-3">
                      <img className="w-[200px] " src={upload1} alt="" />
                    </div>
                  </div>

                  {/* seo details form */}
                  <div className="w-full my-3">
                    <label
                      class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                      for="grid-first-name"
                    >
                      SEO Title
                    </label>
                    <div className="w-full">
                      <input
                        name="metaTitle"
                        value={blogState.metaTitle}
                        onChange={handleChange}
                        placeholder="SEO Title"
                        class="appearance-none block w-full text-xs border text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="w-full my-3">
                    <label
                      class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                      for="grid-first-name"
                    >
                      SEO Description
                    </label>
                    <div className="w-full">
                      <textarea
                        name="metaDescription"
                        value={blogState.metaDescription}
                        onChange={handleChange}
                        placeholder="SEO Description"
                        class="appearance-none block w-full text-xs border text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                        rows={4}
                      />
                    </div>
                  </div>
                  <div className="w-full my-3">
                    <label
                      class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                      for="grid-first-name"
                    >
                      SEO Keyword
                    </label>
                    <div className="w-full">
                      <input
                        name="metaKeyword"
                        value={blogState.metaKeyword}
                        onChange={handleChange}
                        placeholder="SEO Keyword"
                        class="appearance-none block w-full text-xs border text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreatePost;
