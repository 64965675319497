import React, { useState, useEffect, Fragment } from "react";
import LeftSideBar from "../sidebar/LeftSideBar";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { HiEye } from "react-icons/hi";
import { MdDelete, MdOutlineDoneOutline } from "react-icons/md";
import { FaHandHoldingUsd } from "react-icons/fa";
import { NavLink, Link } from "react-router-dom";

import Header from "../sidebar/Header";
import { GiCancel } from "react-icons/gi";
import Select from "react-select";
import { getAllPartner, DeletePartnerData } from "../apiconfig/authService";
import { toast } from "react-toastify";
import { Pagination } from "rsuite";
import 'rsuite/dist/rsuite.min.css';
export default function Partners() {
  //   Modal
  const [modal, setModal] = useState(false);
  const [close, setClose] = useState(false);
  const [partnerData, setPartnerData] = useState([]);
  const [deleteCatgryId, setDeleteCatgryId] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [refetchData, setRefetchData] = useState(false);

  const openModal = () => {
    setModal(true);
    setClose(false);
  };

  const closeModal = () => {
    setClose(true);
    setModal(false);
  };
  // Modal

  const PartnerAllData = async () => {
    const response = await getAllPartner(pageSize === "All" ? totalCount : pageSize, pageSize === "All" ? 1 : pageNo);
    setPartnerData(response.data);
    setTotalCount(response.pagination.totalCount);
    setTotalPages(Math.ceil(response.pagination.totalPages));
  };

  const DeleteCatgry = async () => {
    try {
      const response = await DeletePartnerData({
        id: deleteCatgryId,
      });
      toast(response.message);
      PartnerAllData();
    } catch (error) {
      toast(error.response.data.message);
    }
  };

  useEffect(() => {
    PartnerAllData();
  }, [pageNo, refetchData,]);
  const handlePageSizeChange = (e) => {
    setPageSize(e.target.value);
    setPageNo(1);
    setRefetchData(!refetchData);
  };
  return (
    <>
      <Header />
      <div className="col12SwmDashBord bg-white ">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>
        <div className="col8SemNvr mt-[70px] ml-[5%]">
          {/* Bredcrums */}
          <div className="grid grid-cols-12">
            <div className="col-span-10 ">
              <div className="HdingSwnvr my-3 px-5">
                <h5> Partners </h5>
              </div>
            </div>
          </div>
          <hr className="w-full bg-gray-500 h-[2px]" />
          {/* Bredcrums End */}

          <div className="flex justify-between items-end mb-5">
            <div className="hover:shadow">
              <NavLink to="/cms/create-partner">
                <div className="flex cursor-pointer hover:shadow items-center border-[1px] px-6 space-x-1 py-2 rounded-md shadow-md">
                  <AiOutlinePlus />
                  <span>Create Partner</span>
                </div>
              </NavLink>
            </div>
          </div>
          <div className="flex col12WdthSwhnMn mt-2">
          <div className="flex">
            <label>Show </label>
            <div class="flex mt-[-2px] mr-2 ml-2 items-center ServeCtgrySwn">
              <div class="relative w-full">
                <select
                  className="bg-gray-50 py-1 border border-gray-300 text-gray-500 text-sm rounded-sm  block w-full px-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                  value={pageSize}
                  onChange={handlePageSizeChange}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={250}>250</option>
                  <option value={500}>500</option>
                  <option value="All">All</option>
                </select>
              </div>
            </div>
            <label> records per page. </label>
          </div>
            <div class=" relative  shadow-md sm:rounded-lg  mt-5">
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
                <thead class="text-sm text-gray-700 uppercase bg-orange-100	 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" class="py-4 px-4">
                      S No.
                    </th>
                    <th scope="col" class="py-4 px-4">
                      Image
                    </th>
                    <th scope="col" class="py-4 px-4">
                      Name
                    </th>

                    <th scope="col" class="py-4 px-4">
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody className="TableService">
                  {partnerData.map((item, index) => (
                    <tr
                      class="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                      key={index}
                    >
                      <th
                        scope="row"
                        class="py-3 px-4 font-medium text-sm text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {index + 1}
                      </th>
                      <th
                        scope="row"
                        class="py-3 px-4 font-medium text-sm text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        <img
                          className="w-32 h-32"
                          src={item.imageUrl}
                          alt="Logo"
                        />
                      </th>
                      <td class="py-3 text-sm">{item.title}</td>
                      {/* <td class="py-3 px-4 text-sm">
                                                {item.email}
                                            </td> */}

                      <td class="py-3 text-sm">
                        <div className="flex gap-5">
                          <NavLink to={`/cms/view-partners/${item._id}`}>
                            <HiEye
                              className="bg-[#0a65cc] text-white p-2"
                              size={32}
                            />
                          </NavLink>
                          <MdDelete
                            // onClick={() => DeleteCatgry(item._id)}
                            onClick={(e) => {
                              openModal();
                              setDeleteCatgryId(item._id);
                            }}
                            className="bg-red-600 text-white p-2"
                            size={32}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className=" pb-[20px] pl-[20px] pr-[20px] w-full flex justify-between mt-3">
            {totalCount === 0 ? <div>Showing 0 - 0 entries of 0 entries. </div> : pageSize === "All" ? (<div>Showing {1} - {totalCount} entries of {totalCount} entries.</div>) :
              (<div>Showing {(pageNo - 1) * pageSize + 1} - {totalCount < (pageSize * pageNo) ? totalCount : pageSize * pageNo} entries of {totalCount} entries.</div>)}

            {/* Pagination Start */}
            <div style={{ margin: "100" }}>
              <Pagination
                prev
                last
                next
                first
                size='sm'
                ellipsis
                boundaryLinks
                onSelect={setPageNo}
                limit={pageSize ==="ALL" ? totalCount : pageSize}
                onChangeLimit={setPageSize}
                layout={['pager', 'skip']}
                pages={totalPages}
                total={totalCount}
                maxButtons={5}
                activePage={pageNo}
                onChangePage={(page) => { setPageNo(page) }}
              />
            </div>
            {/* Pagination End */}
          </div>
        </div>
        {/* Modal section */}
        <div
          className={`w-screen h-screen fixed top-0 left-0 bg-black bg-opacity-20 ${modal ? "block duration-500" : "hidden duration-500"
            } ${close ? "hidden" : ""}`}
        >
          <div
            className={`text-sm absolute top-[50%] left-[50%]  w-[38%] translate-x-[-50%] h-auto  translate-y-[-50%] bg-[#fff] rounded-md shadow-lg pb-6 ${modal ? "duration-500" : "duration-500"
              }`}
          >
            <div className="bg-primary w-full flex items-center justify-between px-6 py-4 rounded-tl-md rounded-tr-md text-white">
              <div className="text-sm font-bold">Delete</div>
              <AiOutlineClose
                size={20}
                onClick={closeModal}
                className="cursor-pointer font-bold"
              />
            </div>

            <div className="text-center py-8">
              <h2 className="font-semibold text-lg">
                Are you sure you want to delete this item?
              </h2>
            </div>

            <div className="grid grid-cols-12 gap-5 px-6 py-8">
              <div className="col-span-6">
                <div className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white">
                  <MdOutlineDoneOutline className="text-2xl" />
                  <div
                    class="uppercase"
                    onClick={(e) => {
                      DeleteCatgry();
                      closeModal();
                    }}
                  >
                    <span>Confirm</span>
                  </div>
                </div>
              </div>

              <div className="col-span-6">
                <div
                  onClick={closeModal}
                  className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white"
                >
                  <GiCancel className="text-2xl" />
                  <div class="uppercase">
                    <span>Cancel</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal section */}
      </div>
    </>
  );
}
