import React, { useState } from "react";
import Header from "../sidebar/Header";
import LeftSideBar from "../sidebar/LeftSideBar";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { BiEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import {
  DeleteDirectoryCategory,
  GetperDirectiveCategory,
  postDirectiveCategory,
  UpdateDirectiveCategory,
} from "../apiconfig/authService";
import { useEffect } from "react";
import { toast } from "react-toastify";

const AddProductionTypePerformer = () => {
  const [directoryCat, setDirectorycat] = useState("");
  const [editDirective, setEditDirective] = useState();

  //Post DirectioryCategory
  const handleDirectoryCategory = async () => {
    const data = { name: directoryCat, description: "d" };
    try {
      const response = await postDirectiveCategory(data);
      if (response.status == true) {
        toast(response.message);
      } else {
        toast(response.message);
      }
    } catch (error) {}
    closeModal();
  };
  //Post DirectioryCategory

  //Get Directory Category
  const [data, setData] = useState([]);
  const GetDirectory = async () => {
    try {
      const response = await GetperDirectiveCategory();
      setData(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    GetDirectory();
  }, [directoryCat]);
  //Get Directory Category

  //Delete DirectoryCategory By Id
  const handleDeleteDirective = async (id) => {
    try {
      const response = await DeleteDirectoryCategory({
        id: id,
      });
      GetDirectory();
    } catch (error) {}
  };
  //Delete DirectoryCategory By Id

  //Update Directive Category
  const UpdateDirective = async () => {
    const data = {
      id: editDirective._id,
      name: editDirective.name,
      description: "d",
    };
    try {
      const response = await UpdateDirectiveCategory(data);
      GetDirectory();
    } catch (error) {}
    closeedit();
  };
  //Update Performer Category

  //   Modal
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  // Modal

  //   Modal
  let [edit, setEdit] = useState(false);

  function closeedit() {
    setEdit(false);
  }

  const openedit = () => {
    setEdit(true);
  };

  // Modal

  return (
    <div>
      <Header />
      <div className="col12SwmDashBord bg-white mb-20">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>

        <div className="col8SemNvr mt-[70px] ml-[5%]">
          <div className="py-4 pt-4">
            <button
              onClick={openModal}
              className="px-8 py-2 bg-white border-2 border-black flex justify-end items-end"
            >
              Add Production Type
            </button>
          </div>
          {data.length > 0 ? (
            <div className="">
              <div>
                <div className="">
                  <div class="  shadow-md sm:rounded-lg">
                    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
                      <thead class="text-xs text-gray-700 uppercase bg-orange-100	 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" class="py-4 px-4">
                            S No.
                          </th>
                          <th scope="col" class="py-4 px-4">
                            Production Type
                          </th>

                          <th scope="col" class="py-4 px-4">
                            Action
                          </th>
                        </tr>
                      </thead>

                      <tbody className="">
                        {data &&
                          data.map((item, index) => (
                            <tr
                              class="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                              key={index}
                            >
                              <th
                                scope="row"
                                class="py-3 px-4 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white"
                              >
                                {index + 1}
                              </th>
                              <th
                                scope="row"
                                class="py-3 px-4 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white"
                              >
                                {item.name}
                              </th>

                              <td class="py-3 text-xs">
                                <div className="flex gap-5">
                                  <BiEdit
                                    onClick={() => {
                                      setEditDirective(item);
                                      openedit();
                                    }}
                                    className="bg-blue-600 cursor-pointer text-white p-1"
                                    size={32}
                                  />

                                  <MdDelete
                                    onClick={() =>
                                      handleDeleteDirective(item._id)
                                    }
                                    className="bg-red-600 cursor-pointer text-white p-2"
                                    size={32}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        {/* Modal ADD section */}
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-[80%] transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                    <div className=" mt-[10px] ml-[3%] ">
                      <div className="grid grid-cols-11 ">
                        <div className="col-span-9 py-4">
                          <div className="text-black flex items-center text-sm tracking-wider font-bold">
                            Performer production Type{" "}
                          </div>
                        </div>
                      </div>

                      <div className="mb-10">
                        <div className=" mb-5">
                          <div className="border-[1.5px] border-gray-200 py-4 rounded-md">
                            <div className="flex justify-between bg-grey pl-6 py-1 text-sm text-[#4A4A4A] font-semibold">
                              Add Production Type
                            </div>

                            <div className="flex py-5 px-4 space-x-6 items-center">
                              <div className="w-full">
                                <label className="text-sm font-semibold text-[#898989]">
                                  Enter Production Type
                                </label>
                                <br />
                                <input
                                  value={directoryCat}
                                  onChange={(e) =>
                                    setDirectorycat(e.target.value)
                                  }
                                  type="text"
                                  placeholder=" Production Type"
                                  className="border-[1px] outline-none placeholder:text-xs  py-2.5 my-2 px-2 rounded-md  text-xs border-gray-300 w-full"
                                />
                              </div>
                            </div>
                          </div>

                          <button className="flex items-center justify-center pt-4 space-x-4 duration-300">
                            <p
                              onClick={handleDirectoryCategory}
                              className=" px-6 py-1 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                            >
                              Save
                            </p>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>

        {/* Modal Edit section */}
        <Transition appear show={edit} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeedit}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-[80%] transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                    <div className=" mt-[10px] ml-[3%] ">
                      <div className="grid grid-cols-11 ">
                        <div className="col-span-9 py-4">
                          <div className="text-black flex items-center text-sm tracking-wider font-bold">
                            Production Type{" "}
                          </div>
                        </div>
                      </div>

                      <div className="mb-10">
                        <div className=" mb-5">
                          <div className="border-[1.5px] border-gray-200 py-4 rounded-md">
                            <div className="flex justify-between bg-grey pl-6 py-1 text-sm text-[#4A4A4A] font-semibold">
                              Edit Production Type
                            </div>

                            <div className="flex py-5 px-4 space-x-6 items-center">
                              <div className="w-full">
                                <label className="text-sm font-semibold text-[#898989]">
                                  Edit Production Type
                                </label>
                                <br />
                                <input
                                  value={editDirective?.name}
                                  onChange={(e) =>
                                    setEditDirective({
                                      ...editDirective,
                                      name: e.target.value,
                                    })
                                  }
                                  type="text"
                                  placeholder="edit performer category"
                                  className="border-[1px] outline-none placeholder:text-xs  py-2.5 my-2 px-2 rounded-md  text-xs border-gray-300 w-full"
                                />
                              </div>
                            </div>
                          </div>

                          <button
                            onClick={UpdateDirective}
                            className="flex items-center justify-center pt-4 space-x-4 duration-300"
                          >
                            <p className=" px-6 py-1 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md ">
                              Save
                            </p>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>

      {/* Modal section */}
    </div>
  );
};

export default AddProductionTypePerformer;
