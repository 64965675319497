import React, { useState, useEffect, Fragment } from "react";
import LeftSideBar from "../sidebar/LeftSideBar";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { HiEye } from "react-icons/hi";
import { MdDelete, MdOutlineDoneOutline } from "react-icons/md";
import { FaHandHoldingUsd } from "react-icons/fa";
import { NavLink, Link } from "react-router-dom";

import Header from "../sidebar/Header";
import { GiCancel } from "react-icons/gi";
import Select from "react-select";
import { getAllPartner, DeletePartnerData } from "../apiconfig/authService";
import { toast } from "react-toastify";
export default function Contact() {
  const options = [
    { value: "Status", label: "Casting Director" },
    { value: "Pending", label: "Film Production House" },
    { value: "Rejected", label: "Ad Agency" },
  ];
  const employerDetails = [
    {
      id: 1,
      category: "Singer",
      name: "Sanjay Jangid",
      phone: "0987654576",
      email: "admin@gmail.com",
    },
    {
      id: 2,
      category: "Singer",
      name: "Sanjay Jangid",
      phone: "0987654576",
      email: "admin@gmail.com",
    },
    {
      id: 3,
      category: "Singer",
      name: "Sanjay Jangid",
      phone: "0987654576",
      email: "admin@gmail.com",
    },
    {
      id: 4,
      category: "Singer",
      name: "Sanjay Jangid",
      phone: "0987654576",
      email: "admin@gmail.com",
    },
    {
      id: 5,
      category: "Singer",
      name: "Sanjay Jangid",
      phone: "0987654576",
      email: "admin@gmail.com",
    },
    {
      id: 6,
      category: "Singer",
      name: "Sanjay Jangid",
      phone: "0987654576",
      email: "admin@gmail.com",
    },
    {
      id: 7,
      category: "Singer",
      name: "Sanjay Jangid",
      phone: "0987654576",
      email: "admin@gmail.com",
    },
    {
      id: 8,
      category: "Singer",
      name: "Sanjay Jangid",
      phone: "0987654576",
      email: "admin@gmail.com",
    },
  ];

  //   Modal
  const [modal, setModal] = useState(false);
  const [close, setClose] = useState(false);
  const [partnerData, setPartnerData] = useState([]);
  const openModal = () => {
    setModal(true);
    setClose(false);
  };

  const closeModal = () => {
    setClose(true);
    setModal(false);
  };
  // Modal

  const PartnerAllData = async () => {
    const response = await getAllPartner();

    console.log(response.data);
    setPartnerData(response.data);
  };

  const DeleteCatgry = async (id) => {
    alert(id);
    try {
      const response = await DeletePartnerData({
        id: id,
      });
      console.log("Api Response", response);
      toast(response.message);
      PartnerAllData();
    } catch (error) {
      toast(error.response.data.message);
    }
  };

  useEffect(() => {
    PartnerAllData();
  }, []);

  return (
    <>
      <Header />
      <div className="col12SwmDashBord bg-white ">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>
        <div className="col8SemNvr mt-[70px] ml-[5%]">
          {/* Bredcrums */}
          <div className="grid grid-cols-12">
            <div className="col-span-10 ">
              <div className="HdingSwnvr my-3 px-5">
                <h5> Contact</h5>
              </div>
            </div>
          </div>
          <hr className="w-full bg-gray-500 h-[2px]" />
          {/* Bredcrums End */}

        

          <div className="flex col12WdthSwhnMn mt-2">
            <div class=" relative  shadow-md sm:rounded-lg  mt-5">
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
                <thead class="text-xs text-gray-700 uppercase bg-orange-100	 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" class="py-4 px-4">
                      S No.
                    </th>
                    <th scope="col" class="py-4 px-4">
                      Name
                    </th>
                    <th scope="col" class="py-4 px-4">
                      Phone Number
                    </th>

                    <th scope="col" class="py-4 px-4">
                      Email
                    </th>

                    <th scope="col" class="py-4 px-4">
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody className="TableService">
                  {partnerData.map((item, index) => (
                    <tr
                      class="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                      key={index}
                    >
                      <th
                        scope="row"
                        class="py-3 px-4 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {index + 1}
                      </th>
                      <th
                        scope="row"
                        class="py-3 px-4 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white"
                      >
                       Ravi
                      </th>
                      <td class="py-3 text-xs">8858807666</td>
                       <td class="py-3 text-xs">ravi@gmail.com</td>

                      <td class="py-3 text-xs">
                        <div className="flex gap-5">
                          <NavLink to={`/cms/view-partners/${item._id}`}>
                            <HiEye
                              className="bg-[#0a65cc] text-white p-2"
                              size={32}
                            />
                          </NavLink>
                          <MdDelete
                            onClick={() => DeleteCatgry(item._id)}
                            className="bg-red-600 text-white p-2"
                            size={32}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* Modal section */}
        <div
          className={`w-screen h-screen fixed top-0 left-0 bg-black bg-opacity-20 ${
            modal ? "block duration-500" : "hidden duration-500"
          } ${close ? "hidden" : ""}`}
        >
          <div
            className={`text-sm absolute top-[50%] left-[50%]  w-[38%] translate-x-[-50%] h-auto  translate-y-[-50%] bg-[#fff] rounded-md shadow-lg pb-6 ${
              modal ? "duration-500" : "duration-500"
            }`}
          >
            <div className="bg-primary w-full flex items-center justify-between px-6 py-4 rounded-tl-md rounded-tr-md text-white">
              <div className="text-sm font-bold">Delete</div>
              <AiOutlineClose
                size={20}
                onClick={closeModal}
                className="cursor-pointer font-bold"
              />
            </div>

            <div className="text-center py-8">
              <h2 className="font-semibold text-lg">
                Are you sure you want to delete this item?
              </h2>
            </div>

            <div className="grid grid-cols-12 gap-5 px-6 py-8">
              <div className="col-span-6">
                <div className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white">
                  <MdOutlineDoneOutline className="text-2xl" />
                  <div class="uppercase">
                    <span>Confirm</span>
                  </div>
                </div>
              </div>

              <div className="col-span-6">
                <div
                  onClick={closeModal}
                  className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white"
                >
                  <GiCancel className="text-2xl" />
                  <div class="uppercase">
                    <span>Cancel</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal section */}
      </div>
    </>
  );
}
