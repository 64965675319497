import React, { useState, useEffect, Fragment } from "react";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { HiEye } from "react-icons/hi";
import { MdDelete, MdOutlineDoneOutline } from "react-icons/md";
import { FaHandHoldingUsd } from "react-icons/fa";
import { NavLink, Link } from "react-router-dom";
import { GiCancel } from "react-icons/gi";
import Header from "../sidebar/Header";
import LeftSideBar from "../sidebar/LeftSideBar";
import {
  getSubscriptionList,
  DeleteSubcriptionData,
} from "../apiconfig/authService";
import { toast } from "react-toastify";
export default function AddSubscription() {
  const [subScriptionList, setSubscriptionList] = useState([]);
  const SuscriptionListingDta = async () => {
    const response = await getSubscriptionList();
    response.data = response.data.filter(subscription => subscription.type!=="6 months free trial");
    setSubscriptionList(response.data);
  };

  const DeleteSubcription = async (id) => {
    try {
      const response = await DeleteSubcriptionData({
        id: id,
      });
      toast(response.message);
      SuscriptionListingDta();
    } catch (error) {
      toast(error.response.data.message);
    }
  };
  useEffect(() => {
    SuscriptionListingDta();
  }, []);

  console.log("Sub Scription List", subScriptionList);

  return (
    <>
      <Header />
      <div className="col12SwmDashBord bg-white ">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>
        <div className="col8SemNvr mt-[70px] ml-[5%]">
          {/* Bredcrums */}
          <div className="grid grid-cols-12">
            <div className="col-span-10 ">
              <div className="HdingSwnvr my-3 px-5">
                <h5> Add Subscription </h5>
              </div>
            </div>
          </div>
          <hr className="w-full bg-gray-500 h-[2px]" />
          {/* Bredcrums End */}

          <div className="flex col12WdthSwhnMn mt-2">
            <div className="flex justify-end items-end mb-5">
              <div className="hover:shadow">
                <NavLink to="/subscription/create-item">
                  <div className="flex cursor-pointer hover:shadow items-center border-[1px] px-6 space-x-1 py-2 rounded-md shadow-md">
                    <AiOutlinePlus />
                    <span>Create Item</span>
                  </div>
                </NavLink>
              </div>
            </div>

            {/* cards */}

            <div className="grid md:grid-cols-3 gap-5">
              {subScriptionList &&
                subScriptionList.map((itemSubscription, index) => (
                  <>
                    <div className="bg-white text-black px-10 py-12 rounded-tr-3xl rounded-bl-3xl shadow-lg shadow-black/40">
                      <div className="text-center font-semibold text-lg text-[#61005D]">
                        {itemSubscription?.type}
                      </div>
                      <div className="text-center font-bold text-2xl py-2 text-[#61005D]">
                        {itemSubscription?.Price}
                      </div>
                      <div className="text-center">
                        {itemSubscription?.Discription}
                      </div>
                      <div className="text-center flex justify-center mt-[20px]">
                        <NavLink
                          className="inline-block text-xs font-bold bg-[#61005d] rounded-[10px] mr-3 py-2 pl-6 pr-6 text-[#fff] "
                          to={`/subscription/edit-item/${itemSubscription._id}`}
                        >
                          {" "}
                          Edit{" "}
                        </NavLink>
                        <small
                          onClick={() =>
                            DeleteSubcription(itemSubscription._id)
                          }
                          className="inline-block text-xs font-bold bg-[#61005d] rounded-[10px] cursor-pointer  py-2 pl-6 pr-6 text-[#fff] "
                        >
                          {" "}
                          Delete{" "}
                        </small>
                      </div>
                    </div>
                  </>
                ))}
            </div>
            {/* cards End */}
          </div>
        </div>
        {/* Modal section */}
        {/* <div
          className={`w-screen h-screen fixed top-0 left-0 bg-black bg-opacity-20 ${
            modal ? "block duration-500" : "hidden duration-500"
          } ${close ? "hidden" : ""}`}
        >
          <div
            className={`text-sm absolute top-[50%] left-[50%]  w-[38%] translate-x-[-50%] h-auto  translate-y-[-50%] bg-[#fff] rounded-md shadow-lg pb-6 ${
              modal ? "duration-500" : "duration-500"
            }`}
          >
            <div className="bg-primary w-full flex items-center justify-between px-6 py-4 rounded-tl-md rounded-tr-md text-white">
              <div className="text-sm font-bold">Delete</div>
              <AiOutlineClose
                size={20}
                onClick={closeModal}
                className="cursor-pointer font-bold"
              />
            </div>

            <div className="text-center py-8">
              <h2 className="font-semibold text-lg">
                Are you sure you want to delete this item?
              </h2>
            </div>

            <div className="grid grid-cols-12 gap-5 px-6 py-8">
              <div className="col-span-6">
                <div className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white">
                  <MdOutlineDoneOutline className="text-2xl" />
                  <div class="uppercase">
                    <span>Confirm</span>
                  </div>
                </div>
              </div>

              <div className="col-span-6">
                <div
                  onClick={closeModal}
                  className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white"
                >
                  <GiCancel className="text-2xl" />
                  <div class="uppercase">
                    <span>Cancel</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* Modal section */}
      </div>
    </>
  );
}
