import React, { useState, useEffect } from "react";
import LeftSideBar from "../sidebar/LeftSideBar";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import Header from "../sidebar/Header";
import { MdDelete, MdOutlineDoneOutline } from "react-icons/md";
import { HiEye } from "react-icons/hi";
import { Country, State, City } from "country-state-city";
import { toast } from "react-toastify";
import { getAllBannerById, BannerEditDta } from "../apiconfig/authService";
function EditHomeBanner() {
  const [editState, setEditState] = useState(true);
  const options = [
    { value: "Production Type", label: "Production Type" },
    { value: "Ethnicity", label: "Ethnicity" },
  ];

  const id = useParams();

  const navigate = useNavigate();

  const bnnerid = id.id;

  //   Modal
  const [modal, setModal] = useState(false);
  const [close, setClose] = useState(false);
  const openModal = () => {
    setModal(true);
    setClose(false);
  };

  const closeModal = () => {
    setClose(true);
    setModal(false);
  };

  const [LargeImg, setLargeImg] = useState();
  // Modal
  const [Bneerstate, setHomeBnner] = useState({
    bannertitle: "",
    description: "",
    redirectionUrl: "",
  });

  const handleChange = (e) => {
    setHomeBnner({ ...Bneerstate, [e.target.name]: e.target.value });
  };

  const [upload1, setUpload1] = useState();
  function handleUpload1(e) {
    console.log(e.target.files);
    setLargeImg(e.target.files[0]);
    setUpload1(URL.createObjectURL(e.target.files[0]));
  }

  const BneerListById = async () => {
    const response = await getAllBannerById(bnnerid);
    console.log("Banner Data", response.data[0].ImageUrl);

    setHomeBnner({
      bannertitle: response.data[0].bannertitle,
      description: response.data[0].description,
      redirectionUrl: response.data[0].redirectionUrl,
    });
    setUpload1(response.data[0].ImageUrl);
  };

  var data = new FormData();
  data.append("bannertitle", Bneerstate.bannertitle);
  data.append("description", Bneerstate.description);
  data.append("redirectionUrl", Bneerstate.redirectionUrl);
  if (LargeImg === null || LargeImg === "" || LargeImg === undefined) {
  } else {
    data.append("file", LargeImg);
  }
  data.append("id", bnnerid);

  const handleBnnerEdit = async () => {
    try {
      if (
        !Bneerstate.bannertitle ||
        !Bneerstate.description ||
        !Bneerstate.redirectionUrl
      ) {
        return toast("Please Fill Proper Details");
      } else {
        const response = await BannerEditDta(data);
        console.log("Response Data Modules", response);
        if (response.code === 200) {
          toast(response.message);
          navigate("/cms/home-banner-listing");
        } else {
          toast(response.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    BneerListById();
  }, []);

  return (
    <>
      <Header />
      <div className="col12SwmDashBord">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>
        <div className="col8SemNvr mt-[70px] ml-[5%]">
          <div className="block w-full p-2 bg-white">
            {/* Brecrums */}
            <div className="flex justify-between mt-2 mb-2 px-4">
              <h4 className="text-xl font-bold">Edit Home Banner</h4>
              <div className="space-x-4">
                <button
                  onClick={handleBnnerEdit}
                  className=" px-6 py-1 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                >
                  Save
                </button>
                <NavLink
                  to="/cms/home-banner-listing"
                  className=" px-6 py-1.5 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                >
                  Back
                </NavLink>
              </div>
            </div>

            {/* End Brecrums */}

            <div className="block w-full bg-white ">
              <div className="p-4 block">
                <div class="py-3  gap-3 border-[1px] mb-0 w-full px-2 ">
                  <div class="w-full  my-3">
                    <label
                      class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                      for="grid-first-name"
                    >
                      Upload banner
                    </label>
                    <div className="">
                      <input
                        onChange={handleUpload1}
                        class="appearance-none block w-full text-xs border text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        type="file"
                        name="file"
                      />
                    </div>
                    <div id="banner1" className="my-3">
                      <img className="w-32 h-32" src={upload1} alt="" />
                    </div>
                  </div>

                  <div class="w-full  my-3">
                    <label
                      class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                      for="grid-first-name"
                    >
                      Add Heading
                    </label>
                    <div className="">
                      <input
                        class="appearance-none block w-full text-xs border text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                        value={Bneerstate.bannertitle}
                        name="bannertitle"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div class="w-full  my-3">
                    <label
                      class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                      for="grid-first-name"
                    >
                      Add Description
                    </label>
                    <div className="">
                      <input
                        class="appearance-none block w-full text-xs border text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                        value={Bneerstate.description}
                        name="description"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div class="w-full  my-3">
                    <label
                      class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                      for="grid-first-name"
                    >
                      Add Link
                    </label>
                    <div className="">
                      <input
                        class="appearance-none block w-full text-xs border text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                        value={Bneerstate.redirectionUrl}
                        name="redirectionUrl"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditHomeBanner;
