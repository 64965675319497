import React, { useState, useEffect } from "react";
import LeftSideBar from "../sidebar/LeftSideBar";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import Header from "../sidebar/Header";
import Select from "react-select";
import { MdDelete, MdOutlineDoneOutline } from "react-icons/md";
import { HiEye } from "react-icons/hi";
import { Country, State, City } from "country-state-city";
import { getAllTopVideosById, VideoEditDta } from "../apiconfig/authService";
import { toast } from "react-toastify";
function EditTopVideos() {
  const [editState, setEditState] = useState(true);
  const options = [
    { value: "Production Type", label: "Production Type" },
    { value: "Ethnicity", label: "Ethnicity" },
  ];

  const id = useParams();

  const navigate = useNavigate();

  const videoid = id.id;

  //   Modal
  const [modal, setModal] = useState(false);
  const [close, setClose] = useState(false);
  const openModal = () => {
    setModal(true);
    setClose(false);
  };

  const closeModal = () => {
    setClose(true);
    setModal(false);
  };

  const [LargeImg, setLargeImg] = useState();
  // Modal
  const [Bneerstate, setHomeBnner] = useState({
    title: "",
    videoUrl: "",
  });

  const handleChange = (e) => {
    setHomeBnner({ ...Bneerstate, [e.target.name]: e.target.value });
  };

  const [upload1, setUpload1] = useState();
  function handleUpload1(e) {
    console.log(e.target.files);
    setLargeImg(e.target.files[0]);
    setUpload1(URL.createObjectURL(e.target.files[0]));
  }

  const getTopVideoDataById = async () => {
    const response = await getAllTopVideosById(videoid);
    setHomeBnner({
      title: response.data[0].title,
      videoUrl: response.data[0].videoUrl,
    });
    setUpload1(response.data[0].ImageUrl);
  };

  var data = new FormData();
  data.append("title", Bneerstate.title);
  data.append("videoUrl", Bneerstate.videoUrl);
  if (LargeImg === null || LargeImg === "" || LargeImg === undefined) {
  } else {
    data.append("file", LargeImg);
  }
  data.append("id", videoid);

  const handleTopVideoEdit = async () => {
    try {
      if (!Bneerstate.title || !Bneerstate.videoUrl) {
        return toast("Please Fill Proper Details");
      } else {
        const response = await VideoEditDta(data);
        console.log("Response Data Modules", response);
        if (response.code === 200) {
          toast(response.message);
          navigate("/cms/top-videos-listing");
        } else {
          toast(response.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTopVideoDataById();
  }, []);

  return (
    <>
      <Header />
      <div className="col12SwmDashBord">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>
        <div className="col8SemNvr mt-[70px] ml-[5%]">
          <div className="block w-full p-2 bg-white">
            {/* Brecrums */}
            <div className="flex justify-between mt-2 mb-2 px-4">
              <h4 className="text-xl font-bold">Edit Top Videos</h4>
              <div className="space-x-4">
                <button
                  onClick={handleTopVideoEdit}
                  className=" px-6 py-1 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                >
                  Save
                </button>
                <NavLink
                  to="/cms/top-videos-listing"
                  className=" px-6 py-1.5 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                >
                  Back
                </NavLink>
              </div>
            </div>

            {/* End Brecrums */}

            <div className="block w-full bg-white ">
              <div className="p-4 block">
                <div class="py-3  gap-3 border-[1px] mb-0 w-full px-2 ">
                  <div class="w-full  my-3">
                    <label
                      class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                      for="grid-first-name"
                    >
                      Title
                    </label>
                    <div className="">
                      <input
                        class="appearance-none block w-full text-xs border text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                        name="title"
                        value={Bneerstate.title}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div class="w-full  my-3">
                    <label
                      class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                      for="grid-first-name"
                    >
                      Upload Thumbnail
                    </label>
                    <div className="">
                      <input
                        onChange={handleUpload1}
                        class="appearance-none block w-full text-xs border text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        type="file"
                        name="file"
                      />
                    </div>
                    <div id="banner1" className="my-3 ">
                      <img className="w-36" src={upload1} alt="" />
                    </div>
                  </div>

                  <div class="w-full  my-3">
                    <label
                      class="block w-full py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                      for="grid-first-name"
                    >
                      Video Url
                    </label>
                    <div className="">
                      <input
                        class="appearance-none block w-full text-xs border text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                        name="videoUrl"
                        value={Bneerstate.videoUrl}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditTopVideos;
