import React, { useState } from 'react'

const Resetpassword = () => {
   
   const [pass,setpass]=useState('')
   
   const handelpass=(e)=>{
     setpass(e.target.value)
     console.log(pass)
   }

  

  return (
  
      <>
      <div>
        <div className="h-screen grid grid-cols-12">
           <div className="col-span-12 md:col-span-7">
             <img src="/assets/images/welcome.png" alt='welcome' className="w-full h-[100%]" />
           </div>

           <div className='col-span-12 md:col-span-5'>
               <img src="/assets/images/logo.png" alt='Logo' className="pt-10 mx-auto" />
               
               <div className='mx-auto w-[80%] md:w-[400px] mt-24 mb-16 '>
                   <div className='text-primary text-4xl text-center'>Forgot Password</div>
                   <div className='text-center text-lg mt-8 text-lightgold'>Create new Password</div>  

                   <div className='mt-8'>
                       <form className='space-y-6'>
                          <input value={pass}  onChange={handelpass} type="text" name='Password' placeholder='Enter EmailId Or Mobile Number' className='outline-none placeholder-primary border-2 border-primary border-opacity-30  text-sm  w-full h-[45px] rounded-md shadow-sm pl-4'/>
                       </form>
                       <button className="w-full bg-primary font-semibold text-md mt-8 text-white rounded-md py-2">
                         RESET
                      </button>
                   </div>
               </div>
           </div>
        </div>
    </div>
    </>
  )
}

export default Resetpassword