import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { postfilterattribute } from "../apiconfig/authService";

function 
FeildInputData(props) {
  const [name, setName] = useState("");
  
  const changeName = (event) => {
    setName(event.target.value);
  };

  const handleAddFilter = async () => {
    if(!props.aboutId || !name){
      toast("Invalid data.");
      return;
    }
    
    const data = {
      aboutMeId:props.aboutId,
      name: name,
    };
    try {
      const response = await postfilterattribute(data);
      if (response.status == true) {
        toast(response.message);
      } else {
        toast(response.message);
      }
    } catch (error) {}
    setName("");
    props.filterData()
  };

  return (
    <div className="shadow-xl p-5 mb-5">
      <div className="border-[1.5px] border-gray-200 py-4 rounded-md">
        <div className="flex justify-between bg-grey pl-6 py-1 text-sm text-[#4A4A4A] font-semibold">
          Add Filter
        </div>

        <div className="flex flex-col px-4 space-x-6 items-center">
          <div className="w-full">
            <input
              type="text"
              value={name}
              onChange={changeName}
              placeholder="Enter"
              className="border-[1px] placeholder:text-xs  py-2.5 my-2 px-2 rounded-md  text-xs border-gray-300 w-full"
            />
          </div>
          <button
            onClick={handleAddFilter}
            className=" px-6 py-1 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
}

export default FeildInputData;
