import React, { useState, Fragment, useEffect } from "react";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { HiEye } from "react-icons/hi";
import { MdDelete, MdOutlineDoneOutline } from "react-icons/md";
import { FaHandHoldingUsd } from "react-icons/fa";
import { NavLink, Link } from "react-router-dom";
import { RiCoupon3Fill } from "react-icons/ri";
import { CiPercent } from "react-icons/ci";
import { BsFillAlarmFill } from "react-icons/bs";
import { AiFillStar } from "react-icons/ai";
import { GiCancel } from "react-icons/gi";
import LeftSideBar from "../sidebar/LeftSideBar";
import Header from "../sidebar/Header";
import { getSubscriptionOrderList } from "../apiconfig/authService";
import { Pagination } from "rsuite";
import 'rsuite/dist/rsuite.min.css';
export default function SubscriptionList() {
  const [subscriptionList, setSubscriptionOrderList] = useState([]);

  const SuscriptionListingOrderDta = async () => {
    const response = await getSubscriptionOrderList(pageSize === "All" ? totalCount : pageSize, pageSize === "All" ? 1 : pageNo);
    console.log(response);
    setSubscriptionOrderList(response.data);
    setTotalCount(response.pagination.totalCount);
    setTotalPages(Math.ceil(response.pagination.totalPages));
  };

  const employerDetails = [
    {
      id: 1,
      name: "Sanjay Jangid",
      idgiven: " FA46H96",
      amt: "Monthly",
    },
    {
      id: 2,
      name: "Sanjay Jangid",
      idgiven: " FA46H96",
      amt: "Quarterly",
    },
    {
      id: 3,
      name: "Sanjay Jangid",
      idgiven: " FA46H96",
      amt: "Yearly",
    },
    {
      id: 4,
      name: "Sanjay Jangid",
      idgiven: " FA46H96",
      amt: "Monthly",
    },
    {
      id: 5,
      name: "Sanjay Jangid",
      idgiven: " FA46H96",
      amt: "Quarterly",
    },
    {
      id: 6,
      name: "Sanjay Jangid",
      idgiven: " FA46H96",
      amt: "Yearly",
    },
    {
      id: 7,
      name: "Sanjay Jangid",
      idgiven: " FA46H96",
      amt: "Monthly",
    },
    {
      id: 8,
      name: "Sanjay Jangid",
      idgiven: " FA46H96",
      amt: "Yearly",
    },
  ];

  //   Modal
  const [modal, setModal] = useState(false);
  const [close, setClose] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [refetchData, setRefetchData] = useState(false);
  const openModal = () => {
    setModal(true);
    setClose(false);
  };

  const closeModal = () => {
    setClose(true);
    setModal(false);
  };
  // Modal

  useEffect(() => {
    SuscriptionListingOrderDta();
  }, [pageNo, refetchData]);

  const handlePageSizeChange = (e) => {
    setPageSize(e.target.value);
    setPageNo(1);
    setRefetchData(!refetchData);
  };

  return (
    <>
      <Header />
      <div className="col12SwmDashBord bg-white ">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>
        <div className="col8SemNvr mt-[70px] ml-[5%]">
          {/* Bredcrums */}
          <div className="grid grid-cols-12">
            <div className="col-span-10 ">
              <div className="HdingSwnvr my-3 px-5">
                <h5> Subscription List </h5>
              </div>
            </div>
          </div>
          <hr className="w-full bg-gray-500 h-[2px]" />
          {/* Bredcrums End */}

          <div className="flex col12WdthSwhnMn mt-2">
            <div className="flex justify-between items-end mb-5">
              <div className="flex gap-4  items-center">
                {/* <div className="WdtPerMn">
                                    <label>Search by Id</label>
                                    <div class="flex items-center ServeCtgrySwn pt-3">
                                        <label for="simple-search" class="sr-only">
                                            Search
                                        </label>
                                        <div class="relative w-full">
                                            <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                                <svg
                                                    aria-hidden="true"
                                                    class="w-5 h-5 text-gray-500 dark:text-gray-400"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                                        clip-rule="evenodd"
                                                    ></path>
                                                </svg>
                                            </div>
                                            <input
                                                type="text"
                                                id="simple-search"
                                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm  block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                                                placeholder="Search By Id"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="WdtPerMn">
                                    <label>Search by Date</label>
                                    <div class="flex items-center ServeCtgrySwn pt-3">

                                        <div class="relative w-full">

                                            <input
                                                type="date"

                                                className="bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-sm  block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "

                                                required
                                            />
                                        </div>
                                    </div>
                                </div> */}
              </div>

              {/* <div className="WdtPerMn">
                                <div

                                    className="flex cursor-pointer hover:shadow items-center border-[1px] px-6 space-x-1 py-2 rounded-md shadow-md"
                                >
                                    <AiOutlinePlus />
                                    <span>Add Item</span>
                                </div>
                            </div> */}
            </div>
            <div className="flex">
              <label>Show </label>
              <div class="flex mt-[-2px] mr-2 ml-2 items-center ServeCtgrySwn">
                <div class="relative w-full">
                  <select
                    className="bg-gray-50 py-1 border border-gray-300 text-gray-500 text-sm rounded-sm  block w-full px-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                    value={pageSize}
                    onChange={handlePageSizeChange}
                  >
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={250}>250</option>
                    <option value={500}>500</option>
                    <option value="All">All</option>
                  </select>
                </div>
              </div>
              <label> records per page. </label>
            </div>
            {/* Table  */}

            <div class=" relative  shadow-md sm:rounded-lg  mt-5">
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
                <thead class="text-sm text-gray-700 uppercase bg-orange-100	 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" class="py-4 px-4">
                      S No.
                    </th>
                    <th scope="col" class="py-4 px-4">
                      Name
                    </th>
                    <th scope="col" class="py-4 px-4">
                      Amount Paid
                    </th>
                    <th scope="col" class="py-4 px-4">
                      Payment Mode
                    </th>

                    <th scope="col" class="py-4 px-4">
                      Payment Status
                    </th>
                  </tr>
                </thead>

                <tbody className="TableService">
                  {subscriptionList.map((itemList, index) => (
                    <tr
                      class="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                      key={index}
                    >
                      <th
                        scope="row"
                        class="py-3 px-4 font-medium text-sm text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {index + 1}
                      </th>
                      <th
                        scope="row"
                        class="py-3 px-4 font-medium text-sm text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {itemList?.performerId[0]?.name}
                      </th>
                      <td class="py-3 text-sm">{itemList.amoutpaid}</td>
                      {/* className={item.amt === "free" ? "py-3 px-4 text-sm text-success" : "py-3 px-4 text-sm text-red-600"} */}
                      <td class="py-3 text-sm px-5">{itemList.paymentmode}</td>
                      <td class="py-3 text-sm px-5">
                        {itemList.paymentStatus}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Table End */}
          </div>
            <div className=" pb-[20px] pl-[20px] pr-[20px] w-full flex justify-between mt-3">
              {totalCount === 0 ? <div>Showing 0 - 0 entries of 0 entries. </div> : pageSize === "All" ? (<div>Showing {1} - {totalCount} entries of {totalCount} entries.</div>) :
                (<div>Showing {(pageNo - 1) * pageSize + 1} - {totalCount < (pageSize * pageNo) ? totalCount : pageSize * pageNo} entries of {totalCount} entries.</div>)}
              {/* Pagination Start */}
              <div style={{ margin: "100" }}>
                <Pagination
                  prev
                  last
                  next
                  first
                  size='sm'
                  ellipsis
                  boundaryLinks
                  onSelect={setPageNo}
                  limit={pageSize ==="ALL" ? totalCount : pageSize}
                  onChangeLimit={setPageSize}
                  layout={['pager', 'skip']}
                  pages={totalPages}
                  total={totalCount}
                  maxButtons={5}
                  activePage={pageNo}
                  onChangePage={(page) => { setPageNo(page) }}
                />
              </div>
              {/* Pagination End */}
            </div>
        </div>
        {/* Modal section */}
        <div
          className={`w-screen h-screen fixed top-0 left-0 bg-black bg-opacity-20 ${modal ? "block duration-500" : "hidden duration-500"
            } ${close ? "hidden" : ""}`}
        >
          <div
            className={`text-sm absolute top-[50%] left-[50%]  w-[38%] translate-x-[-50%] h-auto  translate-y-[-50%] bg-[#fff] rounded-md shadow-lg pb-6 ${modal ? "duration-500" : "duration-500"
              }`}
          >
            <div className="bg-primary w-full flex items-center justify-between px-6 py-4 rounded-tl-md rounded-tr-md text-white">
              <div className="text-sm font-bold">Delete</div>
              <AiOutlineClose
                size={20}
                onClick={closeModal}
                className="cursor-pointer font-bold"
              />
            </div>

            <div className="text-center py-8">
              <h2 className="font-semibold text-lg">
                Are you sure you want to delete this item?
              </h2>
            </div>

            <div className="grid grid-cols-12 gap-5 px-6 py-8">
              <div className="col-span-6">
                <div className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white">
                  <MdOutlineDoneOutline className="text-2xl" />
                  <div class="uppercase">
                    <span>Confirm</span>
                  </div>
                </div>
              </div>

              <div className="col-span-6">
                <div
                  onClick={closeModal}
                  className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white"
                >
                  <GiCancel className="text-2xl" />
                  <div class="uppercase">
                    <span>Cancel</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal section */}
      </div>
    </>
  );
}
