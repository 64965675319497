import React, { useState } from "react";
import { AiOutlineRight } from "react-icons/ai";
import Header from "../sidebar/Header";
import LeftSideBar from "../sidebar/LeftSideBar";
import { NavLink } from "react-router-dom";
import {
  
  PostEthencityType,
  PostProductionType,
} from "../apiconfig/authService";
import { toast } from "react-toastify";

const DynamicEmployer = () => {
  const [production, setProduction] = useState("");
  const [ethencity, setEthencity] = useState("");

  //Post Api Data Of Ethencity Type
  const handleEthencity = async () => {
    if (!ethencity) {
      toast("Please enter ethnicity type");
    } else {
      try {
        const response = await PostEthencityType({
          name: ethencity,
        });
        if (response.status == true) {
          toast(response.message);
        } else {
          toast(response.message);
        }
      } catch (error) {
        toast(error.response.data.message);
      }
    }
  };
  //Post Api Data Of Ethencity Type

  //Post Api Data Of Production Type
  const handleProduction = async () => {
    if (!production) {
      toast("Please enter Production type");
    } else {
      try {
        const response = await PostProductionType({
          name: production,
        });
        if (response.status == true) {
          toast(response.message);
        } else {
          toast(response.message);
        }
      } catch (error) {
        toast(error.response.data.message);
      }
    }
  };
  //Post Api Data Of Production Type

  return (
    <div>
      <Header />
      <div className="col12SwmDashBord bg-white mb-20">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>
        <div className="col8SemNvr mt-[70px] ml-[5%] ">
          <div className="grid grid-cols-11 ">
            <div className="col-span-9 py-4">
              <div className="text-black flex items-center text-sm tracking-wider font-bold">
                Others{" "}
                <span className="space-x-1 flex items-center text-secondary">
                  <AiOutlineRight />
                  Employers
                </span>
                <span className="space-x-1 flex items-center text-secondary">
                  <AiOutlineRight />
                  Add Attributes
                </span>
              </div>
            </div>
          </div>

          {/* Start Complete Form */}
          <div className="mb-60">
            <div className="shadow-xl p-5 mb-5">
              <div className="border-[1.5px] border-gray-200 py-4 rounded-md">
                <div className="flex justify-between bg-grey pl-6 py-1 text-sm text-[#4A4A4A] font-semibold">
                  Ethnicity Type
                </div>

                <div className="flex py-5 px-4 space-x-6 items-center">
                  <div className="w-full">
                    <label className="text-sm font-semibold text-[#898989]">
                      Add Ethnicity
                    </label>
                    <br />
                    <input
                      value={ethencity}
                      onChange={(e) => setEthencity(e.target.value)}
                      type="text"
                      placeholder="Enter Ethnicity"
                      className="border-[1px] placeholder:text-xs  py-2.5 my-2 px-2 rounded-md  text-xs border-gray-300 w-full"
                    />
                  </div>
                </div>
              </div>

              {/* Cancel & Save Btn */}

              <div
                onClick={handleEthencity}
                className="flex items-center justify-center pt-4 space-x-4 duration-300"
              >
                <NavLink className=" px-6 py-1 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md ">
                  Add Ethnicity
                </NavLink>
              </div>
            </div>
            <div className="shadow-xl p-5 mb-5">
              <div className="border-[1.5px] border-gray-200 py-4 rounded-md my-4">
                <div className="flex justify-between bg-grey pl-6 py-1 text-sm text-[#4A4A4A] font-semibold">
                  Production Type
                </div>

                <div className="flex py-5 px-4 space-x-6 items-center">
                  <div className="w-full">
                    <label className="text-sm font-semibold text-[#898989]">
                      Add Production type
                    </label>
                    <br />
                    <input
                      value={production}
                      onChange={(e) => setProduction(e.target.value)}
                      type="text"
                      placeholder="Enter Producation Type"
                      className="border-[1px] placeholder:text-xs  py-2.5 my-2 px-2 rounded-md  text-xs border-gray-300 w-full"
                    />
                  </div>
                </div>
              </div>

              {/* Cancel & Save Btn */}
              <div className="flex items-center justify-center pt-4 space-x-4 duration-300">
                <NavLink
                  onClick={handleProduction}
                  className=" px-6 py-1 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                >
                  Add Production Type{" "}
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DynamicEmployer;
