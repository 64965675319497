import Credentials from "../auth/login/Credentials";
import Resetpassword from "../auth/login/Resetpassword";
import IndexMain from "../dasboard/IndexMain";
import Employer from "../employer/Employer";
import Performer from "../performer/Performer";
import EmployerDetail from "../employer/EmployerDetail";
import PerformerDetail from "../performer/PerformerDetail";

import DynamicDirectory from "../dynamic directory/DynamicDirectory";

import AgencyHouse from "../agency-house/AgencyHouse";
import AddSubscription from "../subscription/AddSubscription";
import SubscriptionList from "../subscription/SubscriptionList";
import CreateFeature from "../subscription/CreateFeature";
import ViewFeature from "../subscription/ViewFeature";
import EditFeature from "../subscription/EditFeature";
import DynamicView from "../dynamic directory/DynamicView";
import ProductionHouse from "../production-house/ProductionHouse";
import ProductionHouseView from "../production-house/ProductionHouseView";
import AgencyHouseView from "../agency-house/AgencyHouseView";
import AddAttribute from "../attributes/AddAttribute";
import CreateAttribute from "../attributes/CreateAttribute";
import DynamicEmployer from "../dynamic employer/DynamicEmployer";
import DynamicPerformer from "../dynamic performer/DynamicPerformer";
import AddCategory from "../add-category/AddCategory";

import AddDirectory from "../directory/AddDirectory";
import CreateDirectory from "../directory/CreateDirectory";
import ViewAttribute from "../attributes/ViewAttribute";
import Jobs from "../jobs/Jobs";
import JobDetails from "../jobs/JobDetails";
import ViewCategory from "../directory/ViewCategory";
import AddDirectoryPage from "../directory/AddDirectoryPage";
import AddNewDirectory from "../directory/AddNewDirectory";
import ManageApplicants from "../jobs/ManageApplicants";
import NoOfapplicants from "../jobs/NoOfApplicants";
import AddEmployerDirectory from "../directory/AddEmployerDirectory";
import AttributeListEmployer from "../directory/AttributeListEmployer";
// import AddFilterPerformer from "../directory/AddFilterPerformer";
import CreateFilterPerformer from "../directory/CreateFilterPerformer";
// import ViewFilterPerformer from "../directory/ViewFilterPerformer";
import AddProductionTypePerformer from "../directory/AddProductionTypePerformer";
import LatestUpdates from "../cms/latest-updates";
import CreateBlog from "../cms/addBlog";
import Testimonials from "../cms/testimonials";
import CreateTestimonial from "../cms/create-testimonial";
import CmsFaq from "../cms/faq";
import CreateFaq from "../cms/create-faq";
import CreatePost from "../cms/create-post";
import Partners from "../cms/partners";
import CreatePartner from "../cms/create-partner";
import ViewPost from "../cms/view-post";
import ViewTestimonial from "../cms/view-testimonial";
import ViewPartner from "../cms/view-partners";
import Home from "../cms/home";
import Pages from "../cms/pages";
import HomeBanner from "../cms/HomeBanner";
import EditHomeBanner from "../cms/EditHomeBanner";
import EditTopVideos from "../cms/EditTopVideos";
import HomeBannerListing from "../cms/HomeBannerListing";
import HomeVideosListing from "../cms/HomeVideosListing";
import HomePerformerListing from "../cms/HomePerformerListing";
import EditHomePerformer from "../cms/EditHomePerformer";
import AddJob from "../cms/add-job";
import CreateJob from "../cms/create-job";
import EditJob from "../cms/EditJob";
import Application from "../cms/application";
import Lifestyle from "../cms/lifestyle";
import Library from "../cms/library";
import AddLibrary from "../cms/add-library";
import LifestyleList from "../cms/lifestyle-list";
import EditLifestyle from "../cms/edit-lifestyle";
import Contact from "../cms/contact";
import Header from "../cms/header";
import HeaderPage from "../cms/header";
import HomeVideos from "../cms/HomeVideos";
import HomePerformer from "../cms/HomePerformer";
import Editlibrary from "../cms/Editlibrary";
// import ApplicationData from "../cms/ApplicationData";
import ApplicationData from "../cms/contactusList";
import Trash from "../Trash/Trash";
import TrashEmployer from "../Trash/TrashEmployer";
import Changepassword from "../auth/login/ChangePassword";
export const RouteData = [
  {
    id: 1,
    protected: false,
    path: "/",
    element: <Credentials />,
  },
  {
    id: 2,
    protected: false,
    path: "/reset-password",
    element: <Resetpassword />,
  },
  {
    id: 2,
    protected: true,
    path: "/manage/employer",
    element: <Employer />,
  },
  {
    id: 3,
    protected: true,
    path: "/dashboard",
    element: <IndexMain />,
  },
  {
    id: 4,
    protected: true,
    path: "/change-password",
    element: <Changepassword />,
  },

  //milestone-links start from here
  {
    id: 201,
    protected: true,
    path: "/directory-house/dynamic-directory",
    element: <DynamicDirectory />,
  },
  {
    id: 776,
    protected: true,
    path: "/cms/partners",
    element: <Partners />,
  },
  {
    id: 776,
    protected: true,
    path: "/cms/application",
    element: <Application />,
  },
  {
    id: 786,
    protected: true,
    path: "/cms/addBlog",
    element: <CreateBlog />,
  },

  {
    id: 999,
    protected: true,
    path: "/cms/contactus",
    element: <ApplicationData />,
  },

  {
    id: 776,
    protected: true,
    path: "/cms/create-job",
    element: <CreateJob />,
  },
  {
    id: 888,
    protected: true,
    path: "/cms/edit-job/:id",
    element: <EditJob />,
  },
  {
    id: 779,
    protected: true,
    path: "/cms/create-partner",
    element: <CreatePartner />,
  },

  {
    id: 789,
    protected: true,
    path: "/cms/view-post/:blogid",
    element: <ViewPost />,
  },
  {
    id: 789,
    protected: true,
    path: "/cms/view-testimonial/:id",
    element: <ViewTestimonial />,
  },
  {
    id: 789,
    protected: true,
    path: "/cms/view-partners/:id",
    element: <ViewPartner />,
  },
  {
    id: 799,
    protected: true,
    path: "/cms/home",
    element: <Home />,
  },
  {
    id: 799,
    protected: true,
    path: "/cms/contact",
    element: <Contact />,
  },
  {
    id: 799,
    protected: true,
    path: "/cms/header",
    element: <HeaderPage />,
  },
  {
    id: 789,
    protected: true,
    path: "/cms/lifestyle-list",
    element: <LifestyleList />,
  },
  {
    id: 789,
    protected: true,
    path: "/cms/edit-lifestyle/:id",
    element: <EditLifestyle />,
  },
  {
    id: 791,
    protected: true,
    path: "/cms/lifestyle",
    element: <Lifestyle />,
  },
  {
    id: 791,
    protected: true,
    path: "/cms/library",
    element: <Library />,
  },
  {
    id: 790,
    protected: true,
    path: "/cms/add-library",
    element: <AddLibrary />,
  },
  {
    id: 792,
    protected: true,
    path: "/cms/edit-library/:id",
    element: <Editlibrary />,
  },
  {
    id: 199,
    protected: true,
    path: "/cms/add-job",
    element: <AddJob />,
  },
  {
    id: 203,
    protected: true,
    path: "/directory-house/agency-house",
    element: <AgencyHouse />,
  },
  {
    id: 204,
    protected: true,
    path: "/subscription/add-subscription",
    element: <AddSubscription />,
  },
  {
    id: 204,
    protected: true,
    path: "/subscription/subscription-list",
    element: <SubscriptionList />,
  },
  {
    id: 205,
    protected: true,
    path: "/subscription/create-item",
    element: <CreateFeature />,
  },
  {
    id: 206,
    protected: true,
    path: "/subscription/view-item",
    element: <ViewFeature />,
  },
  {
    id: 202,
    protected: true,
    path: "/directory-house/production-House",
    element: <ProductionHouse />,
  },
  {
    id: 207,
    protected: true,
    path: "/subscription/edit-item/:id",
    element: <EditFeature />,
  },

  {
    id: 204,
    protected: true,
    path: "/manage/employer-detail/:id",
    element: <EmployerDetail />,
  },

  {
    id: 204,
    protected: true,
    path: "/manage/performer-detail/:id",
    element: <PerformerDetail />,
  },
  {
    id: 205,
    protected: true,
    path: "/dynamic-directory-view",
    element: <DynamicView />,
  },
  {
    id: 206,
    protected: true,
    path: "/production-house-detail",
    element: <ProductionHouseView />,
  },
  {
    id: 207,
    protected: true,
    path: "/agency-house-detail",
    element: <AgencyHouseView />,
  },
  {
    id: 208,
    protected: true,
    path: "/add-attribute",
    element: <AddAttribute />,
  },
  {
    id: 209,
    protected: true,
    path: "/attribute/create-attribute",
    element: <CreateAttribute />,
  },
  {
    id: 205,
    protected: true,
    path: "/managed-jobs/dynamic-employer",
    element: <DynamicEmployer />,
  },
  {
    id: 211,
    protected: true,
    path: "/manage/performer",
    element: <Performer />,
  },
  {
    id: 211,
    protected: true,
    path: "/manage/performer-detail",
    element: <PerformerDetail />,
  },

  {
    id: 205,
    protected: true,
    path: "/managed-jobs/dynamic-performer",
    element: <DynamicPerformer />,
  },

  {
    id: 210,
    protected: true,
    path: "/directory/add-category",
    element: <AddDirectory />,
  },

  {
    id: 210,
    protected: true,
    path: "/directory/view-category/:id",
    element: <ViewCategory />,
  },
  {
    id: 210,
    protected: true,
    path: "/directory/create-category",
    element: <AddNewDirectory />,
  },

  {
    id: 210,
    protected: true,
    path: "/cms/create-testimonial",
    element: <CreateTestimonial />,
  },
  {
    id: 210,
    protected: true,
    path: "/cms/create-post",
    element: <CreatePost />,
  },
  {
    id: 210,
    protected: true,
    path: "/cms/create-faq",
    element: <CreateFaq />,
  },
  {
    id: 103,
    protected: true,
    path: "/cms/faq",
    element: <CmsFaq />,
  },

  {
    id: 210,
    protected: true,
    path: "/directory/add-directorypage",
    element: <AddDirectoryPage />,
  },
  {
    id: 211,
    protected: true,
    path: "/directory/create-directory",
    element: <CreateDirectory />,
  },

  {
    id: 205,
    protected: true,
    path: "/add-category",
    element: <AddCategory />,
  },
  {
    id: 213,
    protected: true,
    path: "/view-attribute",
    element: <ViewAttribute />,
  },
  {
    id: 205,
    protected: true,
    path: "/jobs",
    element: <Jobs />,
  },
  {
    id: 205,
    protected: true,
    path: "/job-details/:id",
    element: <JobDetails />,
  },
  {
    id: 216,
    protected: true,
    path: "/jobs/manage-appliants",
    element: <ManageApplicants />,
  },
  {
    id: 217,
    protected: true,
    path: "/jobs/applicants-details/:id",
    element: <NoOfapplicants />,
  },
  {
    id: 218,
    protected: true,
    path: "/directory/employer-addCategory",
    element: <AddEmployerDirectory />,
  },
  {
    id: 219,
    protected: true,
    path: "/directory/attribute-employer",
    element: <AttributeListEmployer />,
  },
  // {
  //   id: 220,
  //   protected: true,
  //   path: "/directory/addfilter-perforer",
  //   element: <AddFilterPerformer />,
  // },
  {
    id: 221,
    protected: true,
    path: "/directory/create-filter-performer",
    element: <CreateFilterPerformer />,
  },
  // {
  //   id: 222,
  //   protected: true,
  //   path: "/directory/view-filter-performer",
  //   element: <ViewFilterPerformer />,
  // },
  {
    id: 223,
    protected: true,
    path: "/directory/add-production-type",
    element: <AddProductionTypePerformer />,
  },
  {
    id: 224,
    protected: true,
    path: "/cms/latest-updates",
    element: <LatestUpdates />,
  },
  {
    id: 225,
    protected: true,
    path: "/cms/latest-updates/createBlog/:id",
    element: <CreateBlog />,
  },
  {
    id: 226,
    protected: true,
    path: "/cms/testimonials",
    element: <Testimonials />,
  },
  {
    id: 227,
    protected: true,
    path: "/cms/home-banner-listing",
    element: <HomeBannerListing />,
  },
  {
    id: 228,
    protected: true,
    path: "/cms/add-top-videos",
    element: <HomeVideos />,
  },
  {
    id: 229,
    protected: true,
    path: "/cms/home-top-performer",
    element: <HomePerformer />,
  },
  {
    id: 230,
    protected: true,
    path: "/cms/home-banner",
    element: <HomeBanner />,
  },
  {
    id: 231,
    protected: true,
    path: "/cms/edit-home-banner/:id",
    element: <EditHomeBanner />,
  },
  {
    id: 232,
    protected: true,
    path: "/cms/top-videos-listing",
    element: <HomeVideosListing />,
  },
  {
    id: 234,
    protected: true,
    path: "/cms/edit-top-videos/:id",
    element: <EditTopVideos />,
  },
  {
    id: 235,
    protected: true,
    path: "/cms/top-performer-listing",
    element: <HomePerformerListing />,
  },
  {
    id: 236,
    protected: true,
    path: "/cms/edit-performer/:id",
    element: <EditHomePerformer />,
  },
  {
    id: 237,
    protected: true,
    path: "/manage/users/Trash",
    element: <Trash />,
  },
  {
    id: 238,
    protected: true,
    path: "/manage/users/Trash/Employer",
    element: <TrashEmployer />,
  },
  {
    id: 239,
    protected: true,
    path: "/cms/pages",
    element: <Pages />,
  },
];
