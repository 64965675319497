import React, { useState, useEffect } from "react";
import LeftSideBar from "../sidebar/LeftSideBar";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Header from "../sidebar/Header";
import Select from "react-select";
import { Country, State, City } from "country-state-city";
import { PutLibrayDataApi, getLibrayById } from "../apiconfig/authService";
import { toast } from "react-toastify";
function Editlibrary() {
  const libray = useParams();
  const libraryid = libray.id;
  const [LargeImg, setLargeImg] = useState();
  const [upload1, setUpload1] = useState();
  function handleUpload1(e) {
    console.log(e.target.files);
    setLargeImg(e.target.files[0]);
    setUpload1(URL.createObjectURL(e.target.files[0]));
  }
  const navigate = useNavigate();
  const [Bneerstate, setHomeBnner] = useState({
    librarytitle: "",
    description: "",
    redirectionUrl: "",
  });

  const handleChange = (e) => {
    setHomeBnner({ ...Bneerstate, [e.target.name]: e.target.value });
  };

  const getLibrayByIdData = async () => {
    const response = await getLibrayById(libraryid);
    setHomeBnner({
      librarytitle: response.data[0].librarytitle,
      description: response.data[0].description,
      redirectionUrl: response.data[0].redirectionUrl,
    });
    setUpload1(response.data[0].ImageUrl);
  };

  var data = new FormData();
  data.append("librarytitle", Bneerstate.librarytitle);
  data.append("description", Bneerstate.description);
  data.append("redirectionUrl", Bneerstate.redirectionUrl);
  if (LargeImg == "" || LargeImg == undefined || LargeImg == null) {
  } else {
    data.append("file", LargeImg);
  }
  data.append("id", libraryid);
  const PutLibrayData = async () => {
    try {
      if (!Bneerstate.librarytitle || !Bneerstate.description) {
        return toast("Please Fill Proper Details");
      } else {
        const response = await PutLibrayDataApi(data);
        if (response.code === 200) {
          toast(response.message);
          navigate("/cms/library");
        } else {
          toast(response.message);
        }
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getLibrayByIdData();
  }, []);

  return (
    <>
      <Header />
      <div className="col12SwmDashBord">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>
        <div className="col8SemNvr mt-[70px] ml-[5%]">
          <div className="block w-full p-2 bg-white">
            {/* Brecrums */}

            <div className="flex justify-between mt-2 mb-2 px-4">
              <h4 className="text-xl font-bold"> Library</h4>
              <div className="space-x-4">
                <button
                  onClick={PutLibrayData}
                  className=" px-6 py-1 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                >
                  Save
                </button>
                <NavLink
                  to="/cms/partners"
                  className=" px-6 py-1.5 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                >
                  Back
                </NavLink>
              </div>
            </div>

            {/* End Brecrums */}

            <div className="block w-full bg-white ">
              <div className="p-4 border-[1px] block">
                <div class="py-3 grid grid-cols-2 gap-3  mb-0 w-full px-2 ">
                  <div class="w-full border px-3 my-3 justify-between  flex items-center">
                    <label class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2">
                      Upload Image
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="name"
                        onChange={handleUpload1}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        type="file"
                      />
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between  flex items-center">
                    <label class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2">
                      Library Title
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                        name="librarytitle"
                        value={Bneerstate.librarytitle}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div id="upload">
                    <img className="w-[200px]" src={upload1} alt="" />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-3">
                  <div class="w-full border px-3 my-3 justify-between  flex items-center">
                    <label class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2">
                      Small Description
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                        name="description"
                        value={Bneerstate.description}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between  flex items-center">
                    <label class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2">
                      Link
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                        name="redirectionUrl"
                        value={Bneerstate.redirectionUrl}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Editlibrary;
