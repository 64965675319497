import React, { useState, useEffect } from "react";
import LeftSideBar from "../sidebar/LeftSideBar";
import { NavLink } from "react-router-dom";
import Header from "../sidebar/Header";
import { useParams } from "react-router-dom";
import {
  deleteEmployerById,
  getEmpCatById,
  getEmployerById,
  updateEmployer,
  updateEmployerStatus,
} from "../apiconfig/authService";
import { toast } from "react-toastify";
function EmployerDetail() {
  const [editState, setEditState] = useState(true);

  const id = useParams();
  const employerId = id.id;
  console.log(employerId);

  const [state, setState] = useState({});
  //   employerId: "1234",
  //   employerName: "Shrikant",
  //   companyName: "Ackrolix Innovations Pvt. Ltd",
  //   occupation: "Director",
  //   address: "fdhf",
  //   phone: "976554578",
  //   email: "abc@gmail.com",
  //   category: "Category",
  //   position: "Project Manager",
  //   name: "Shrikant",
  //   website: "https/website.com",
  //   createdAt: "Created",
  //   description: "I am a web designer",
  //   vision: "Vision",
  // });

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const [category, setCategory] = useState("");

  const getEmployerDetails = async () => {
    try {
      const response = await getEmployerById({ params: { id: employerId } });
      console.log(response.data);
      setState(response.data);
      setCategory(response.data.categoryId);
    } catch (error) {
      console.log(error);
    }
  };

  const [catName, setCatName] = useState("");

  const getCategory = async () => {
    try {
      const response = await getEmpCatById({
        params: {
          id: category,
        },
      });
      console.log(response.data);
      setCatName(response.data[0].name);
    } catch (error) {
      console.log(error);
    }
  };

  const handleApprove = async (e) => {
    e.preventDefault();
    const data = {
      id: state._id,
      employerstatus: "Approved",
    };

    try {
      const response = await updateEmployerStatus(data);
      if (response.status == 200) {
        toast(response.message);
      } else {
        toast(response.message);
      }
    } catch (error) {
      console.log(error);
    }
    console.log(data);
  };

  const handleDisapprove = async (e) => {
    e.preventDefault();
    const data = {
      id: state._id,
      employerstatus: "Rejected",
    };

    try {
      const response = await updateEmployerStatus(data);
      if (response.status == 200) {
        toast(response.message);
      } else {
        toast(response.message);
      }
    } catch (error) {
      console.log(error);
    }
    console.log(data);
  };
  const handleSave = async () => {
    try {
      const response = await updateEmployer(state);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEmployerDetails();
  }, []);
  useEffect(() => {
    getCategory();
  }, [category]);

  console.log(state);

  console.log(catName);
  return (
    <>
      <Header />
      <div className="col12SwmDashBord">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>
        <div className="col8SemNvr mt-[70px] ml-[5%]">
          <div className="block w-full p-2 bg-white">
            {/* Brecrums */}
            <nav class="flex MngeBredcums px-4" aria-label="Breadcrumb">
              <ol class="inline-flex items-center space-x-1 md:space-x-3">
                <li>
                  <div class="flex items-center">
                    <NavLink
                      to="#"
                      class="ml-1 text-xs font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white"
                    >
                      {" "}
                      MANAGE USER{" "}
                    </NavLink>
                  </div>
                </li>
                <li aria-current="page">
                  <div class="flex items-center">
                    <svg
                      class="w-4 h-4 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <NavLink
                      to="/manage/employer"
                      class="ml-1  font-medium text-gray-500 md:ml-2 dark:text-gray-400 text-xs"
                    >
                      {" "}
                      Employer{" "}
                    </NavLink>
                  </div>
                </li>
                <li aria-current="page">
                  <div class="flex items-center">
                    <svg
                      class="w-4 h-4 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span class="ml-1  font-medium text-gray-500 md:ml-2 dark:text-gray-400 text-xs">
                      {" "}
                      Employer Detail{" "}
                    </span>
                  </div>
                </li>
              </ol>
            </nav>

            <div className="flex justify-between mt-2 mb-2 px-4">
              <h4 className="text-xl font-bold"> Employer Detail</h4>
              <div className="space-x-4">
                {/* <button
                  onClick={() => {setEditState(!editState);
                    if(editState === false){
                      handleSave()
                    }
                  }
                }
                  className=" px-6 py-1 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                >
                  {editState ? "Edit" : "Save"}
                </button> */}
                <NavLink
                  to="/manage/employer"
                  className=" px-6 py-1.5 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                >
                  Back
                </NavLink>
              </div>
            </div>

            {/* End Brecrums */}

            <div className="block w-full  mt-2 bg-white ">
              <div className="p-4   block">
                <div class="py-3 grid grid-cols-2 gap-3 border-[1px] mb-0 w-full px-2 ">
                  <div class="w-full border px-3 my-3 justify-between  flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Employer Id
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="employerId"
                        defaultValue={state.employerId}
                        disabled={editState}
                        onChange={handleChange}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between  flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Employer Name
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="employerName"
                        defaultValue={state.orgName}
                        disabled={editState}
                        onChange={handleChange}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between  flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Occupation
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="employerName"
                        defaultValue={state.designation}
                        disabled={editState}
                        onChange={handleChange}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between  flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Primary Phone No.
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="employerName"
                        defaultValue={state.phoneNumber}
                        disabled={editState}
                        onChange={handleChange}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between  flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Secondary Phone No.
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="employerName"
                        defaultValue={state.secondaryPhoneNumber}
                        disabled={editState}
                        onChange={handleChange}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between  flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Company Name
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="companyName"
                        defaultValue={state.orgName}
                        disabled={editState}
                        onChange={handleChange}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Email
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="email"
                        defaultValue={state.email}
                        disabled={editState}
                        onChange={handleChange}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="w-full border px-3 my-3 justify-between flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Address with City
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <textarea
                        name="address"
                        value={`${state?.address},  ${state?.city} `}
                        disabled={editState}
                        onChange={handleChange}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Pincode
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="pincode"
                        defaultValue={state.pincode}
                        disabled={editState}
                        onChange={handleChange}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Country
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="country"
                        defaultValue={state.country}
                        disabled={editState}
                        onChange={handleChange}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      State
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="state"
                        defaultValue={state.state}
                        disabled={editState}
                        onChange={handleChange}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between flex items-center">
                    <label
                      class="block w-full border-r py-2px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Category
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="category"
                        defaultValue={catName}
                        disabled={editState}
                        onChange={handleChange}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="w-full border px-3 my-3 justify-between flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Position
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="position"
                        onChange={handleChange}
                        disabled={editState}
                        defaultValue={state.designation}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Name
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="name"
                        onChange={handleChange}
                        disabled={editState}
                        defaultValue={state.fullName}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="w-full border px-3 my-3 justify-between flex items-center">
                    <label
                      class="block w-full border-r py-2  px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      website
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="website"
                        onChange={handleChange}
                        disabled={editState}
                        defaultValue={state.websiteurl || state.website}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Created at
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="createdAt"
                        onChange={handleChange}
                        disabled={editState}
                        value={new Date(state.createdAt).toLocaleDateString(
                          "en-GB"
                        )}
                        class="appearance-none block w-full text-xs text-gray-700 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>
                  {/* <div class="w-full border px-3 my-3 justify-between flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Description
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="description"
                        value={state.description}
                        disabled={editState}
                        onChange={handleChange}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="w-full border px-3 my-3 justify-between flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Vision
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="vision"
                        onChange={handleChange}
                        disabled={editState}
                        value={state.vision}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="flex items-center justify-end space-x-4 mb-4 duration-300">
                <a
                  href={`https://www.milestone101.com/in/c/${state?.employerId}`}
                  target="_blank" rel="noreferrer"
                >
                  <button className="w-52 px-4 py-1 border-[1px] border-primary text-white hover:bg-white bg-primary hover:text-primary rounded-md ">
                    View Resume
                  </button>
                </a>
                <button
                  type="button"
                  onClick={handleApprove}
                  className=" px-14 py-1 border-[1px] border-primary text-white hover:bg-white bg-primary hover:text-primary rounded-md "
                >
                  APPROVED
                </button>
                <button
                  type="button"
                  onClick={handleDisapprove}
                  className=" px-14 py-1 border-[1px] border-primary text-white hover:bg-white bg-primary hover:text-primary rounded-md "
                  >
                  DISAPPROVE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmployerDetail;
