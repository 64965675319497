import React, { useState, useEffect } from "react";
import LeftSideBar from "../sidebar/LeftSideBar";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../sidebar/Header";
import Select from "react-select";
import { Country, State, City } from "country-state-city";
import { AddPartnerData } from "../apiconfig/authService";
import { toast } from "react-toastify";

function CreatePartner() {
  const [editState, setEditState] = useState(true);
  const options = [
    { value: "Production Type", label: "Production Type" },
    { value: "Ethnicity", label: "Ethnicity" },
  ];

  const navigate = useNavigate();
  const [partnerData, setPartner] = useState({
    title: "",
  });
  const [uplodData, setUploadData] = useState([]);

  const handleChange = (e) => {
    setPartner({ ...partnerData, [e.target.name]: e.target.value });
  };

  const [uploadImage, setUploadImage] = useState();
  function handleImage(e) {
    console.log(e.target.files);
    setUploadData(e.target.files[0])
    setUploadImage(URL.createObjectURL(e.target.files[0]));
  }
  
  var data = new FormData();
  data.append("title", partnerData.title);
  data.append("image", uplodData);

  const PostPartner = async () => {
    try {
      if (!partnerData.title) {
        toast("Please Fill Details");
      } else {
        const response = await AddPartnerData(data);
        console.log(response);
        if (response.code == 200) {
          toast(response.message);
          navigate("/cms/partners");
        } else {
          toast(response.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {}, []);

  return (
    <>
      <Header />
      <div className="col12SwmDashBord">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>
        <div className="col8SemNvr mt-[70px] ml-[5%]">
          <div className="block w-full p-2 bg-white">
            {/* Brecrums */}
            <nav class="flex MngeBredcums px-4" aria-label="Breadcrumb">
              <ol class="inline-flex items-center space-x-1 md:space-x-3">
                <li>
                  <div class="flex items-center">
                    <NavLink
                      to="#"
                      class="ml-1 text-xs font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white"
                    >
                      {" "}
                      Partner{" "}
                    </NavLink>
                  </div>
                </li>
                <li aria-current="page">
                  <div class="flex items-center">
                    <svg
                      class="w-4 h-4 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span class="ml-1  font-medium text-gray-500 md:ml-2 dark:text-gray-400 text-xs">
                      {" "}
                      Create Partner{" "}
                    </span>
                  </div>
                </li>
              </ol>
            </nav>

            <div className="flex justify-between mt-2 mb-2 px-4">
              <h4 className="text-xl font-bold"> Partner Detail</h4>
              <div className="space-x-4">
                <button
                  onClick={PostPartner}
                  className=" px-6 py-1 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                >
                  Save
                </button>
                <NavLink
                  to="/cms/partners"
                  className=" px-6 py-1.5 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                >
                  Back
                </NavLink>
              </div>
            </div>

            {/* End Brecrums */}


            <div className="block w-full bg-white ">
              <div className="p-4 block">
                <div class="py-3 grid grid-cols-2 gap-3 border-[1px] mb-0 w-full px-2 ">
                  <div class="w-full border px-3 my-3 justify-between  flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Name
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        name="title"
                        type="text"
                        value={partnerData.title}
                        onChange={handleChange}
                      />
                    </div>
                  </div>


                  <div class="w-full border px-3 my-3 justify-between  flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Upload Image
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="name"
                        onChange={handleImage}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="file"
                      />
                    </div>
                  </div>

                  <div>
                    <img className="w-[100px]" src={uploadImage} alt="" />
                  </div>

                  {/* <div class="w-full border px-3 my-3 justify-between  flex items-center">
                                        <label
                                            class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                                            for="grid-first-name"
                                        >
                                            Telephone
                                        </label>
                                        <div className="w-full md:w-[90%] px-3">
                                            <input
                                                name="telephone"
                                                // value={state.secondaryEmail}
                                                
                                                onChange={
                                                    (e) => 
                                                        e.target.value
                                                    
                                                }
                                                class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                                                id="grid-first-name"
                                                type="text"
                                            />
                                        </div>
                                    </div> */}

                  {/* <div class="w-full border px-3 my-3 justify-between  flex items-center">
                                        <label
                                            class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                                            for="grid-first-name"
                                        >
                                            Address
                                        </label>
                                        <div className="w-full md:w-[90%] px-3">
                                            <textarea
                                                name="address"
                                                value={state.address}
                                                
                                                onChange={handleChange}
                                                class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                                                id="grid-first-name"
                                                type="text"
                                            />
                                        </div>
                                    </div> */}

                  {/* <div class="w-full border px-3 my-3 justify-between  flex items-center">
                                        <label
                                            class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                                            for="grid-first-name"
                                        >
                                            Country
                                        </label>
                                        <div className="w-full md:w-[90%] px-3">
                                            {/* <input
                                                name="country"
                                                value={state.country}
                                                disabled={editState}
                                                onChange={handleChange}
                                                class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                                                id="grid-first-name"
                                                type="text"
                                            /> */}
                  {/* <Select
                                            name="country"
                                                placeholder="Country"
                                                getOptionLabel={(e) => e.name}
                                                getOptionValue={(e) => e}
                                                value={state.country}
                                                onChange={(e) =>
                                                    setState({
                                                        ...state,
                                                        country: e,
                                                    })
                                                }                           
                                                
                                                options={mycountry}
                                            />
                                        </div>
                                    </div> */}

                  {/* <div class="w-full border px-3 my-3 justify-between flex items-center">
                                        <label
                                            class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                                            for="grid-first-name"
                                        >
                                            State
                                        </label>
                                        <div className="w-full md:w-[90%] px-3">
                                            <Select
                                                placeholder="State"
                                                getOptionLabel={(e) => e.name}
                                                getOptionValue={(e) => e}
                                                value={state.state}
                                                onChange={(e) =>
                                                    setState({
                                                        ...state,
                                                        state: e,
                                                    })
                                                }
                                                
                                                options={mystate}
                                            />
                                        </div>
                                    </div> */}
                  {/* <div class="w-full border px-3 my-3 justify-between flex items-center">
                                        <label
                                            class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                                            for="grid-first-name"
                                        >
                                            City
                                        </label>
                                        <div className="w-full md:w-[90%] px-3">
                                            <Select
                                                placeholder="city"
                                                getOptionLabel={(e) => e.name}
                                                getOptionValue={(e) => e}
                                                value={state.city}
                                                onChange={(e) =>
                                                    setState({
                                                        ...state,
                                                        city: e,
                                                    })
                                                }
                                                
                                                options={mycity}
                                            />
                                        </div>
                                    </div> */}

                  {/* <div class="w-full border px-3 my-3 justify-between flex items-center">
                                        <label
                                            class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                                            for="grid-first-name"
                                        >
                                            Pincode
                                        </label>
                                        <div className="w-full md:w-[90%] px-3">
                                            <input
                                                name="pincode"
                                                // value={(e) => e.target.value}                                                
                                                onChange={(e) => e.target.value}
                                                class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                                                id="grid-first-name"
                                                type="text"
                                            />
                                        </div>
                                    </div> */}

                  {/* <div class="w-full border px-3 my-3 justify-between flex items-center">
                                        <label
                                            class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                                            for="grid-first-name"
                                        >
                                            Website
                                        </label>
                                        <div className="w-full md:w-[90%] px-3">
                                            <input
                                                name="website"
                                                value={state.website}
                                                
                                                onChange={handleChange}
                                                class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                                                id="grid-first-name"
                                                type="text"
                                            />
                                        </div>
                                    </div> */}

                  {/* <div class="w-full border px-3 my-3 justify-between flex items-center">
                                        <label
                                            class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                                            for="grid-first-name"
                                        >
                                            Branch Office
                                        </label>
                                        <div className="w-full md:w-[90%] px-3">
                                            <input
                                                name="branchOffice"
                                                value={state.branchOffice}
                                                
                                                onChange={handleChange}
                                                class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                                                id="grid-first-name"
                                                type="text"
                                            />
                                        </div>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreatePartner;
