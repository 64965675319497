import React, { useState, Fragment } from "react";
import LeftSideBar from "../sidebar/LeftSideBar";
import { AiOutlineClose } from "react-icons/ai";
import { HiEye } from "react-icons/hi";
import { MdDelete, MdOutlineDoneOutline } from "react-icons/md";
import { FaHandHoldingUsd } from "react-icons/fa";
import { NavLink, Link } from "react-router-dom";
import { RiCoupon3Fill } from "react-icons/ri";
import { CiPercent } from "react-icons/ci";
import { BsFillAlarmFill } from "react-icons/bs";
import { AiFillStar } from "react-icons/ai";

import Header from "../sidebar/Header";
import { GiCancel } from "react-icons/gi";
export default function ProductionHouse() {
  const employerDetails = [
    {
      id: 1,
      name: "Sanjay Jangid",
      phone: "0987654576",
      email: "admin@gmail.com",
      state: "Haryana",
      city: "Gurgaon",
    },
    {
      id: 2,
      name: "Sanjay Jangid",
      phone: "0987654576",
      email: "admin@gmail.com",
      state: "Haryana",
      city: "Gurgaon",
    },
    {
      id: 3,
      name: "Sanjay Jangid",
      phone: "0987654576",
      email: "admin@gmail.com",
      state: "Haryana",
      city: "Gurgaon",
    },
    {
      id: 4,
      name: "Sanjay Jangid",
      phone: "0987654576",
      email: "admin@gmail.com",
      state: "Haryana",
      city: "Gurgaon",
    },
    {
      id: 5,
      name: "Sanjay Jangid",
      phone: "0987654576",
      email: "admin@gmail.com",
      state: "Haryana",
      city: "Gurgaon",
    },
    {
      id: 6,
      name: "Sanjay Jangid",
      phone: "0987654576",
      email: "admin@gmail.com",
      state: "Haryana",
      city: "Gurgaon",
    },
    {
      id: 7,
      name: "Sanjay Jangid",
      phone: "0987654576",
      email: "admin@gmail.com",
      state: "Haryana",
      city: "Gurgaon",
    },
    {
      id: 8,
      name: "Sanjay Jangid",
      phone: "0987654576",
      email: "admin@gmail.com",
      state: "Haryana",
      city: "Gurgaon",
    },
  ];

  //   Modal
  const [modal, setModal] = useState(false);
  const [close, setClose] = useState(false);
  const openModal = () => {
    setModal(true);
    setClose(false);
  };

  const closeModal = () => {
    setClose(true);
    setModal(false);
  };
  // Modal

  return (
    <>
      <Header />
      <div className="col12SwmDashBord bg-white ">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>
        <div className="col8SemNvr mt-[70px] ml-[5%]">
          {/* Bredcrums */}
          <div className="grid grid-cols-12">
            <div className="col-span-10 ">
              <div className="HdingSwnvr my-3 px-5">
                <h5> Production House</h5>
              </div>
            </div>
          </div>
          <hr className="w-full bg-gray-500 h-[2px]" />
          {/* Bredcrums End */}

          <div className="flex col12WdthSwhnMn mt-2">
            <div className="flex  items-center mb-5 gap-4">
              <div className="WdtPerMn">
                <label>Search by Id</label>
                <div class="flex items-center ServeCtgrySwn pt-3">
                  <label for="simple-search" class="sr-only">
                    Search
                  </label>
                  <div class="relative w-full">
                    <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                      <svg
                        aria-hidden="true"
                        class="w-5 h-5 text-gray-500 dark:text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <input
                      type="text"
                      id="simple-search"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm  block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                      placeholder="Search By Id"
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="WdtPerMn">
                <label>Search by Date</label>
                <div class="flex items-center ServeCtgrySwn pt-3">
                  <div class="relative w-full">
                    <input
                      type="date"
                      className="bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-sm  block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Table  */}

            <div class=" relative  shadow-md sm:rounded-lg  mt-5">
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
                <thead class="text-xs text-gray-700 uppercase bg-orange-100	 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" class="py-4 px-4">
                      S No.
                    </th>
                    <th scope="col" class="py-4 px-4">
                      Name
                    </th>
                    <th scope="col" class="py-4 px-4">
                      Email
                    </th>
                    <th scope="col" class="py-4 px-4">
                      Phone
                    </th>
                    <th scope="col" class="py-4 px-4">
                      State
                    </th>
                    <th scope="col" class="py-4 px-4">
                      City
                    </th>
                    <th scope="col" class="py-4 px-4">
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody className="TableService">
                  {employerDetails.map((item, index) => (
                    <tr
                      class="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                      key={index}
                    >
                      <th
                        scope="row"
                        class="py-3 px-4 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {item.id}
                      </th>
                      <th
                        scope="row"
                        class="py-3 px-4 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {item.name}
                      </th>
                      <td class="py-3 text-xs">{item.email}</td>
                      <td class="py-3 px-4 text-xs">{item.phone}</td>
                      <td class="py-3 px-4 text-xs">{item.state}</td>
                      <td class="py-3 px-4 text-xs">{item.city}</td>

                      <td class="py-3 text-xs">
                        <div className="flex gap-5">
                          <NavLink to="/production-house-detail">
                          <HiEye
                            className="bg-[#0a65cc] text-white p-2"
                            size={32}
                          />
                          </NavLink>
                          <MdDelete
                            onClick={openModal}
                            className="bg-red-600 text-white p-2"
                            size={32}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Table End */}

            <div className="SwvrMnvr flex">
              <ul>
                <li className="prev">
                  {" "}
                  <NavLink to={"#"}>
                    {" "}
                    <img alt="Swhn" src="/assets/images/leftarrow.png" />{" "}
                  </NavLink>{" "}
                </li>
                <li>
                  {" "}
                  <NavLink to={"1"}> 1 </NavLink>{" "}
                </li>
                <li>
                  {" "}
                  <NavLink to={"#"}> 2 </NavLink>{" "}
                </li>
                <li>
                  {" "}
                  <NavLink to={"#"}> 3 </NavLink>{" "}
                </li>
                <li>
                  {" "}
                  <NavLink to={"#"}> .. </NavLink>{" "}
                </li>
                <li>
                  {" "}
                  <NavLink to={"#"}> 7 </NavLink>{" "}
                </li>
                <li>
                  {" "}
                  <NavLink to={"#"}> 8 </NavLink>{" "}
                </li>
                <li>
                  {" "}
                  <NavLink to={"#"}> 9 </NavLink>{" "}
                </li>
                <li>
                  {" "}
                  <NavLink to={"#"}> 10 </NavLink>{" "}
                </li>
                <li className="next">
                  {" "}
                  <NavLink to={"#"}>
                    {" "}
                    <img alt="Swhn" src="/assets/images/rightarrow.png" />{" "}
                  </NavLink>{" "}
                </li>
              </ul>

              <div className="flex ml-2">
                <select
                  id="countries"
                  class="bg-gray-50 border text-xs border-gray-300 text-gray-900  rounded-[2px] focus:ring-blue-500 focus:border-blue-500 block w-full p-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option selected value="10">
                    10
                  </option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="100">200</option>
                  <option value="200">200</option>
                  <option value="300">300</option>
                  <option value="400">400</option>
                  <option value="500">500</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        {/* Modal section */}
        <div
          className={`w-screen h-screen fixed top-0 left-0 bg-black bg-opacity-20 ${
            modal ? "block duration-500" : "hidden duration-500"
          } ${close ? "hidden" : ""}`}
        >
          <div
            className={`text-sm absolute top-[50%] left-[50%]  w-[38%] translate-x-[-50%] h-auto  translate-y-[-50%] bg-[#fff] rounded-md shadow-lg pb-6 ${
              modal ? "duration-500" : "duration-500"
            }`}
          >
            <div className="bg-primary w-full flex items-center justify-between px-6 py-4 rounded-tl-md rounded-tr-md text-white">
              <div className="text-sm font-bold">Delete</div>
              <AiOutlineClose
                size={20}
                onClick={closeModal}
                className="cursor-pointer font-bold"
              />
            </div>

            <div className="text-center py-8">
              <h2 className="font-semibold text-lg">
                Are you sure you want to delete this item?
              </h2>
            </div>

            <div className="grid grid-cols-12 gap-5 px-6 py-8">
              <div className="col-span-6">
                <div className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white">
                  <MdOutlineDoneOutline className="text-2xl" />
                  <div class="uppercase">
                    <span>Confirm</span>
                  </div>
                </div>
              </div>

              <div className="col-span-6">
                <div
                  onClick={closeModal}
                  className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white"
                >
                  <GiCancel className="text-2xl" />
                  <div class="uppercase">
                    <span>Cancel</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal section */}
      </div>
    </>
  );
}
