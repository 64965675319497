import React, { useState, useEffect, useRef } from "react";
import LeftSideBar from "../sidebar/LeftSideBar";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../sidebar/Header";
import Select from "react-select";
import { Country, State, City } from "country-state-city";
import { addJobDataShow } from "../apiconfig/authService";
import JoditEditor from "jodit-react";
import { toast } from "react-toastify";

// import React, { Component } from 'react';
// import VideoPlayer from 'react-video-js-player';

function CreateJob() {
  // Create Job

  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [content1, setContent1] = useState("");

  const navigate = useNavigate();

  const [jobCreate, setCreateJob] = useState({
    title: "",
    totalExperience: "",
    salary: "",
    skills: "",
    // jobDescription: "",
    // candidateDescription: "",
  });

  const handleChange = (e) => {
    setCreateJob({ ...jobCreate, [e.target.name]: e.target.value });
  };

  const [dropDown, setDropDown] = useState([]);

  const addJobDetails = async () => {
    if (
      !jobCreate.title ||
      !jobCreate.totalExperience ||
      !jobCreate.salary ||
      !jobCreate.skills
      // !jobCreate.jobDescription ||
      // !jobCreate.candidateDescription
    ) {
      toast("Please Fill Details");
    } else {
      try {
        const response = await addJobDataShow({
          title: jobCreate.title,
          totalExperience: jobCreate.totalExperience,
          salary: jobCreate.salary,
          skills: jobCreate.skills,
          jobDescription: content,
          candidateDescription: content1,
        });

        if (response.code == 200) {
          navigate("/cms/add-job");
          toast(response.message);
        } else {
          toast(response.message);
        }
        console.log(response);
      } catch (error) {}
    }
  };

  useEffect(() => {}, []);

  return (
    <>
      <Header />
      <div className="col12SwmDashBord">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>
        <div className="col8SemNvr mt-[70px] ml-[5%]">
          <div className="block w-full p-2 bg-white">
            {/* Brecrums */}
            <nav class="flex MngeBredcums px-4" aria-label="Breadcrumb">
              <ol class="inline-flex items-center space-x-1 md:space-x-3">
                <li>
                  <div class="flex items-center">
                    <NavLink
                      to="#"
                      class="ml-1 text-base font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white"
                    >
                      {" "}
                      Career{" "}
                    </NavLink>
                  </div>
                </li>
                <li aria-current="page">
                  <div class="flex items-center">
                    <svg
                      class="w-4 h-4 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span class="ml-1  font-medium text-gray-500 md:ml-2 dark:text-gray-400 text-base">
                      {" "}
                      Add Job{" "}
                    </span>
                  </div>
                </li>
              </ol>
            </nav>

            <div className="flex justify-between mt-2 mb-2 px-4">
              <h4 className="text-xl font-bold"> Job Detail</h4>
              <div className="space-x-4">
                <button
                  onClick={addJobDetails}
                  className=" px-6 py-1 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                >
                  Save
                </button>
                <NavLink
                  to="/cms/add-job"
                  className=" px-6 py-1.5 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                >
                  Back
                </NavLink>
              </div>
            </div>

            {/* End Brecrums */}

            <div className="block w-full bg-white ">
              <div className="p-4 block">
                <div class="py-3 grid grid-cols-2 gap-3 border-[1px] mb-0 w-full px-2 ">
                  <div class="w-full border px-3 my-3 justify-between  flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                      for="grid-first-name"
                    >
                      Job Title{" "}
                      <small className="text-red-500 text-xs"> * </small>
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="title"
                        onChange={handleChange}
                        class="appearance-none block w-full text-base text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="w-full border px-3 my-3 justify-between  flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                      for="grid-first-name"
                    >
                      Experience{" "}
                      <small className="text-red-500 text-xs"> * </small>
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="totalExperience"
                        onChange={handleChange}
                        class="appearance-none block w-full text-base text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between  flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                      for="grid-first-name"
                    >
                      Salary <small className="text-red-500 text-xs"> * </small>
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="salary"
                        onChange={handleChange}
                        class="appearance-none block w-full text-base text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between  flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                      for="grid-first-name"
                    >
                      Skills <small className="text-red-500 text-xs"> * </small>
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="skills"
                        onChange={handleChange}
                        class="appearance-none block w-full text-base text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full block">
                  <label
                    class="block w-full py-2 px-3 mt-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                    for="grid-first-name"
                  >
                    Description{" "}
                    <small className="text-red-500 text-xs"> * </small>
                  </label>
                  <div class="w-full border px-3 my-1 justify-between  flex items-center">
                    <div className="w-full">
                      <JoditEditor
                        ref={editor}
                        name="jobDescription"
                        value={content}
                        tabIndex={1} // tabIndex of textarea
                        onChange={(newContent) => setContent(newContent)}
                      />{" "}
                      {/* <textarea
                        name="jobDescription"
                        onChange={handleChange}
                        placeholder="Write your description here..."
                        class="appearance-none block w-full h-24 text-base text-gray-700  rounded py-2  leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      /> */}
                    </div>
                  </div>
                </div>

                <div className="w-full block">
                  <label
                    class="block w-full py-2 px-3 mt-3  md:mb-0  tracking-wide text-black-700 text-base font-bold mb-2"
                    for="grid-first-name"
                  >
                    Candidate Description{" "}
                    <small className="text-red-500 text-xs"> * </small>
                  </label>
                  <div class="w-full border px-3 my-1 justify-between  flex items-center">
                    <div className="w-full">
                      <JoditEditor
                        ref={editor}
                        value={content1}
                        tabIndex={1} // tabIndex of textarea
                        onChange={(newContent) => setContent1(newContent)}
                      />{" "}
                      {/* <textarea
                        name="candidateDescription"
                        onChange={handleChange}
                        placeholder="Write your description here..."
                        class="appearance-none block w-full h-24 text-base text-gray-700  rounded py-2  leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateJob;
