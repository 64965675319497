import React, { useState, useEffect, Fragment } from "react";
import LeftSideBar from "../sidebar/LeftSideBar";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { HiEye } from "react-icons/hi";
import { MdDelete, MdOutlineDoneOutline } from "react-icons/md";
import { FaHandHoldingUsd } from "react-icons/fa";
import { NavLink, Link } from "react-router-dom";
import { BiHide } from "react-icons/bi";

import Header from "../sidebar/Header";
import { GiCancel } from "react-icons/gi";
import Select from "react-select";
import {
  getAllHeaderMenu,
  DeleteHeader,
  getAllHeaderMenuFilter,
} from "../apiconfig/authService";
import { toast } from "react-toastify";
export default function HeaderPage() {
  const options = [
    { value: "Status", label: "Casting Director" },
    { value: "Pending", label: "Film Production House" },
    { value: "Rejected", label: "Ad Agency" },
  ];
  const employerDetails = [
    {
      id: 1,
      category: "Singer",
      name: "Sanjay Jangid",
      phone: "0987654576",
      email: "admin@gmail.com",
    },
    {
      id: 2,
      category: "Singer",
      name: "Sanjay Jangid",
      phone: "0987654576",
      email: "admin@gmail.com",
    },
    {
      id: 3,
      category: "Singer",
      name: "Sanjay Jangid",
      phone: "0987654576",
      email: "admin@gmail.com",
    },
    {
      id: 4,
      category: "Singer",
      name: "Sanjay Jangid",
      phone: "0987654576",
      email: "admin@gmail.com",
    },
    {
      id: 5,
      category: "Singer",
      name: "Sanjay Jangid",
      phone: "0987654576",
      email: "admin@gmail.com",
    },
    {
      id: 6,
      category: "Singer",
      name: "Sanjay Jangid",
      phone: "0987654576",
      email: "admin@gmail.com",
    },
    {
      id: 7,
      category: "Singer",
      name: "Sanjay Jangid",
      phone: "0987654576",
      email: "admin@gmail.com",
    },
    {
      id: 8,
      category: "Singer",
      name: "Sanjay Jangid",
      phone: "0987654576",
      email: "admin@gmail.com",
    },
  ];

  //   Modal
  const [modal, setModal] = useState(false);
  const [close, setClose] = useState(false);
  const [typevalue, setTypeValue] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const openModal = () => {
    setModal(true);
    setClose(false);
  };

  const closeModal = () => {
    setClose(true);
    setModal(false);
  };
  // Modal

  console.log("Type Value", typevalue);

  const HeaderMenuAllData = async () => {
    const response = await getAllHeaderMenu();
    console.log("Header Dashboard", response.data);
    setHeaderData(response.data);
  };

  const HeaderMenuAllDataFilter = async (typevalue) => {
    const response = await getAllHeaderMenuFilter(typevalue);
    console.log("Header Dashboard Flter", response.data);

    setHeaderData(response.data);
    // setHeaderData(response.data);
  };

  const DeleteCatgry = async (id) => {
    try {
      const response = await DeleteHeader(id);
      console.log("Api Response", response);
      toast(response.message);
      HeaderMenuAllData();
    } catch (error) {
      toast(error.response.data.message);
    }
  };

  useEffect(() => {
    HeaderMenuAllDataFilter(typevalue);
  }, [typevalue]);

  useEffect(() => {
    HeaderMenuAllData();
  }, []);

  return (
    <>
      <Header />
      <div className="col12SwmDashBord bg-white ">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>
        <div className="col8SemNvr mt-[70px] ml-[5%]">
          {/* Bredcrums */}
          <div className="grid grid-cols-12">
            <div className="col-span-9 ">
              <div className="HdingSwnvr my-3 px-5">
                <h5> Header </h5>
              </div>
            </div>
            <div className="col-span-3">
              <div className="flex">
                <label className="mt-2"> Select Type</label>

                <div class="mx-3  border p-2">
                  <select onChange={(e) => setTypeValue(e.target.value)}>
                    <option> Select Type </option>
                    <option value={"website"}>Main Website</option>
                    <option value={"employer"}>Employer</option>
                    <option value={"performer"}>Performer</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          {/* Bredcrums End */}

          <div className="flex col12WdthSwhnMn mt-2">
            <div class=" relative  shadow-md sm:rounded-lg  mt-5">
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
                <thead class="text-sm text-gray-700 uppercase bg-orange-100	 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" class="py-4 px-4">
                      S No.
                    </th>
                    <th scope="col" class="py-4 px-4">
                      Header Name
                    </th>
                    <th scope="col" class="py-4 px-4">
                      Type
                    </th>
                    <th scope="col" class="py-4 px-4">
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody className="TableService">
                  {headerData &&
                    headerData.map((itemMenuData, index) => (
                      <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                        <th
                          scope="row"
                          class="py-3 px-4 font-medium text-sm text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {index + 1}
                        </th>
                        <th
                          scope="row"
                          class="py-3 px-4 font-medium text-sm text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {itemMenuData.name}
                        </th>
                        <td class="py-3 text-sm">{itemMenuData.type}</td>

                        <td class="py-3 text-sm">
                          <div className="flex gap-5">
                            <label class="inline-flex relative items-center cursor-pointer">
                              <input
                                type="checkbox"
                                onClick={() => DeleteCatgry(itemMenuData._id)}
                                checked={itemMenuData.isActive}
                                class="sr-only peer"
                              />
                              <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                            </label>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* Modal section */}
        <div
          className={`w-screen h-screen fixed top-0 left-0 bg-black bg-opacity-20 ${
            modal ? "block duration-500" : "hidden duration-500"
          } ${close ? "hidden" : ""}`}
        >
          <div
            className={`text-sm absolute top-[50%] left-[50%]  w-[38%] translate-x-[-50%] h-auto  translate-y-[-50%] bg-[#fff] rounded-md shadow-lg pb-6 ${
              modal ? "duration-500" : "duration-500"
            }`}
          >
            <div className="bg-primary w-full flex items-center justify-between px-6 py-4 rounded-tl-md rounded-tr-md text-white">
              <div className="text-sm font-bold">Delete</div>
              <AiOutlineClose
                size={20}
                onClick={closeModal}
                className="cursor-pointer font-bold"
              />
            </div>

            <div className="text-center py-8">
              <h2 className="font-semibold text-lg">
                Are you sure you want to delete this item?
              </h2>
            </div>

            <div className="grid grid-cols-12 gap-5 px-6 py-8">
              <div className="col-span-6">
                <div className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white">
                  <MdOutlineDoneOutline className="text-2xl" />
                  <div class="uppercase">
                    <span>Confirm</span>
                  </div>
                </div>
              </div>

              <div className="col-span-6">
                <div
                  onClick={closeModal}
                  className="flex text-primary justify-center items-center space-x-3 border-radius px-3 py-2 cursor-pointer text-sm  rounded-[8px] border bg-[#FAFCFE]  text-center font-bold  hover:bg-primary hover:text-white"
                >
                  <GiCancel className="text-2xl" />
                  <div class="uppercase">
                    <span>Cancel</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal section */}
      </div>
    </>
  );
}
