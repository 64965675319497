import React, { useState, useEffect } from "react";
import LeftSideBar from "../sidebar/LeftSideBar";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../sidebar/Header";
import Select from "react-select";
import { Country, State, City } from "country-state-city";
import {
  getDirectoryData,
  getDirectoryDataById,
  getDirectoryTypes,
  editDirectoryDataById,
  getAllCountry,
  getAllState,
  getAllCity,
  UpdateDirctyPage,
} from "../apiconfig/authService";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

function ViewCategory() {
  const navigate = useNavigate();
  const [editState, setEditState] = useState(true);
  const [dropDown, setDropDown] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [coutryValue, setCountryValue] = useState("India");
  const [coutryShow, setCountry] = useState();
  const [stateValue, setStateValue] = useState("");
  const [cityData, setCityData] = useState([]);
  const getDropDownOptions = async () => {
    try {
      const response = await getDirectoryTypes();
      console.log(response?.data);
      setDropDown(response?.data);
    } catch (error) {
      console.log(error);
    }
  };
  let arr = [];
  for (let i = 0; i < dropDown.length; i++) {
    arr[i] = dropDown[i].name;
  }
  console.log(arr);

  var list = [];
  arr.forEach(function (element) {
    list.push({ label: element, value: element });
  });

  console.log("Ste Dta", stateData.length);

  const handleChange = (e) => {
    setDirectoryDataId({ ...directoryDataId, [e.target.name]: e.target.value });
  };

  const handleSelect = (event) => {
    event.preventDefault();
    const { value } = event.target;
    console.log(value);
  };

  const id = useParams();
  const directoryId = id.id;

  const [directoryDataId, setDirectoryDataId] = useState({
    typeId: "",
    name: "",
    email: "",
    phoneNumber: "",
    telePhone: "",
    address: "",
    website: "",
    branchOffice: "",
    country: "",
  });

  const showDetails = async () => {
    try {
      const response = await getDirectoryDataById(directoryId);
      console.log(response.data);
      //   setDirectoryDataId(response.data[0]);
      setDirectoryDataId({
        typeId: response.data[0]?.typeId?._id,
        name: response.data[0]?.name,
        email: response.data[0]?.email,
        phoneNumber: response.data[0]?.phoneNumber,
        telePhone: response.data[0]?.telePhone,
        address: response.data[0]?.address,
        website: response.data[0]?.website,
        branchOffice: response.data[0]?.branchOffice,
        country: response.data[0]?.country,
        state: response.data[0]?.state,
        city: response.data[0]?.city,
      });
      setCountryValue(response.data[0]?.country);
      setStateValue(response.data[0]?.state);
    } catch (error) {
      console.log(error);
    }
  };

  const editDetails = async () => {
    try {
      const response = await editDirectoryDataById(directoryDataId);
      console.log(response.data);
      setDirectoryDataId(response.data[0]);
      setCountryValue(response.data[0]?.country);
    } catch (error) {
      console.log(error);
    }
  };

  console.log("Coutry value", coutryValue, stateValue);

  const handleClick = async () => {
    if (
      !directoryDataId.typeId ||
      !directoryDataId.name ||
      !directoryDataId.email ||
      !directoryDataId.phoneNumber
    ) {
      toast("Please Select Mandatory Fields");
    } else {
      try {
        const response = await UpdateDirctyPage({
          id: directoryId,
          typeId: directoryDataId.typeId,
          name: directoryDataId.name,
          address: directoryDataId.address,
          country: coutryValue,
          state: stateValue,
          city: directoryDataId.city,
          branchOffice: directoryDataId.branchOffice,
          email: directoryDataId.email,
          telePhone: directoryDataId.telePhone,
          phoneNumber: directoryDataId.phoneNumber,
          website: directoryDataId.website,
        });

        console.log(response);

        if (response.code == 200) {
          toast(response.message);
          navigate("/directory/add-directorypage");
        } else {
          toast(response.message);
        }
      } catch (error) {}
    }
  };

  async function CountryHandler(e) {
    var DataCounty = e.target.value;
    setCountryValue(DataCounty);
    getAllStateData(DataCounty);
    setStateValue("");
  }

  async function StateHandler(e) {
    var DataState = e.target.value;
    setStateValue(DataState);
    getAllCityData(coutryValue, DataState);
    // setStateData(response.data);
  }

  const getAllStateData = async (coutryValue) => {
    try {
      const response = await getAllState(coutryValue);
      console.log("Sate Data Show Mnvr ", response);
      setStateData(response.data);
    } catch (error) {}
  };

  const getAllCityData = async (coutryValue, stateValue) => {
    try {
      const response = await getAllCity(coutryValue, stateValue);
      console.log(" City Data ", response);
      setCityData(response.data);
    } catch (error) {}
  };

  const getAllCountryData = async () => {
    try {
      const response = await getAllCountry();
      console.log(response);
      setCountry(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getAllStateData(coutryValue);
  }, [coutryValue]);

  useEffect(() => {
    getAllCityData(coutryValue, stateValue);
  }, [coutryValue, stateValue]);

  useEffect(() => {
    showDetails();
    getAllCountryData();
    getDropDownOptions();
  }, []);

  console.log(directoryDataId);

  return (
    <>
      <Header />
      <div className="col12SwmDashBord">
        <div className="col4SemNvr">
          <LeftSideBar />
        </div>
        <div className="col8SemNvr mt-[70px] ml-[5%]">
          <div className="block w-full p-2 bg-white">
            {/* Brecrums */}
            <nav class="flex MngeBredcums px-4" aria-label="Breadcrumb">
              <ol class="inline-flex items-center space-x-1 md:space-x-3">
                <li>
                  <div class="flex items-center">
                    <NavLink
                      to="/"
                      class="ml-1 text-xs font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white"
                    >
                      {" "}
                      Directory{" "}
                    </NavLink>
                  </div>
                </li>
                <li aria-current="page">
                  <div class="flex items-center">
                    <svg
                      class="w-4 h-4 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span class="ml-1  font-medium text-gray-500 md:ml-2 dark:text-gray-400 text-xs">
                      {" "}
                      View Directory{" "}
                    </span>
                  </div>
                </li>
              </ol>
            </nav>

            <div className="flex justify-between mt-2 mb-2 px-4">
              <h4 className="text-xl font-bold"> Directory Detail</h4>
              <div className="space-x-4">
                <button
                  onClick={handleClick}
                  // onClick={() => setEditState(!editState)}
                  className=" px-6 py-1 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                >
                  Save
                  {/* {editState ? "Edit" : "Save"} */}
                </button>
                <NavLink
                  to="/directory/add-directorypage"
                  className=" px-6 py-1.5 border-[2px] font-semibold border-primary text-primary hover:bg-primary hover:text-white rounded-md "
                >
                  Back
                </NavLink>
              </div>
            </div>

            {/* End Brecrums */}

            <div className="block w-full mb-8 bg-white ">
              <div className="p-4 block">
                <div class="py-3 grid grid-cols-2 gap-3 border-[1px] mb-0 w-full px-2 ">
                  <div class="w-full border px-3 my-3 justify-between  flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="countryDta"
                    >
                      Select Category <span className="text-red-500">*</span>
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <select
                        class="form-select mt-1 block w-full text-xs"
                        id="SelctCgry"
                        name="typeId"
                        value={directoryDataId?.typeId}
                        onChange={handleChange}
                      >
                        <option> Select Category </option>
                        {dropDown &&
                          dropDown.map((item, index) => {
                            return (
                              <option key={index} value={item._id}>
                                {item.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between  flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Name <span className="text-red-500">*</span>
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="name"
                        value={directoryDataId?.name}
                        // disabled={editState}
                        onChange={handleChange}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between  flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Email <span className="text-red-500">*</span>
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="email"
                        value={directoryDataId?.email}
                        // disabled={editState}
                        onChange={handleChange}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="email"
                      />
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between  flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Phone <span className="text-red-500">*</span>
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="phoneNumber"
                        value={directoryDataId?.phoneNumber}
                        // disabled={editState}
                        onChange={handleChange}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="number"
                      />
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between  flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Telephone Number 
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="telePhone"
                        value={directoryDataId?.telePhone}
                        // disabled={editState}
                        onChange={handleChange}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="email"
                      />
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between  flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Address
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <textarea
                        name="address"
                        value={directoryDataId?.address}
                        // disabled={editState}
                        onChange={handleChange}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between  flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="countryDta"
                    >
                      Country
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <select
                        class="form-select mt-1 block w-full text-xs"
                        id="countryDta"
                        name="country"
                        value={coutryValue}
                        onChange={CountryHandler}
                      >
                        <option> Select Country </option>
                        {coutryShow &&
                          coutryShow?.map((item, index) => {
                            return (
                              <option key={index} value={item?.name}>
                                {item?.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="stateDta"
                    >
                      State
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <select
                        class="form-select mt-1 block w-full text-xs"
                        id="stateDta"
                        name="state"
                        value={stateValue}
                        onChange={StateHandler}
                      >
                        <option> Select State </option>
                        {stateData.length === undefined ||
                        stateData.length === 0 ||
                        stateData.length === null
                          ? ""
                          : stateData &&
                            stateData?.map((item, index) => {
                              return (
                                <option key={index} value={item?.name}>
                                  {item?.name}
                                </option>
                              );
                            })}
                      </select>
                    </div>
                  </div>
                  <div class="w-full border px-3 my-3 justify-between flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="cityDta"
                    >
                      City
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <select
                        class="form-select mt-1 block w-full text-xs"
                        id="cityDta"
                        name="city"
                        onChange={handleChange}
                        value={directoryDataId?.city}
                      >
                        <option> Select City </option>

                        {stateData.length === undefined ||
                        stateData.length === 0 ||
                        stateData.length === null
                          ? ""
                          : cityData &&
                            cityData?.map((item, index) => {
                              return (
                                <option key={index} value={item?.name}>
                                  {item?.name}
                                </option>
                              );
                            })}
                      </select>
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Website
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="website"
                        value={directoryDataId?.website}
                        // disabled={editState}
                        onChange={handleChange}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                      />
                    </div>
                  </div>

                  <div class="w-full border px-3 my-3 justify-between flex items-center">
                    <label
                      class="block w-full border-r py-2 px-3  md:mb-0  tracking-wide text-black-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Branch Office
                    </label>
                    <div className="w-full md:w-[90%] px-3">
                      <input
                        name="branchOffice"
                        value={directoryDataId?.branchOffice}
                        // disabled={editState}
                        onChange={handleChange}
                        class="appearance-none block w-full text-xs text-gray-700  rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewCategory;
