import React, { useState } from "react";
import { Checkbox, Input } from "@material-tailwind/react";

import Select from "react-select";
import { IoMdAddCircle } from "react-icons/io";
import { AiFillDelete } from "react-icons/ai";

const AddFormTwo = ({
    fieldName,
    setFieldName,
    fieldtypeoptions,
    setRequired,
    required,
    placeHolder,
    setPlaceHolder,
    selectedElement,
    setSelectedElement,
    addOption,
    removeOption,
    options,
    onChangeOption,
}) => {
    const [arr, setArr] = useState([1]);

    return (
        <>
            <form className="grid grid-cols-12 gap-3 shadow-sm shadow-black p-3 rounded-lg mb-4">
                <div className="col-span-12 md:col-span-4">
                    <Input
                        value={fieldName}
                        onChange={setFieldName}
                        label="Field Name"
                        color="purple"
                    />
                </div>
                <div className="col-span-12 md:col-span-4">
                    <Input
                        value={placeHolder}
                        onChange={setPlaceHolder}
                        label="Place holder"
                        color="purple"
                    />
                </div>
                <div className="col-span-12 md:col-span-4">
                    <Select
                        placeholder={"Field Type"}
                        options={fieldtypeoptions}
                        value={selectedElement}
                        onChange={(e) => setSelectedElement(e)}
                        className="w-full text-sm text-gray-500 rounded-[7px] text-left"
                    />
                </div>

                <div className="col-span-12 md:col-span-6 gap-2 flex justify-start items-start">
                    <div className="flex gap-2 items-center">
                        <div className="space-x-2">
                            <input
                                type="radio"
                                id="optional"
                                name="type"
                                value="Optional"
                                onChange={setRequired}
                            />
                            <label for="html">Optional</label>
                        </div>
                        <div className="space-x-2">
                            <input
                                type="radio"
                                id="optional"
                                name="type"
                                value="Required"
                                onChange={setRequired}
                            />
                            <label for="html">Required</label>
                        </div>

                        <div className="flex items-center">
                            <Checkbox />
                            <h2>More Filter</h2>
                        </div>
                    </div>
                </div>

                {/* <div className="col-span-12 md:col-span-6 gap-4 flex justify-start items-center text-sm px-3 w-full">
                    {selectedElement?.value == "dropdown" || selectedElement?.value == 'multiselect' ? (
                        <>
                            <div className="flex flex-col justify-start items-center space-y-3 w-full">
                                {options.map((i, index) => (
                                    <div className="w-full flex gap-3 justify-center items-center">
                                        <Input
                                            onChange={(e) => onChangeOption(e, i.id)}
                                            value={i.value}
                                            label='Option'
                                            className=""
                                            color="purple"
                                        />

                                        <span className="flex justify-center items-center cursor-pointer hover:scale-105 duration-500">
                                            <AiFillDelete
                                                onClick={() => removeOption(i.id)}
                                                size={20}
                                                color="purple"
                                            />
                                        </span>

                                    </div>
                                ))}
                            </div>

                            <div className="flex justify-end items-end p-2 cursor-pointer ">
                                <IoMdAddCircle onClick={addOption} color="purple" size={20} />
                            </div>
                        </>
                    ) : selectedElement?.value == "string" || selectedElement?.value == "number" ? <>
                        <div className="flex flex-col justify-start items-center space-y-3 w-full">
                            {options.map((i, index) => (
                                <div className="w-full flex gap-3 justify-center items-center">
                                    <Input
                                        onChange={(e) => onChangeOption(e, i.id)}
                                        value={i.value}
                                        label='Value'
                                        className=""
                                        color="purple"
                                    />

                                </div>
                            ))}
                        </div>

                    </> : null}
                </div> */}
            </form>
        </>
    );
};

export default AddFormTwo;
